﻿/* ===========================================
   component.quote
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-quote {
  text-align: left;

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    margin: 0 auto;
    position: relative;
    background-color: @color-primary;
    border-radius: 100px;
  }

  &__icon {
    fill: white;
  }

  &__text {
    text-align: center;
    font-size: @font-size--xl;
    font-style: italic;
    width: 100%;
    margin: 0 auto;
    font-weight: 900;
    &:not(:first-child) {
      margin-top: 2rem;
    }

    @media @sm {
      width: 100%;
      font-size: @font-size--xxl;
    }
  }
}

.mode-edit {
  .avalon-quote {
    position: static;

    &__content {
      margin-right: 0;
    }
  }
}
