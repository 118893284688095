﻿@import (reference) "./../_imports.less";

.avalon-cards .card-type1 {
  height: 23rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);

  @media @xl {
    height: 37rem;
  }

  .card__image-wrapper {
    position: relative;
    height: 10rem;
    background: rgba(0, 0, 0, 0.2);

    @media @xl {
      height: 15rem;
    }

    .card__image-title {
      position: absolute;
      top: 1rem;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: white;
      z-index: 1;
    }

    .card__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .card__content {
    padding: 1rem 1rem 0;
    flex: 1;

    @media @xl {
      padding: 3.5rem 3.5rem 0;
    }

    .card__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: white;
      line-height: 1.2;
      margin-bottom: 0.5rem;

      @media @xl {
        font-size: 2.1rem;
      }
    }

    .card__subtitle {
      font-size: 1.3rem;
      font-weight: 600;
      color: white;
      line-height: 1.3;

      @media @xl {
        font-size: 1.8rem;
      }
    }
  }

  .card__cta-wrapper {
    padding: 1rem 1rem 2rem;
    text-align: center;

    @media @xl {
      padding: 1rem 3.5rem 3.5rem;
    }
  }
}


