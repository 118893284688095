@import (reference) '../Colors/Base';

.app-universe {
  .app-universe__button {
    border-radius: 0.4rem;
    padding: 0 1.5rem;
    height: 3.3rem;
    line-height: 3.3rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: color 0.15s linear, background-color 0.15s linear;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
    background-color: white;
    color: black;
    font-weight: 600;
  }

  .app-universe__button-container {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    height: 100%;
  }

  .app-universe__button--cta {
    position: relative;
    border-radius: 0.4rem;
    background-color: @ds-yellow-primary;
    padding: 0 2rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    min-width: 12.5rem;
    display: inline-block;
    text-align: center;
    color: @app-universe-color-text-on-light--dark;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }


  .app-universe__button--installation-guide {
    position: relative;
    color: @app-universe-color-text-on-dark;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 0 0 3rem;
    cursor: pointer;
    user-select: none;
    
    &--your-luck{
      text-shadow: rgba(0, 0, 0, 0.4) 0.1rem 0 1rem;  
    }

    &:after {
      content: "?";
      position: absolute;
      color: @app-universe-primary--dark;
      background: @app-universe-color-text-on-dark;
      width: 2rem;
      height: 2rem;
      top: 1rem;
      left: 0;
      line-height: 2rem;
      text-align: center;
      border-radius: 50%;
    }

    .ios-device & {
      display: none;
    }
  }

  .app-universe__button--toggle {
    border-radius: 0.4rem;
    padding: 0 1.5rem;
    height: 3.3rem;
    line-height: 3.3rem;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: color 0.15s linear, background-color 0.15s linear;
    color: @app-universe-color-text-on-dark;

    &.active {
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
      background-color: white;
      color: black;
      font-weight: 600;
    }
  }

  .app-universe__button-flash {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    &:before {
      position: absolute;
      content: "";
      top: -50%;
      bottom: -50%;
      left: -200%;
      width: 3rem;
      background-color: rgba(255, 255, 255, 0.3);
      animation: appUniverseButtonFlash 1000ms linear infinite;
      transform: rotate(20deg);
    }

    &:after {
      position: absolute;
      content: "";
      top: -50%;
      bottom: -50%;
      left: -200%;
      width: 1.5rem;
      background-color: rgba(255, 255, 255, 0.3);
      animation: appUniverseButtonFlash 1000ms linear infinite;
      animation-delay: 500ms;
      transform: rotate(20deg) translateX(-2rem);
    }

    @keyframes appUniverseButtonFlash {
      100% {
        left: 300%;
      }
    }
  }
}
