/* ===========================================
   component.heading
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";
// file needs to be imported,
// so @extend can be used on .u-padding classes for conditional styling
@import (reference) "./../Utility/spacing.less";

.avalon-heading {

  * {
    &.text-right-aligned {
      text-align: right;
    }

    &.text-left-aligned {
      text-align: left;
    }

    &.text-centered {
      text-align: center;
    }

    &.u-theme-font {
      font-family: @font-impact;
    }
  }
}

.avalon-heading__sub-title {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 600;

  .region-avalon-bingo & {
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: normal;
  }

  @media @sm {
    font-size: 2.4rem;
  }
}
