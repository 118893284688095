/* ===========================================
   component.text-continues-indicator
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-text-continues-indicator {
  font-size: @font-size--sm;
  color: @color-gray-300;
  text-align: center;
  font-style: italic;
}
