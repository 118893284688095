@import (reference) "../Variables/breakpoints";
@import (reference) "../Variables/Variables";

.app-universe {
  .container {
    margin-left: 2.5rem;
    margin-right: 2.5rem;

    @media (min-width: @medium) {
      margin-left: 5rem;
      margin-right: 5rem;
    }

    @media (min-width: @large) {
      margin-left: auto;
      margin-right: auto;
      width: @app-universe-container-width-large;
    }
  }
}
