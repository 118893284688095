﻿@import (reference) "./../_imports.less";

.subscription-responses {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  padding: 1rem;
  min-height: 50rem;
  
  .subscription-responses__block-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .subscription-responses__block {
    flex: 1;
  }

  .subscription-responses__title {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: bold;
    margin: 1rem 0;
  }

  .subscription-responses__subtitle {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  pre {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.1rem;
    line-height: 1.2;
  }

  .subscription-responses__states {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .subscription-responses__states-button {
    padding: 0.7rem;
    background: #ecf0f1;
    border: 0.1rem solid #bdc3c7;
    margin: 0 0.3rem 0.3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    cursor: pointer;
    
    &--selected {
      background: @ds-black;
      color: @ds-white;
      font-weight: bold;
    }
  }

  .subscription-responses__states-list {
    flex: 0 0 100%;
  }

  .subscription-responses__table-wrapper {
    width: 100%;
    //max-height: 70rem;
    overflow: auto;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.2;

    table, th, td {
      vertical-align: top;
      border: 0.1rem solid @ds-black;
      border-collapse: collapse;
      text-align: left;
    }

    th, td {
      padding: 0.3rem 0.4rem;
    }
  }

  .subscription-responses__toggled-rows {
    margin-bottom: 2rem;
  }

  .subscription-responses__toggled-rows-inner {
    display: flex;
    align-items: center;

    input {
      margin-right: 0.5rem;
    }

    label {
      line-height: 2rem;
    }
  }
}
