﻿@import (reference) "./../_imports.less";

.glossary {
  position: relative;
  color: @ds-white;
  margin: 0 auto;
  padding: 2.5rem 1.5rem;
  max-width: 147rem;
}

.glossary__header {
  margin-bottom: 2.5rem;

  @media @sm {
    display: grid;
    grid-template-areas: "title search" "text seacrh";
    grid-auto-columns: 1fr 30rem;
    grid-column-gap: 2rem;
  }

  @media @xl {
    margin-bottom: 4rem;
    grid-auto-columns: 1fr 34rem;
  }
}

.glossary__title {
  grid-area: title;
  font-weight: 800;
  font-size: 2.8rem;
  line-height: 3.2rem;
  padding: 0 0 1.6rem;
}

.glossary__text {
  grid-area: text;
  color: @ds-gray-125;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0 0 2.5rem;
}

.glossary__search {
  position: relative;
  width: 100%;
  max-width: 34rem;
}

.glossary__search-icon {
  width: 5.4rem;
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  height: 4.4rem;
  border-radius: 2.4rem;
  pointer-events: none;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/magnifying-glass.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: fade(@ds-white, 80%);

  .glossary--searching & {
    pointer-events: initial;
    cursor: pointer;
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/close.svg);
  }
}

.glossary__input {
  grid-area: search;
  width: 100%;
  border: none;
  height: 4.8rem;
  border-radius: 2.4rem;
  padding: 0 2rem;
  outline: 0.2rem solid transparent;

  &:focus-visible {
    outline: 0.2rem solid @ds-gray-425;
  }
}

.glossary__content {
  display: flex;
  margin: 0 -1.5rem 0 0;

  @media @xl {
    flex-direction: column-reverse;
    margin: 0;
  }
}

.glossary__entries {
  flex: 1;
  padding: 0 1.5rem 0 0;
  margin-top: -2rem;

  .glossary--searching & {
    margin-top: 0;
  }
}

.glossary__entry-letter {
  pointer-events: none;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
  padding-top: 9rem;
  margin-top: -7rem;

  @media @xl {
    padding-top: 12rem;
    margin-top: -12rem;
  }

  .glossary--searching & {
    display: none;
  }
}

a.glossary__entry-to-top {
  display: none;
  color: @ds-white;
  text-decoration: underline;
  margin-bottom: 4rem;

  @media @xl {
    display: inline-block;

    .glossary--searching & {
      display: none;
    }
  }
}

.glossary__no-entries {
  display: none;

  .glossary--no-entries & {
    display: block;
  }
}

.glossary__entry {
  margin-bottom: 1.6rem;

  &--hide {
    display: none;
  }
}

.glossary__entry-word {
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 0.4rem;
}

.glossary__entry-definition {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.glossary__index {
  position: sticky;
  z-index: 1;
  top: 7rem;
  padding: 0.5rem 0;
  flex: 0 0 4rem;
  height: 100vh;
  overflow-x: hidden;

  .glossary--searching & {
    visibility: hidden;

    @media @xl {
      visibility: initial;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    border-radius: 2rem;
    background: @ds-black-600;

    @media @xl {
      content: none;
    }
  }

  @media @xl {
    position: relative;
    top: initial;
    flex: 1;
    overflow-x: initial;
    padding: 0;
    margin-bottom: 6rem;
    background: none;
    max-width: 121.6rem;
  }
}

.glossary__index-inner {
  width: 100px;
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  margin-bottom: 15rem;

  @media @xl {
    margin-bottom: 0;
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: initial;
  }
}

.glossary__index-letter {
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  color: fade(@ds-white, 50%);
  margin: 0.3rem auto 1rem 0.6rem;

  &:hover {
    color: fade(@ds-white, 25%);
  }

  &--selected {
    color: @ds-black;
    background: @ds-yellow;
    border-radius: 100%;
    font-weight: 700;

    &:hover {
      color: @ds-black;
      background: @ds-yellow-600;
    }
  }

  @media @xl {
    font-size: 1.6rem;
    margin: 0;
    width: 4rem;
    height: 4rem;
  }
}
