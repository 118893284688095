/* ===========================================
   component.tabs-component
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";
// file needs to be imported,
// so @extend can be used on .grid classes for conditional styling
@import (reference) "./../Utility/grid.less";
// file needs to be imported,
// so @extend can be used on .u-padding classes for conditional styling
@import (reference) "./../Utility/spacing.less";

.avalon-tabs-component {
  @small-height: 6rem;
  @large-height: 8rem;

  &__nav {
    background: @component-tabs-campaign-navigation-bg;
    position: relative;
    z-index: 1;
    display: flex;

    @media @sm {
      padding-left: @spacing-unit--lg;
      padding-right: @spacing-unit--lg;
    }

    .tabs-component--tabs-disabled & {
      display: none;
    }

    .tabs-component__link {
      color: inherit;
      background: rgba(0, 0, 0, .3);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__nav-list {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;

    @media screen and (max-width: @medium) {
      padding: 0;
    }
  }

  &__nav-item {
    display: flex;
    flex: 1 1 50%;
    justify-content: center;
    align-items: center;

    position: relative;

    text-align: center;
    text-transform: uppercase;
    font-weight: 900;

    transition: all ease .4s;

    &:first-child {
      border-left: 1px solid rgba(255, 255, 255, .2);
    }

    &:nth-last-child(1) {
      border-right: 1px solid rgba(255, 255, 255, .2);
    }

    &:empty {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 12.5px solid transparent;
      border-right: 12.5px solid transparent;
      border-top: 12.5px solid transparent;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1.2rem;
      transition: all ease .4s;

      @media @sm {
        border-right-width: 15px;
        border-left-width: 15px;
        border-top-width: 15px;
        bottom: -1.4rem;
      }
    }

    &.is-selected {
      border-color: transparent;
      background: @component-tabs-component-nav-item-bg--selected;

      &::after {
        border-top-color: @component-tabs-component-nav-item-bg--selected;
      }
    }
  }


  &__nav-link {
    display: block;
    width: 100%;
    padding: @spacing-unit;

    color: white;

    @media screen and (max-width: @medium) {
      padding: @spacing-unit @spacing-unit--sm;
    }

    .avalon-tabs-component__nav-item.is-selected & {
      color: @button-text-color;
      cursor: default;
    }
  }

  &__nav-title {
    font-weight: 900;
  }

  &__frames {
    background-color: @component-tabs-campaign-bg;
    color: @color-white;
    position: relative;
    z-index: 0;
  }

  &__frame {
    height: 0;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transition: opacity .4s ease-in-out;

    &.is-active {
      height: auto;
      top: 0;
      opacity: 1;
    }
  }

  .mode-edit & {
    nav,
    .is-single-tab-title {
      display: none !important;
    }

    &__frame {
      height: auto !important;
      top: 0 !important;
      opacity: 1;
    }
  }
}

.avalon-tabs-component__frame-title {

  .tabs-component--tabs-disabled & {
    // Grid needs to be set in CSS and not in HTML,
    // because it should only be applied if tabs have disabled class, which gets set in JS
    &:extend(.avalon-area .u-grid);
    &:extend(.avalon-area .u-grid--medium);
    &:extend(.avalon-area .u-padding-top--xl);
    &:extend(.avalon-area .u-padding-bottom--none);
    text-align: center;
  }
}
