@import (reference) "./../_imports.less";

.avalon-button {
  display: inline-block;
  padding: 1.3rem 1.8rem;
  color: @component-avalon-button--color;
  background-color: @component-avalon-button--bg;
  border-radius: @component-avalon-button--borderradius;
  font-size: @font-size;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  box-shadow: @component-avalon-button--boxshadow;
  transition: 200ms ease;

  @media @sm {
    font-size: @font-size--sm;
  }

  &:hover {
    color: @component-avalon-button-hover--color;
    background-color: @component-avalon-button-hover--bg;
    box-shadow: @component-avalon-button-hover--boxshadow;
  }

  &:active {
    color: @component-avalon-button-active--color;
    background-color: @component-avalon-button-active--bg;
  }

  &--inactive {
    &,
    &:hover,
    &:active {
      color: @component-avalon-button__inactive--color;
      background-color: @component-avalon-button__inactive--bg;
      text-decoration: none;
      user-select: none;
    }
  }

  &--yellow,
  &--corporate {
    color: @component-avalon-button__yellow--color;
    background-color: @component-avalon-button__yellow--bg;

    &:hover {
      background-color: @component-avalon-button__yellow-hover--bg;
    }
  }

  &--gray,
  &--grey {
    color: @component-avalon-button__gray--color;
    background-color: @component-avalon-button__gray--bg;

    &:hover {
      background-color: @component-avalon-button__gray-hover--bg;
    }
  }

  &--light-gray,
  &--light-grey {
    color: @component-avalon-button__lightgray--color;
    background-color: @component-avalon-button__lightgray--bg;

    &:hover {
      background-color: @component-avalon-button__lightgray-hover--bg;
    }
  }
}
