﻿@import (reference) "./../_imports.less";

.brand-and-byline-tag {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  display:none;

  @media @lg{
    display:flex;
  }

  .brand-and-byline-tag__brand {
    width: 7rem;
    height: 3rem;
    border-radius: 0 0 0 .6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: @breakpoint-region-spot-large) {
      width: 10rem;
      height: 4rem;
    }

    img {
      // IE requires a width and height in order to scale properly 
      width: 80%;
      height: 80%;
      max-width: 80%;
      max-height: 70%;
    }
  }

  .brand-and-byline-tag__byline {
    color: white;
    font-size: 0.9rem;
    font-weight: 700;
    text-align: right;
    padding: 0 1rem;
    width: 100%;
    font-style: italic;
    margin-top: 0.5rem;

    &.brand-and-byline-tag__byline--dark {
      color: black;
    }
  }
}
