@import (reference) "./Variables/breakpoints";
@import (reference) "./Mixins/css-variable";

/* ===========================================
   tools.mixins
   ========================================= */
// Mixins used in PoCaGa, Poker and Casino projects

// Clearfix
.clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.view-width(@sizeValue) {
  @vw: (@sizeValue / 7.5);
  font-size: ~"@{vw}";
}

//Container grid wide
.container-grid() {
  max-width: @large;
  width: 100%;
  margin: 0 auto;
}

.container-grid-small() {
  max-width: @small;
  width: 100%;
  margin: 0 auto;
}
