/* ===========================================
   utility.group
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

@import (reference) "./../_imports.less";

.avalon-area {
  // Fullsize-image will be more square in the mobile view.
  .u-fullsize-image {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    min-height: 400px;
    display: block;
    object-fit: cover;

    // No resizing on desktop. The image cropping should be done i Photoshop or the like
    @media @sm {
      max-height: 100%;
      min-height: 0;
    }

    // IE11 Specific style. Because object-fit is not supported in IE.
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-height: 100%;
      min-height: 0;
    }

    // Edge Specific style. Because object-fit is not supported untill Edge 16.
    @supports (-ms-ime-align:auto) {
      max-height: 100%;
      min-height: 0;
    }

    // Fullsize-image with resizing. Object-fit will keep the image from being streched.
    &--restricted {
      @media @sm {
        max-height: 60vh;
        min-height: 400px;
      }
    }
  }
}
