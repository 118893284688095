// images:
.framed-background-images-eurojackpot01() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 60px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-right_use');
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 60px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#center-left_use');
  @bg-top-left: 104px 112px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-left_use');
  @bg-top-right: 104px 112px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top-right_use');
  @bg-bottom-left: 104px 112px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-left_use');
  @bg-bottom-right: 104px 112px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#top_use');
  @bg-right: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#bottom_use');
  @bg-left: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame01.svg#left_use');
  @bg-inner-bg: url();
}

.framed-background-images-eurojackpot02() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 0 0 url();
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 0 0 url();
  @bg-top-left: 178px 175px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-left_use');
  @bg-top-right: 178px 175px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top-right_use');
  @bg-bottom-left: 178px 175px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-left_use');
  @bg-bottom-right: 178px 175px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#top_use');
  @bg-right: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#bottom_use');
  @bg-left: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame02.svg#left_use');
  @bg-inner-bg: url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
}

.framed-background-images-eurojackpot03() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 0 0 url();
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 0 0 url();
  @bg-top-left: 104px 154px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-left_use');
  @bg-top-right: 104px 154px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top-right_use');
  @bg-bottom-left: 104px 154px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-left_use');
  @bg-bottom-right: 104px 154px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#top_use');
  @bg-right: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#bottom_use');
  @bg-left: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame03.svg#left_use');
  @bg-inner-bg: url();
}

.framed-background-images-eurojackpot04() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 0 0 url();
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 0 0 url();
  @bg-top-left: 92px 86px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-left_use');
  @bg-top-right: 92px 86px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top-right_use');
  @bg-bottom-left: 92px 86px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-left_use');
  @bg-bottom-right: 92px 86px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#top_use');
  @bg-right: 40px 50px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#bottom_use');
  @bg-left: 40px 50px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame04.svg#left_use');
  @bg-inner-bg: url();
}

.framed-background-images-eurojackpot05() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 0 0 url();
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 0 0 url();
  @bg-top-left: 137px 136px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-left_use');
  @bg-top-right: 137px 136px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top-right_use');
  @bg-bottom-left: 137px 136px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-left_use');
  @bg-bottom-right: 137px 136px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#top_use');
  @bg-right: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#bottom_use');
  @bg-left: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame05.svg#left_use');
  @bg-inner-bg: url();
}

.framed-background-images-eurojackpot06() {
  @bg-decoration-top: 0 0 url();
  @bg-decoration-right: 0 0 url();
  @bg-decoration-bottom: 0 0 url();
  @bg-decoration-left: 0 0 url();
  @bg-top-left: 137px 137px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-left_use');
  @bg-top-right: 137px 137px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top-right_use');
  @bg-bottom-left: 137px 137px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-left_use');
  @bg-bottom-right: 137px 137px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom-right_use');
  @bg-top: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#top_use');
  @bg-right: 50px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#right_use');
  @bg-bottom: 40px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#bottom_use');
  @bg-left: 50px 40px url('/Components/DanskeSpil/Domain/Eurojackpot/Graphics/SpriteSheets/EurojackpotFrame06.svg#left_use');
  @bg-inner-bg: url();
}

// mixins:
.framed-background-mixin-bg(@bg-color: 'transparent', @bg-image: '') {
  background-size: cover;
  background-position: center;
  background-color: @bg-color;
  background-image: url(@bg-image);
}

.framed-background-mixin-frame(@scale, @dec-top, @dec-right, @dec-bottom, @dec-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg) {
  background-repeat: no-repeat;
  background-image: extract(@dec-top, 3), extract(@dec-right, 3), extract(@dec-bottom, 3), extract(@dec-left, 3), extract(@bg-top-left, 3), extract(@bg-top-right, 3), extract(@bg-bottom-left, 3), extract(@bg-bottom-right, 3), extract(@bg-top, 3), extract(@bg-right, 3), extract(@bg-bottom, 3), extract(@bg-left, 3), @bg;

  @bg-top-left-width: ceil(extract(@bg-top-left, 1) * @scale);
  @bg-top-left-height: ceil(extract(@bg-top-left, 2) * @scale);

  background-size: ceil(extract(@dec-top, 1) * @scale) ceil(extract(@dec-top, 2) * @scale),
    ceil(extract(@dec-right, 1) * @scale) ceil(extract(@dec-right, 2) * @scale),
    ceil(extract(@dec-bottom, 1) * @scale) ceil(extract(@dec-bottom, 2) * @scale),
    ceil(extract(@dec-left, 1) * @scale) ceil(extract(@dec-left, 2) * @scale),
    @bg-top-left-width @bg-top-left-height,
    ceil(extract(@bg-top-right, 1) * @scale) ceil(extract(@bg-top-right, 2) * @scale),
    ceil(extract(@bg-bottom-left, 1) * @scale) ceil(extract(@bg-bottom-left, 2) * @scale),
    ceil(extract(@bg-bottom-right, 1) * @scale) ceil(extract(@bg-bottom-right, 2) * @scale),
    calc(~'100% - @{bg-top-left-width} * 2 + 1px') ceil(extract(@bg-top, 2) * @scale),
    ceil(extract(@bg-right, 2) * @scale) calc(~'100% - @{bg-top-left-height} * 2 + 1px'),
    calc(~'100% - @{bg-top-left-width} * 2 + 1px') ceil(extract(@bg-bottom, 2) * @scale),
    ceil(extract(@bg-left, 2) * @scale) calc(~'100% - @{bg-top-left-height} * 2 + 1px'),
    cover;

  background-position: top center,
    right center,
    bottom center,
    left center,
    top left,
    top right,
    bottom left,
    bottom right,
    top center,
    right center,
    bottom center,
    left center,
    center;
}

// backgrounds:
.framed-background__bg-eurojackpot-gold--small() {
  .framed-background-mixin-bg(#dba238, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-small.jpg');
}

.framed-background__bg-eurojackpot-gold--medium() {
  .framed-background-mixin-bg(#dba238, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-medium.jpg');
}

.framed-background__bg-eurojackpot-gold--large() {
  .framed-background-mixin-bg(#dba238, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame01/bg-large.jpg');
}

.framed-background__bg-eurojackpot-stars--small() {
  .framed-background-mixin-bg(black, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-small.jpg');
}

.framed-background__bg-eurojackpot-stars--medium() {
  .framed-background-mixin-bg(black, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-medium.jpg');
}

.framed-background__bg-eurojackpot-stars--large() {
  .framed-background-mixin-bg(black, '/Components/DanskeSpil/Domain/Eurojackpot/Graphics/EurojackpotFrame04/bg-large.jpg');
}

// frames:
.framed-background__frame-eurojackpot01--small() {
  .framed-background-images-eurojackpot01();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot01--medium() {
  .framed-background-images-eurojackpot01();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot01--large() {
  .framed-background-images-eurojackpot01();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot02--small() {
  .framed-background-images-eurojackpot02();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot02--medium() {
  .framed-background-images-eurojackpot02();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot02--large() {
  .framed-background-images-eurojackpot02();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot03--small() {
  .framed-background-images-eurojackpot03();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot03--medium() {
  .framed-background-images-eurojackpot03();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot03--large() {
  .framed-background-images-eurojackpot03();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot04--small() {
  .framed-background-images-eurojackpot04();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot04--medium() {
  .framed-background-images-eurojackpot04();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot04--large() {
  .framed-background-images-eurojackpot04();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot05--small() {
  .framed-background-images-eurojackpot05();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot05--medium() {
  .framed-background-images-eurojackpot05();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot05--large() {
  .framed-background-images-eurojackpot05();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot06--small() {
  .framed-background-images-eurojackpot06();
  .framed-background-mixin-frame(0.5, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot06--medium() {
  .framed-background-images-eurojackpot06();
  .framed-background-mixin-frame(0.75, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}

.framed-background__frame-eurojackpot06--large() {
  .framed-background-images-eurojackpot06();
  .framed-background-mixin-frame(1, @bg-decoration-top, @bg-decoration-right, @bg-decoration-bottom, @bg-decoration-left, @bg-top-left, @bg-top-right, @bg-bottom-left, @bg-bottom-right, @bg-top, @bg-right, @bg-bottom, @bg-left, @bg-inner-bg);
}
