﻿@import (reference) "./../_imports.less";

.avalon-cards {
  .avalon-cards__title {
    color: @ds-color-primary--text;
    text-align: center;
  }
  
  .avalon-cards__container {
    padding: 0 3rem;
    max-width: 128rem;

    @media @sm {
      padding: 0 2rem;
    }

    @media (min-width: (@content-max-width + 40)) {
      padding: 0;
      width: 100%;
      margin: 0 auto;
    }
  }

  .cards {
    height: 100%;
  }

  .card {
    overflow: hidden;
    border-radius: 0.6rem;
  }

  &.cards__remove-rounded-corners .card {
    border-radius: 0;
  }

  &.cards__padding-top {
    padding-top: 3rem;
  }

  &.cards__padding-bottom {
    padding-bottom: 3rem;
  }
}

