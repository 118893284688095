/* ===========================================
   component.campaign-list
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-campaign-list {
  background-color: @color-gray-500;

  &__link-area {
    color: @color-black;
    text-decoration: none;
  }

  &__intro {
    margin-bottom: 2rem;
  }

  &__header {
    color: @color-black-100;
    text-align: center;
  }

  &__abstract {

    @media screen and (max-width: @medium) {
      width: 100%;
      font-size: @font-size--sm;
    }
  }

  &__items {
    counter-reset: section;
  }

  &__item {
    color: @color-white-100;
    padding: 3rem;
    background-color: @color-black-100;

    &:not(:first-child) {
      margin-top: @item-margin;
    }

    @media screen and (max-width: @medium) {
      width: 100%;
      padding: 2rem;
      position: relative;
    }

    .clearfix();
  }

  &__item-date {
    font-weight: normal;
    display: block;
    margin-bottom: 0rem;

    @media screen and (max-width: @medium) {
      display: none;
    }
  }

  &__item-date--mobile {
    display: none;

    @media screen and (max-width: @medium) {
      font-size: @font-size--sm;
      text-align: left;
      display: inline-block;
    }
  }

  &__item-subheading {
    color: @color-white-100;
    text-align: left;
    font-weight: normal;

    @media screen and (max-width: @medium) {
      width: 100%;
      font-size: @font-size--sm;
      margin-bottom: 2rem;
    }
  }

  &__item-prize {
    font-size: @font-size--lg;
    color: @color-yellow-100;
    font-weight: 900;
    text-transform: uppercase;

    @media screen and (max-width: @medium) {
      font-size: @font-size--sm;
    }
  }

  .avalon-campaign-list__column {
    float: left;
    width: 33.33%;
    padding: 0 2rem;

    @media screen and (max-width: @medium) {
      padding: 0;
      width: 100%;
    }

    &:first-child {
      width: 40%;
      text-align: left;
      padding: 0;

      @media screen and (max-width: @medium) {
        width: 100%;
      }
    }

    &:last-child {
      width: 25%;
      text-align: right;
      padding: 0;

      @media screen and (max-width: @medium) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
