﻿@import (reference) "./../_imports.less";

@bingo-games-bg-color: #f7f6f0;

.avalon-area .u-grid.avalon-accordion {
  padding: 2rem;
}

.avalon-accordion__inner {
  border-radius: 0.5rem;
  background: @ds-gray-125;
  padding: 0 1.5rem;
  color: @ds-black;

  .region-casino &,
  .region-avalon-casino & {
    color: @ds-gray-875
  }

  .region-avalon-bingo & {
    background: @bingo-games-bg-color;
  }

  .avalon-accordion--transparent & {
    background: none;
  }

  .avalon-accordion--light & {
    color: @ds-white;
  }
}

.avalon-accordion__header {
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1.1;
  min-height: 5.6rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid @ds-gray-250;
  padding: 1rem 0;

  .region-casino & {
    font-family: Raleway, "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-style: italic;
  }

  .avalon-accordion--light & {
    border-color: @ds-white;
  }
}

.avalon-accordion__item {
  position: relative;
  z-index: 1;
  padding-top: 0.4rem;
  border-bottom: 0.1rem solid @ds-gray-250;

  .avalon-accordion--light & {
    border-color: @ds-white;
  }

  &:last-child {
    border-color: transparent;
    padding-bottom: 0.2rem;
  }

  &:hover::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: -0.1rem;
    bottom: -0.1rem;
    left: -1.5rem;
    right: -1.5rem;
    background: fade(@ds-black, 5%);
    border-radius: 0.5rem;
  }
}

.avalon-accordion__inner .avalon-accordion__header + .avalon-accordion__item {
  padding-top: 0;
}

.avalon-accordion__item label {
  position: relative;
  cursor: pointer;
  user-select: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: @ds-gray-925;
  font-size: 1.6rem;
  line-height: 1.25;
  min-height: 5rem;
  display: flex;
  align-items: center;
  padding: 1rem 3.5rem 1rem 0;

  .avalon-accordion--light & {
    color: @ds-white;
  }

  .region-avalon-bingo & {
    font-weight: 600;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    transition: all 250ms ease-out;
    border: 0.2rem solid @ds-black;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
    height: 1.1rem;
    width: 1.1rem;
    right: 0.5rem;
    top: 1.5rem;
    transform-origin: center;

    .region-casino &,
    .region-avalon-casino & {
      border-color: @color-brown-chocolate;
    }

    .avalon-accordion--light & {
      border-color: @ds-white;
    }
  }
}

.avalon-accordion__item input {
  display: none;

  &:checked + label {
    border-color: transparent;

    &:after {
      transform: rotate(225deg);
      top: 2.3rem;
    }
  }
}

.avalon-accordion__item article {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6;
  overflow: hidden;
  height: 0;
  transform-origin: 50% 0;
  transform: rotateX(-60deg) translate3d(0, 0, 0);
  opacity: 0;
  transition: all 300ms ease-out;
}

// html.mode-edit: Open the articles in Sitecore Experience Editor, for editing.
html.mode-edit .avalon-accordion__item input ~ article,
.avalon-accordion__item input:checked ~ article {
  height: auto;
  padding-bottom: 2rem;
  opacity: 1;
  transform: rotateX(0) translate3d(0, 0, 0);
}
