/* ===========================================
   component.text-with-cta
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-text-with-cta {

  &__content {
    margin-bottom: @spacing-unit;
  }

  &__cta {
    text-align: center;
  }
}
