@import (reference) "./../_imports.less";

.region-avalon-frontpage header nav .sub-navigation {
  display: none;
}

.brandmenu {
  // this calculation is aesthetical, but should be wide enough to not cause 3 rows of brandmenu icons
  @brandmenu-max-width: 126rem;

  // must be px (calculations below are somehow using 1rem = 16px)
  @brandmenu-brand-min-width: 115px;
  @brandmenu-brand-width-desktop: 124px;
  @brandmenu-sideWidth: 15px;
  @brandmenu-subsidiary-spacing-desktop: 20px;

  // calculations must be wrapped in parenthesis so they're calculated before being used in media queries
  @brandmenu-width-2: (@brandmenu-brand-min-width * 2 + @brandmenu-sideWidth * 2);
  @brandmenu-width-3: (@brandmenu-brand-min-width * 3 + @brandmenu-sideWidth * 2);
  @brandmenu-width-4: (@brandmenu-brand-min-width * 4 + @brandmenu-sideWidth * 2);
  @brandmenu-width-5: (@brandmenu-brand-min-width * 5 + @brandmenu-sideWidth * 2);
  @brandmenu-width-6: (@brandmenu-brand-min-width * 6 + @brandmenu-sideWidth * 2);
  @brandmenu-width-7: (@brandmenu-brand-min-width * 7 + @brandmenu-sideWidth * 2);

  // we need subsidiary rows that can contain 5 dli and 5 dlo icons (as we need to fit 9 dli and 7 dlo icons in total) 
  @brandmenu-breakpoint: (
    @brandmenu-brand-width-desktop * 10 + 
    @brandmenu-sideWidth * 2 + 
    @brandmenu-subsidiary-spacing-desktop + 
    20px // browser scrollbar width
  );

  padding: 0.5rem @brandmenu-sideWidth 1rem;
  background: @color-white;

  .brandmenu__content {
    max-width: @brandmenu-max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: @brandmenu-breakpoint) {
      flex-direction: row;
    }
  }

  .brandmenu__header {
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0 0.5rem 0.3rem;
    width: 100%;
  }

  .dli .brandmenu__header {
    color: @color-brand-oddset;
  }

  .dlo .brandmenu__header {
    color: @color-brand-lotto;
  }


  .brandmenu__brands {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    overflow: hidden;
  }

  .brandmenu__brand {
    height: 6rem;
    position: relative;
    width: 100%;

    @media (min-width: @brandmenu-width-2) {
      width: ~'calc(100% / 2)';
    }

    @media (min-width: @brandmenu-width-3) {
      width: ~'calc(100% / 3)';
    }

    @media (min-width: @brandmenu-width-4) {
      width: ~'calc(100% / 4)';
    }

    @media (min-width: @brandmenu-width-5) {
      width: ~'calc(100% / 5)';
    }

    @media (min-width: @brandmenu-width-6) {
      width: ~'calc(100% / 6)';
    }

    @media (min-width: @brandmenu-width-7) {
      width: ~'calc(100% / 7)';
    }

    @media (min-width: @brandmenu-breakpoint) {
      width: @brandmenu-brand-width-desktop;
    }
  }

  .brandmenu__link {
    border-radius: 0.6rem;
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 100ms ease-in;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 9rem;
      height: 3rem;
      max-width: 80%;
      max-height: 55%;
    }
  }

  .brandmenu__subsidiary {
    margin-top: 0.5rem;

    &.dli {
      @media (min-width: @brandmenu-breakpoint) {
        flex: 1;
      }
    }

    &.dlo {
      @media (min-width: @brandmenu-breakpoint) {
        flex-shrink: 0;
        width: 5 * @brandmenu-brand-width-desktop;
      }
    }
  }

  .alt-eller-intet-white {
    background-color: @color-brand-all-or-nothing;
  }
  
  .bingo-white {
    background-color: @color-brand-bingo;
  }

  .casino-white,
  .live-casino-white {
    background-color: @color-brand-casino;
  }

  .dantoto-white {
    background-color: @color-brand-dantoto;
  }

  .eurojackpot-white {
    background-color: @color-brand-eurojackpot;
  }

  .keno-white {
    background-color: @color-brand-keno;
  }

  .lotto-white {
    background-color: @color-brand-lotto;
  }

  .oddset-white,
  .eoddset-white {
    background-color: @color-brand-oddset;
  }

  .plus-white,
  .spilsammen-white {
    background-color: @color-brand-plus;
  }

  .poker-white {
    background-color: @color-brand-poker;
  }

  .quick-white {
    background-color: @color-brand-quick;
  }

  .spillehjoernet-white {
    background-color: @color-brand-spillehjoernet;
  }

  .tips-white {
    background-color: @color-brand-tips;
  }

  .vikinglotto-white,
  .onsdagslotto-white {
    background-color: @color-brand-vikinglotto;
  }
}
