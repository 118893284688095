﻿/* ===========================================
   component.image
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";
// file needs to be imported,
// so @extend can be used on .grid classes for conditional styling
@import (reference) "./../Utility/grid.less";

.avalon-image {
  position: relative;
  
  //Single image
  &.avalon-image--count-1 {
    height: 100%;

    .avalon-image-wrapper {

      &__caption {
        right: 0;
        bottom: 0;
        width: 50rem;
        padding: 3rem;
        position: absolute;
        background: @color-black-70;
        color: @color-white-100;

        @media screen and (max-width: @medium) {
          padding: 3rem;
          width: 100%;
        }
      }
    }
  }
  
  //Double image
  &.avalon-image--count-2 {
    background: @color-tertiary;

    .avalon-image-wrapper {
      // grid needs to be set in CSS and not in HTML,
      // because it should only be applied if count is 2
      &:extend(.avalon-area .u-grid);
      &:extend(.avalon-area .u-grid--medium);

      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      &__single,
      &__double {
        flex-basis: calc(~'50% - @{item-margin} / 2');
        position: relative;

        @media screen and (max-width: @medium) {
          flex-basis: 100%;

          &:not(:first-child) {
            margin-top: @item-margin;
          }
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &__caption {
        width: 100%;
        padding: 3rem;
        background: @color-black-70;
        position: absolute;
        bottom: 0;
        right: 0;
        color: @color-white-100;

        @media screen and (max-width: @medium) {
          font-size: @font-size;
        }
      }
    }
  }
}