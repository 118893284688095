﻿/* ===========================================
   utility.layout
   ========================================= */

// Utilities – utility- and helper classes with the ability to override anything
@import (reference) "./../_imports.less";
// file needs to be imported,
// so @extend can be used on .u-padding classes for conditional styling
@import (reference) "spacing.less";

.avalon-area {
  // Three-col layout
  .three-col {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    background: @color-white;

    @media screen and (max-width: @medium) {
      flex-wrap: wrap;
    }

    &:after {
      // Float-fallback (if Flexbox isn't supported)
      content: '';
      display: block;
      clear: both;
    }


    &__col {
      flex: 1 0 100%;

      @media @sm {
        flex: 0 0 25%;
        float: left; // Float-fallback (if Flexbox isn't supported)
        width: 25%; // Float-fallback (if Flexbox isn't supported)
      }
    }

    &__col--left,
    &__col--right {
      &:not(:empty) {
        padding: @spacing-unit--lg 0;

        @media screen and (max-width: @medium) {
          padding: @spacing-unit--xs 0;
        }
      }
    }

    &__col--right {
      &:not(:empty) {
        @media screen and (max-width: @medium) {
          padding-bottom: @spacing-unit--sm;
        }
      }
    }

    &__col--center {

      @media screen and (max-width: @medium) {
        order: -1; // Render as first column on mobile
      }

      @media @sm {
        flex: 0 1 50%;
        float: left; // Float-fallback (if Flexbox isn't supported)
        width: 50%; // Float-fallback (if Flexbox isn't supported)
        padding: @spacing-unit--lg @spacing-unit--xl;
      }

      &:not(:empty) {
        padding: @spacing-unit--lg;
      }
      // Ensures that the components inside a three col center won't get double padding.
      & section {
        &:extend(.avalon-area .u-padding-hor--none);
      }
      // Ensures that the first component in a three col center won't have double top padding.
      & section:first-child {
        &:extend(.avalon-area .u-padding-top--none);
      }
      // Ensures that the last component in a three col center won't have double bottom padding.
      & section:last-of-type {
        &:extend(.avalon-area .u-padding-bottom--none);
      }
    }
  }
}


//Edit mode styles
.region-poker.mode-edit,
.region-casino.mode-edit {

  .one-col {
    background: #e4e4e4;
    width: 100%;
    border: 1px solid gray;
    min-height: 80px;
  }


  .three-col {
    display: table;
    width: 100%;
    max-width: 1024px;
    min-height: 80px;
    margin: 0 auto;

    background: #e4e4e4;
    border: 1px solid gray;

    &__col {
      float: left;
      width: 25%;
      border: 1px solid gray; // Styling to highlight columns in page editor
      &:before,
      &:after {
        content: '';
        display: block;
        border-top: 1px solid rgba(0, 0, 0, .25);
        margin: 1rem 0;
      }
    }

    &__col--center {
      width: 47%;
      padding: 0 20px;
    }
  }
}
