﻿/* ===========================================
   component.dark-teaser-list
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-teaser-list {
  background: @color-tertiary;
  color: @color-white-100;

  &__header {
    text-align: center;
  }

  &__link-area {
    display: block;
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 -.5rem;
  }

  &__item {
    flex-basis: 25%;
    position: relative;

    @media screen and (max-width: @medium) {
      flex-basis: 33.3333%;
    }

    @media screen and (max-width: @small) {
      flex-basis: 50%;
    }
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    height: 0;
    margin: 0 0.5rem;
    // images are in the format 234x166 px
    padding-top: calc(~'166/234 * 100%');
  }

  &__image {
    @format: 0.75; //width: 1, height: 0.75
    @widthItems4: 100 / 4;
    @widthItems3: 100 / 3;
    @widthItem1: 100;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .avalon-teaser-list__image-spinner {
    position: absolute;
    z-index: 0;
    top: calc(~'50% - 2rem');
    left: calc(~'50% - 2rem');
    animation: rotation 750ms infinite linear;
    width: 4rem;
    height: 4rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}

.avalon-teaser-list__title-overlay {
  width: 100%;
  min-height: 5vw;
  padding: .4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  background: @color-black-70;
  color: @color-white;
  text-align: center;

  @media @lg {
    min-height: 5rem;
  }
}

.avalon-teaser-list__body-text {
  position: relative;
  z-index: 10;
  font-size: @font-size;
  line-height: 1.3;
  padding: 0.5rem;
  margin: 0;
}
