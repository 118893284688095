.page-not-found {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1.4;
  color: #00422c;
  max-width: 99rem;
  text-align: center;
  margin: 4rem auto 2rem;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-areas: ". dice ." ". title ." ". text ." ". buttons. " ". games .";
}
@media screen and (min-width: 768px) {
  .page-not-found {
    text-align: left;
    grid-template-columns: auto minmax(auto, 40rem) auto 23rem auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: ". title . dice ." ". text . dice ." ". buttons . dice ." ". games games games .";
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found {
    margin-top: 5rem;
    grid-template-columns: minmax(auto, 57rem) auto 34rem;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: "title . dice" "text . dice" "buttons . dice" "games games games";
  }
}
.page-not-found__dice {
  grid-area: dice;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  .page-not-found__dice {
    justify-content: flex-end;
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__dice {
    margin-bottom: 10rem;
  }
}
.page-not-found__dice .ds-cube-loader-wrapper {
  position: relative;
}
.page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper {
  position: relative;
  top: 0;
  transform: scale(0.5);
  perspective-origin: 50% 90%;
}
@media screen and (min-width: 768px) {
  .page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper {
    transform: scale(0.55);
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper {
    transform: scale(0.65);
  }
}
.page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube {
  margin: 2rem auto -10rem;
}
@media screen and (min-width: 768px) {
  .page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube {
    margin: 5rem auto -10rem -3rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__dice .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube {
    margin: 10rem -2rem -10rem auto;
  }
}
.page-not-found__dice .ds-cube-loader-wrapper .ds-cube-loader-text {
  display: none;
}
.page-not-found__title {
  grid-area: title;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .page-not-found__title {
    margin: 3rem 0 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__title {
    font-size: 4rem;
  }
}
.page-not-found__text {
  grid-area: text;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  line-height: 1.3;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .page-not-found__text {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__text {
    font-size: 2rem;
  }
}
.page-not-found__buttons {
  grid-area: buttons;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .page-not-found__buttons {
    justify-content: flex-start;
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__buttons {
    margin-bottom: 10rem;
  }
}
.page-not-found__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 0 2rem;
  min-width: 13rem;
  height: 4rem;
  background: #FEB700;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 700;
  box-shadow: 0 0.3rem 1rem 0.2rem rgba(0, 0, 0, 0.15), 0 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
  transition: 100ms;
  text-decoration: none;
}
.page-not-found__button:hover {
  text-decoration: none;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
}
.page-not-found__button + .page-not-found__button {
  margin-left: 2rem;
}
@media screen and (min-width: 768px) {
  .page-not-found__button {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__button {
    font-size: 1.6rem;
  }
}
.page-not-found__games {
  grid-area: games;
}
@media screen and (min-width: 1024px) {
  .page-not-found__games {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.page-not-found__games-dli,
.page-not-found__games-dlo,
.page-not-found__games-dli-dlo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 55rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1024px) {
  .page-not-found__games-dli,
  .page-not-found__games-dlo,
  .page-not-found__games-dli-dlo {
    flex: 1 1 calc(50% - 0.5rem);
    max-width: 60rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__games-dli {
    margin-right: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .page-not-found__games-dli-dlo {
    flex: 0 0 100%;
  }
}
.page-not-found--dli .page-not-found__games-dlo,
.page-not-found--dli .page-not-found__games-dli-dlo {
  display: none;
}
.page-not-found--dlo .page-not-found__games-dli,
.page-not-found--dlo .page-not-found__games-dli-dlo {
  display: none;
}
.page-not-found__game {
  position: relative;
  min-height: 4rem;
  flex: 1 1 9rem;
  height: 4rem;
  transition: 100ms;
}
.page-not-found__game:hover {
  transform: scale(1.05);
}
@media screen and (min-width: 1024px) {
  .page-not-found__game {
    height: 4.5rem;
  }
}
.page-not-found__game--placeholder {
  min-height: 0;
  max-height: 0;
  order: 100;
}
.page-not-found__game::before {
  content: "";
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  bottom: 0.2rem;
  right: 0.2rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.page-not-found__game--oddset {
  order: 1;
}
.page-not-found__game--oddset::before {
  background-color: #003E99;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#oddset_use);
}
.page-not-found__game--casino {
  order: 2;
}
.page-not-found__game--casino::before {
  background-color: #a78952;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#casino_use);
}
.page-not-found__game--spillehjoernet {
  order: 3;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--spillehjoernet {
    order: 8;
  }
}
.page-not-found__game--spillehjoernet::before {
  background-color: #7D96B4;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#spillehjoernet_use);
}
.page-not-found__game--eoddset {
  order: 4;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--eoddset {
    order: 6;
  }
}
.page-not-found__game--eoddset::before {
  background-color: #003E99;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#eoddset_use);
}
.page-not-found__game--livecasino {
  order: 5;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--livecasino {
    order: 7;
  }
}
.page-not-found__game--livecasino::before {
  background-color: #a78952;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#livecasino_use);
}
.page-not-found__game--poker {
  order: 6;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--poker {
    order: 9;
  }
}
.page-not-found__game--poker::before {
  background-color: #000000;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#poker_use);
}
.page-not-found__game--tips {
  order: 7;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--tips {
    order: 4;
  }
}
.page-not-found__game--tips::before {
  background-color: #009a60;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#tips_use);
}
.page-not-found__game--bingo {
  order: 8;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--bingo {
    order: 3;
  }
}
.page-not-found__game--bingo::before {
  background-color: #a0d323;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#bingo_use);
}
.page-not-found__game--dantoto {
  order: 9;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--dantoto {
    order: 5;
  }
}
.page-not-found__game--dantoto::before {
  background-color: #dc5f11;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#dantoto_use);
}
.page-not-found__game--lotto {
  order: 1;
}
.page-not-found__game--lotto::before {
  background-color: #c50005;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#lotto_use);
}
.page-not-found__game--vikinglotto {
  order: 2;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--vikinglotto {
    order: 3;
  }
}
.page-not-found__game--vikinglotto::before {
  background-color: #1c1cff;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#vikinglotto_use);
}
.page-not-found__game--eurojackpot {
  order: 3;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--eurojackpot {
    order: 2;
  }
}
.page-not-found__game--eurojackpot::before {
  background-color: #e8a332;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#eurojackpot_use);
}
.page-not-found__game--keno {
  order: 4;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--keno {
    order: 7;
  }
}
.page-not-found__game--keno::before {
  background-color: #00a5eb;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#keno_use);
}
.page-not-found__game--allornothing {
  order: 5;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--allornothing {
    order: 6;
  }
}
.page-not-found__game--allornothing::before {
  background-color: #191339;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#allornothing_use);
}
.page-not-found__game--quick {
  order: 6;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--quick {
    order: 8;
  }
}
.page-not-found__game--quick::before {
  background-color: #7f4488;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#quick_use);
}
.page-not-found__game--plus {
  order: 7;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--plus {
    order: 4;
  }
}
.page-not-found__game--plus::before {
  background-color: #004b32;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#plus_use);
}
.page-not-found__game--spilsammen {
  order: 8;
}
@media screen and (min-width: 1024px) {
  .page-not-found__game--spilsammen {
    order: 5;
  }
}
.page-not-found__game--spilsammen::before {
  background-color: #004b32;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#spilsammen_use);
}
.page-not-found__game--virtuel {
  order: 9;
}
.page-not-found__game--virtuel::before {
  background-color: #166F93;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#virtuel_use);
}
.page-not-found__game--sikkertspil {
  order: 1;
}
.page-not-found__game--sikkertspil::before {
  background-color: #4b997f;
  background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#sikkertspil_use);
}
.page-not-found__games-header {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.4rem;
}
@media screen and (min-width: 1024px) {
  .page-not-found__games-header {
    font-size: 1.3rem;
  }
}
.page-not-found__games-dli .page-not-found__games-header {
  color: #0f4096;
}
.page-not-found__games-dlo .page-not-found__games-header {
  color: #c21617;
}
