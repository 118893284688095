﻿/* ===========================================
   component.table-component
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-table-component__content {
  padding: @container-padding 0;

  @media @sm {
    padding: @container-padding;
  }
}

.avalon-table-component__header {
  display: flex;
}

.avalon-table-component__headline {
  padding: 3rem;
  background: @color-tertiary--dark;
  color: @color-white;
  text-align: center;
  flex: 1;

  &--left {
    text-align: left;
  }
}

.avalon-table-component__campaign {
  flex: 0 30%;
}


.avalon-table-component__table {
  width: 100%;
}

.avalon-table-component__cell {
  padding: @spacing-unit--lg;

  font-size: @font-size--sm;
  line-height: 1.2;
  text-align: left;
  letter-spacing: -0.5px;

  @media screen and (max-width: @medium) {
    width: 50%;
    padding: @spacing-unit @spacing-unit--lg;
  }

  // Set in Sitecore
  &.text-left-aligned {
    @media @sm {
      text-align: left;
    }
  }

  // Set in Sitecore
  &.text-right-aligned {
    @media @sm {
      text-align: right;
    }
  }

  // Set in Sitecore
  &.text-center-aligned {
    @media @sm {
      text-align: center;
    }
  }

  // Cell content is wrapped in <p> tags
  p {
    margin: 0;
  }

  &.text-style-bold {
    font-weight: 600;
  }
}

.avalon-table-component__cell--header {
  padding-top: @spacing-unit;
  padding-bottom: @spacing-unit;

  background: @color-gray-300;
  border-right: 1px solid currentcolor;

  font-size: @font-size--sm;
  color: @color-white;
  text-transform: uppercase;
  font-weight: 900;

  &:last-of-type {
    border-right: none;
  }
}

.avalon-table-component__row {
  @media screen and (max-width: @medium) {
    .clearfix();
  }

  &:nth-child(odd) {
    background: @color-gray-600;
  }
}

// Common styles for mobile table views
.avalon-table-component__row--cells-3,
.avalon-table-component__row--cells-4 {
  @media screen and (max-width: @medium) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: @spacing-unit @spacing-unit--lg;
  }

  .avalon-table-component__cell {
    @media screen and (max-width: @medium) {
      width: ~'calc(50% - 0.1rem)'; // Fixes too much width issue on IOS
      padding: 0.3rem 0;
    }
  }
}

// If it's a 4 cell table it should behave like this:
// 2 cells on top and 2 beneath
.avalon-table-component__row--cells-4 {
  .cell-item--2 {
    @media screen and (max-width: @medium) {
      order: 3;
    }
  }

  .cell-item--3 {
    @media screen and (max-width: @medium) {
      order: 4;
      text-align: right;
    }
  }

  .cell-item--4 {
    @media screen and (max-width: @medium) {
      order: 2;
      text-align: right;
    }
  }
}

// If it's a 3 cell table, then it should behave like this:
// The first cell is fullwidth, and the other two aligns underneath
.avalon-table-component__row--cells-3 {
  .cell-item--2 {
    @media screen and (max-width: @medium) {
      order: 99; // Display as last on mobile
    }
  }

  .cell-item--3 {
    @media screen and (max-width: @medium) {
      text-align: right;
    }
  }
}

// If only two columns 'table-component__row--cells-2' the columns should not be forced to align on small devices
.avalon-table-component__row--header {
  // Do not show table headers, <th>, on mobile tables with 3 or 4 cells
  &.avalon-table-component__row--cells-3,
  &.avalon-table-component__row--cells-4 {
    @media screen and (max-width: @medium) {
      display: none !important;
    }
  }
}

//// Experience Editor behavior ////
.mode-edit .avalon-table-component {

  .avalon-table-component__edit-cell {
    display: block;
    width: 10rem;
    margin-left: -10rem;
    margin-top: 2.5rem;
    padding: .5rem;

    background: transparent !important;

    font-size: @font-size--sm;
    line-height: 1.3;
  }
}
