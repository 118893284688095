﻿@import (reference) '../Colors/Base';
@import (reference) "../Variables/breakpoints.less";

.app-universe {
  .app-history {
    margin: 0 0 3rem;
    background: @app-universe-color-light-grey;
    border-radius: 1rem;
    padding: 2rem 2rem 2.5rem;

    @media (min-width: 102.5rem) {
      padding: 2rem 2rem 3rem 2.5rem;
    }
  }

  .app-history-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .app-history-header__title {
      padding: 0.4rem 1rem 0 0;
      color: @app-universe-color-text-on-light;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.3;

      @media (min-width: 102.5rem) {
        font-size: 1.7rem;
      }
    }
  }

  .app-history-header__link {
    font-size: 1.1rem;
    line-height: 2.6rem;
    height: 2.6rem;
    padding: 0 2.2rem 0 1rem;
    background: @app-universe-primary;
    color: @app-universe-color-text-on-dark;
    border-radius: 1.3rem;
    text-decoration: none;
    position: relative;

    @media (min-width: 102.5rem) {
      font-size: 1.3rem;
      line-height: 3rem;
      height: 3rem;
      border-radius: 1.5rem;
      padding: 0 2.7rem 0 1.7rem;
    }

    &:after {
      content: "";
      position: absolute;
      right: 1rem;
      top: 1.1rem;
      border: solid white;
      border-width: 0 0.1rem 0.1rem 0;
      display: inline-block;
      padding: 0.2rem;
      transform: rotate(-45deg);

      @media (min-width: 102.5rem) {
        border-width: 0 0.1rem 0.1rem 0;
        padding: 0.3rem;
        top: 1.2rem;
        right: 1.2rem;
      }
    }
  }

  .app-history-header__version {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;
    color: @app-universe-color-text-on-light--light;

    @media (min-width: 102.5rem) {
      font-size: 1.5rem;
    }
  }

  .app-history-header__date {
    color: @app-universe-color-text-on-light--light;
    font-size: 1rem;
    line-height: 2rem;

    @media (min-width: 50rem) {
      font-size: 1.1rem;
    }

    @media (min-width: 102.5rem) {
      font-size: 1.2rem;
    }
  }

  .app-history-content {
    color: @app-universe-color-text-on-light;
    font-size: 1.2rem;
    line-height: 1.5;

    @media (min-width: 50rem) {
      font-size: 1.4rem;
    }

    ul {
      list-style-position: inside;
    }
  }
}
