/* ===========================================
   component.twitter-typeahead
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

// Twitter Typeahead styles
.twitter-typeahead {
  width: 100%;
}

.tt-menu {
  width: 100%;
  max-height: 30rem;
  margin-top: -.1rem;

  border: 1px solid @color-gray-600;
  background-color: @color-primary;

  overflow-y: auto;
}

.tt-cursor {
  background: @color-primary;
  color: @color-black;
}

.tt-suggestion {
  padding: 1rem 2rem;

  font-size: @font-size;
  line-height: 2.4rem;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;

  @media screen and (max-width: @medium) {
    font-size: @font-size--sm;
  }

  &:hover {
    background-color: @color-primary;
    color: @color-black;
  }

  &:nth-child(odd) {
    border-top: 1px solid @color-gray-600;
    border-bottom: 1px solid @color-gray-600;
  }
}