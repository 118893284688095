@import (reference) "./../../_imports.less";

.avalon-cards.card-layout-9 {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.cards__padding-top {
    padding-top: 2rem;
  }

  &.cards__padding-bottom {
    padding-bottom: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;

    @media @xs {
      flex-direction: row;
    }
  }

  .card {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 100%;
    margin: 1rem;

    @media @xs {
      &:nth-child(3n+1) {
        flex-basis: 100%;
      }

      flex-basis: calc(~'50% - 2rem');
    }

    @media @lg {
      &:nth-child(3n+1) {
        flex-basis: calc(~'50% - 2rem');
      }

      flex-basis: calc(~'25% - 2rem');
    }
  }
}
