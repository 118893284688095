.page-nav-header-target {
  // No visual styling on the website

  // Styling for Experience Editor only
  .mode-edit & {
    display: flex;
    background-color: orange;
    border: 1px dotted black;
    min-height: 10px;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
}

.page-nav-header-target__text {
  .mode-edit & {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
