/* ===========================================
   component.rich-text-content
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-rich-text-content {

  a {
    color: @color-primary;
    color: var(--color-primary);
  }

  p {
    margin: 6px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 12px;
    margin-left: 1.2em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
