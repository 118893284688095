@import (reference) "../Variables/breakpoints";
@import (reference) "../Variables/Variables";

.app-universe {
  .app-changelog {
    margin-top: 4rem;
    margin-bottom: 4rem;
    color: white;

    .app-changelog-section {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      padding: 2rem 0;
    }

    .app-changelog-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .app-changelog-header__version {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 2rem;

      @media (min-width: @large) {
        font-size: 1.5rem;
      }
    }

    .app-changelog-header__date {
      font-size: 1rem;
      line-height: 2rem;
      color: rgba(255, 255, 255, 0.5);

      @media (min-width: @small) {
        font-size: 1.1rem;
      }

      @media (min-width: @large) {
        font-size: 1.2rem;
      }
    }

    .app-changelog-content {
      color: rgba(255, 255, 255, 0.75);
      font-size: 1.2rem;
      line-height: 1.5;

      @media (min-width: @small) {
        font-size: 1.4rem;
      }

      ul {
        list-style-position: inside;
      }
    }
  }
}
