@import (reference) "./../../_imports.less";

.avalon-cards.card-layout-10 {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.cards__padding-top {
    padding-top: 2rem;
  }

  &.cards__padding-bottom {
    padding-bottom: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: (@small)) {
      flex-direction: row;
    }
  }

  .card {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: 100%;

    @media (min-width: (@small)) {
      &:nth-child(3n+1) {
        flex-basis: 100%;
      }

      flex-basis: 50%;
    }

    @media (min-width: (@large)) {
      &:nth-child(3n+1) {
        flex-basis: 50%;
      }

      flex-basis: 25%;
    }
  }
}
