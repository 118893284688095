@import (reference) "./../_imports.less";

.episode-overview {

  .episode-overview__back-link-wrapper {
    width: 90%;
    max-width: 145rem;
    margin: auto;
    padding: 2rem 0;
    display: flex;
    justify-content: flex-start;

    @media @md {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
    }

    .episode-overview__back-link {
      text-decoration: none;
      display: flex;
      color: @color-white;
      align-items: center;

      &::before {
        content: '';
        margin-right: 1rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-bottom: 0.3rem solid @color-white;
        border-left: 0.3rem solid @color-white;
        transform: rotate(45deg);
      }

      &:hover {
        &::before {
          animation: back-arrow-hover-animation 0.3s alternate infinite linear;
        }

        @keyframes back-arrow-hover-animation {
          from {
            transform: rotate(45deg) translate(0);
          }

          to {
            transform: rotate(45deg) translate(-0.3rem, 0.3rem);
          }
        }
      }
    }
  }

  .episode-overview__episode-content {
    background-color: @component-podcast-primary;
    width: 100%;
    .divider-bottom();

    .episode-overview__episode-content-inner-wrapper {
      max-width: 145rem;
      align-items: center;
      display: flex;
      flex-direction: column;
      position: relative;

      @media @md {
        padding-top: 2.5rem;
        width: 90%;
        margin: 0 auto;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .episode-overview__imagewrap {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 60%;
        position: relative;

        @media @md {
          flex-direction: initial;
          width: 50%;
          justify-content: flex-end;
        }

        .episode-overview__image {
          width: 100%;

          @media @md {
            width: 80%;
          }
        }

        .episode-overview__episode-duration {
          position: absolute;
          bottom: 0;
          font-size: 1.2rem;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          color: @color-white;
          padding: 0.2rem 1rem;

          @media @md {
            display: none;
          }
        }
      }

      .episode-overview__episode-description-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 2rem;

        @media @md {
          margin-top: 0;
          width: 50%;
          text-align: left;
        }

        .episode-overview__content-icons {
          margin-top: 1rem;
          display: none;
          color: @color-white;
          width: 100%;

          @media @md {
            display: flex;
          }

          .episode-overview__button-text {
            width: 100%;
          }

          .episode-overview__episode-content-icon {
            height: 4rem;
            align-items: center;
            display: none;
            margin-right: 2rem;

            @media @md {
              display: flex;
            }

            svg {
              width: 1.5rem;
              height: 1.5rem;
              fill: @color-white;
            }

            .episode-overview__episode-content-text {
              margin: 0 0.5rem;
              color: @color-white;
            }
          }
        }

        .episode-overview__title {
          display: block;
          color: @color-white;
          width: 100%;
          margin: auto;
          font-style: italic;
          font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
        }

        .episode-overview__button-wrapper {
          display: flex;
          align-items: center;
          margin: 2rem 0 2rem;
          flex-direction: column;

          @media @md {
            flex-direction: row;
            width: 100%;
            order: 1;
          }

          .episode-overview__button {
            color: @color-white;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            background: #1c1c1c;
            cursor: pointer;
            font-size: 1.3rem;
            width: 13rem;
            font-weight: bold;
            display: flex;
            align-items: center;

            svg {
              height: 2rem;
              width: 25%;
              margin-right: 1rem;
            }

            .episode-overview__button-text {
              width: 100%;
            }

            @media @md {
              margin-bottom: 0;
              width: 15rem;
              font-size: 1.6rem;
            }
          }

          .episode-overview__episode-content-icon {
            height: 4rem;
            align-items: center;
            display: flex;
            order: -1;
            margin-bottom: 1rem;

            @media @md {
              display: none;
            }

            > svg {
              width: 2.5rem;
              height: 2.5rem;
            }

            .episode-overview__episode-content-text {
              margin: 0 1rem;
              color: @color-white;
            }
          }

          .episode-overview__open-in-other-player {
            display: none;
            font-size: 1.5rem;
            margin-left: 2rem;
            cursor: pointer;
            text-decoration: underline;
            color: @ds-white;

            &:focus {
              outline: none;
            }

            @media @md {
              display: block;
            }
          }

        }

        .episode-overview__description {
          color: @color-white;
          width: 90%;
          margin: auto;

          @media @md {
            width: 100%;
          }
        }

        .episode-overview__link-wrapper {
          margin-top: 2rem;
          color: @ds-white;

          .episode-overview__link-wrapper-link {
            text-decoration: underline;
            font-weight: 600;
            color: @ds-white;
          }

          @media @md {
            order: 1;
            align-self: flex-start;
            margin-top: 0;
          }
        }
      }
    }
  }

  .episode-overview__episode-list {
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
    max-width: 145rem;
    margin: 0 auto;

    @media @xs {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media @sm {
      padding-bottom: 4rem;
    }

    @media @md {
      flex-direction: column;
    }

    .episode-overview__episode {
      width: 100%;
      margin: 0.3rem 0;
      display: flex;
      align-items: flex-start;
      transition: transform 0.1s;
      transform: scale(1);
      color: @color-black;

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.03);
        animation: podcast-episode-bump 0.2s;
      }

      @media @xs {
        width: 47.5%;
      }

      @media @md {
        width: 100%;
        margin-right: 0;
        align-items: flex-start;
        justify-content: center;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }

      .episode-overview__episode-calendar {
        display: none;
        background-color: @component-podcast-primary;
        margin-right: 2rem;

        @media @md {
          display: block;
        }

        & > div {
          display: flex;
          justify-content: center;
          padding: 0.1rem 1rem;
          color: @color-white;
        }

        .episode-overview__episode-calendar-day {
          position: relative;
          font-weight: 700;
          font-size: 2.1rem;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            height: 0.1rem;
            width: 100%;
            background-color: @component-podcast-gray;
          }
        }
      }

      .episode-overview__episode-image-wrapper {
        flex-basis: 35%;
        position: relative;
        margin-right: 1rem;

        @media @md {
          flex-basis: unset;
          height: 20rem;
          width: 35rem;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-right: 3rem;
        }

        .episode-overview__episode-image {
          max-width: 100%;

          @media @md {
            min-height: 100%;
          }
        }

        .episode-overview__episode-duration {
          position: absolute;
          bottom: 0.7rem;
          font-size: 1.2rem;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          color: @color-white;
          padding: 0.2rem 1rem;

          @media @md {
            display: none;
          }
        }
      }

      .episode-overview__episode-content-wrapper {
        flex-basis: 65%;

        @media @md {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 20rem;
        }

        .episode-overview__episode-content-inner-wrapper {

          .episode-overview__episode-title {
            font-style: italic;
            font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
            font-weight: 700;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;

            @media @md {
              font-size: 2rem;
            }
          }

          .episode-overview__episode-description {
            position: relative;
            font-size: 1.4rem;
            line-height: 1;
            padding-bottom: 0.1rem;
            color: @component-podcast-text-color;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;

            &--3-lines {
              -webkit-line-clamp: 3;
            }

            @media @md {
              font-size: 1.6rem;
              line-height: 1.1;

              -webkit-line-clamp: 7;
            }
          }

          .episode-overview__episode-release-date {
            margin: 0;
            font-size: 1rem;
            color: @component-podcast-text-color;

            @media @md {
              display: none;
            }
          }
        }

        .episode-overview__episode-icons {
          display: none;

          @media @md {
            display: flex;
            height: 4rem;
          }

          .episode-overview__episode-play-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            border-radius: 50%;
            background-color: @component-podcast-primary;

            svg {
              width: 50%;
              margin-left: 0.2rem;
            }
          }

          .episode-overview__episode-duration-icon {
            height: 4rem;
            display: flex;
            align-items: center;
            margin-left: 2rem;

            > svg {
              width: 2.5rem;
              height: 2.5rem;
              fill: @color-gray-100;
            }

            .episode-overview__episode-duration-text {
              margin: 0 0.5rem;
            }
          }
        }
      }

      @keyframes podcast-episode-bump {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.04);
        }

        100% {
          transform: scale(1.03);
        }
      }
    }

    .episode-overview__show-more {
      margin: 2rem auto;
      color: @color-white;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      background: @ds-black;
      cursor: pointer;
      user-select: none;
      text-align: center;
      font-size: 1.3rem;
      width: 11rem;

      &--disabled {
        display: none;
      }
    }
  }

  .episode-overview__series-description-wrapper {
    .divider-top();
    background-color: @component-podcast-primary;
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;

    .episode-overview__series-description {
      max-width: 145rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @color-white;

      .episode-overview__series-description-inner-wrapper {
        width: 85%;
        text-align: center;
        margin-top: 1rem;

        @media @md {
          display: flex;
          align-items: center;
          text-align: left;
        }

        .episode-overview__series-description-text-wrapper {

          @media @md {
            flex-basis: 50%;
          }

          .episode-overview__series-description-header {
            font-style: italic;
            font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
            font-size: 2.5rem;
            font-weight: 600;
          }
        }

        .episode-overview__series-description-image-wrapper {
          margin: 2rem auto;
          width: 65%;
          box-shadow: 0 0 0.9rem 0 rgba(130, 130, 130, 0.4);

          @media @md {
            width: 21%;
          }

          .episode-overview__series-description-image {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}

.divider-bottom() {
  position: relative;
  margin-bottom: calc(0.2 * 100vw);

  &::after {
    bottom: 0;
    .divider();
  }
}

.divider-top() {
  position: relative;
  margin-top: calc(0.15 * 100vw);

  &::before {
    top: calc(-0.2 * 100vw);
    .divider();
  }
}

.divider() {
  left: 50%;
  content: '';
  width: 120%;
  height: calc(0.2 * 100vw);
  background: inherit;
  z-index: -1;
  position: absolute;
  transform: rotate(-10deg) translateX(-50%);
}
