@import (reference) "../Variables/breakpoints";

.app-universe {
  .phone-tablet-steps {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 3rem;

    .phone-tablet-steps__container {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      width: 100%;
    }

    .phone-tablet-steps__wrapper {
      width: 100%;
      display: flex;
    }

    .phone-tablet-steps__step {
      pointer-events: none;
      flex: 0 0 20rem;
      width: 20rem;
      margin: 0 1rem;
      border-radius: 1rem;
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
      background-repeat: no-repeat;
      background-color: white;
      background-position: center;
      background-size: cover;
      outline: none;

      &.tablet {
        flex: 0 0 37rem;
        width: 37rem;
        height: 29rem;
      }
    }
  }

  .phone-tablet-steps__container .slick-list {
    padding: 2rem 0;
  }

  .phone-tablet-steps__container .slick-track {
    left: 2.5rem;

    @media (min-width: @medium) {
      left: 5rem;
    }

    @media (min-width: @large) {
      left: calc(~'(100% - 95.8rem)/2');
    }

  }
}
