.rules-and-terms {
  position: relative;
  width: 100%;
  z-index: 2;
  border-radius: 2rem 2rem 0 0;
  background: white;
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  max-width: 144rem;
  margin: -4rem auto 0;
}
.rules-and-terms--open {
  position: fixed;
  top: 0;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms {
    border-radius: 4rem 4rem 0 0;
  }
}
.rules-and-terms__top {
  position: sticky;
  top: 0;
  text-align: right;
  z-index: 10;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 2rem 2rem 0 0;
  margin: 0;
  padding: 2rem 2rem 1rem;
}
.rules-and-terms--open .rules-and-terms__top {
  background: white;
  border-bottom: 0.1rem solid #8e8e8e;
  overflow: scroll;
  height: 100vh;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms--open .rules-and-terms__top {
    display: none;
  }
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__top {
    border-radius: 4rem 4rem 0 0;
    padding: 4rem 4rem 1rem;
    position: relative;
    top: auto;
  }
}
.rules-and-terms__input {
  margin: 0;
  padding: 0 1.5rem;
  border: 0.1rem solid #8e8e8e;
  border-radius: 0.8rem;
  height: 5.6rem;
  width: calc(100% - 7.6rem);
  max-width: 34.5rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1.6rem) center;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/search.svg);
  background-size: 1.8rem;
}
.rules-and-terms--search .rules-and-terms__input {
  background: none;
}
.rules-and-terms__input-clear {
  display: none;
  width: 5rem;
  height: 5rem;
  position: absolute;
  z-index: 1;
  right: 2.3rem;
  top: 2.3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rules-and-terms--search .rules-and-terms__input-clear {
  display: flex;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__input-clear {
    right: 4.3rem;
    top: 4.3rem;
  }
}
.rules-and-terms__input-clear-lines {
  position: relative;
}
.rules-and-terms__input-clear-lines::before,
.rules-and-terms__input-clear-lines::after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.8rem;
  height: 0.2rem;
  background: #000000;
  border-radius: 0.1rem;
  transform-origin: center;
  width: 2rem;
  transform: rotate(45deg);
}
.rules-and-terms__input-clear-lines::after {
  transform: rotate(-45deg);
}
.rules-and-terms__toggle {
  display: flex;
  height: 5.6rem;
  width: 5.6rem;
  border: 0.1rem solid #8e8e8e;
  border-radius: 0.8rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__toggle {
    display: none;
  }
}
.rules-and-terms__toggle-lines {
  position: relative;
  width: 2.6rem;
  height: 0.3rem;
  background: #000000;
  border-radius: 0.1rem;
}
.rules-and-terms__toggle-lines::before,
.rules-and-terms__toggle-lines::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.6rem;
  height: 0.3rem;
  background: #000000;
  border-radius: 0.1rem;
  transform: translatey(-0.8rem);
}
.rules-and-terms__toggle-lines::after {
  transform: translatey(0.8rem);
}
.rules-and-terms--open .rules-and-terms__toggle-lines {
  background: none;
}
.rules-and-terms--open .rules-and-terms__toggle-lines::before,
.rules-and-terms--open .rules-and-terms__toggle-lines::after {
  transform-origin: center;
  width: 2.8rem;
  transform: rotate(45deg);
}
.rules-and-terms--open .rules-and-terms__toggle-lines::after {
  transform: rotate(-45deg);
}
.rules-and-terms__content {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem 3rem 3rem;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__content {
    padding: 1rem 4rem 4rem;
  }
}
.rules-and-terms__toc-mobile {
  display: none;
  text-align: left;
  list-style-type: none;
  margin: 3rem 0 6rem 1rem;
  padding: 0;
}
.rules-and-terms__toc-mobile a:not([class]) {
  font-weight: normal;
  color: #000000;
  text-decoration: none;
}
.rules-and-terms__toc-mobile li {
  line-height: 1.2;
  margin-bottom: 2rem;
}
.rules-and-terms--open .rules-and-terms__toc-mobile {
  display: block;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms--open .rules-and-terms__toc-mobile {
    display: none;
  }
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__toc-mobile {
    display: none;
  }
}
.rules-and-terms__toc {
  text-align: left;
  display: none;
  background: white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 0.1rem solid #000000;
  list-style-type: none;
  margin: 0;
  line-height: 1.3;
}
.rules-and-terms__toc li {
  padding: 0;
  margin: 0 0 0.8rem;
}
.rules-and-terms__toc li:last-child {
  margin: 0;
}
.rules-and-terms__toc li a:not([class]) {
  font-weight: normal;
  text-decoration: none;
  color: #000000;
}
.rules-and-terms__toc li a:not([class]):hover {
  text-decoration: underline;
  font-weight: bold;
  color: #000000;
}
.rules-and-terms__toc li:first-child a:not([class]) {
  font-weight: bold;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__toc {
    display: block;
    border: none;
    width: 28rem;
    flex: 0 0 28rem;
    padding: 0 2rem 0 0;
    position: sticky;
    top: 5rem;
    margin-bottom: auto;
    max-height: calc(100vh - 5rem);
    overflow: auto;
  }
  .rules-and-terms__toc::-webkit-scrollbar {
    display: none;
  }
}
.rules-and-terms__rules {
  flex: 1;
}
.rules-and-terms__rules mark {
  position: relative;
}
.rules-and-terms__rules mark::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.1em;
  bottom: -0.15em;
  left: 0.5ch;
  right: -0.2ch;
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(-100deg, rgba(255, 255, 0, 0.3), rgba(255, 255, 0, 0.7) 95%, rgba(255, 255, 0, 0.1));
}
.rules-and-terms__rules h1,
.rules-and-terms__rules h2,
.rules-and-terms__rules h3 {
  line-height: 1.2;
  padding-top: 12rem;
  margin: -12rem 0 0.4em;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__rules h1,
  .rules-and-terms__rules h2,
  .rules-and-terms__rules h3 {
    padding-top: 5rem;
    margin: -5rem 0 0.4em;
  }
}
.rules-and-terms__rules p {
  margin: 0 0 0.4em;
}
.rules-and-terms__rules dl {
  line-height: 1.5;
}
.rules-and-terms__rules dd {
  margin: -1.5em 0 0.4em 4ch;
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__rules dd {
    margin: -1.5em 0 0.4em 6ch;
  }
}
.rules-and-terms__rules dl > dt {
  font-weight: bold;
}
.rules-and-terms__rules dl dl dt {
  font-weight: normal;
}
.rules-and-terms__rules dl dl dl dd {
  margin-left: 4ch;
}
.rules-and-terms__rules ol:not([class]) li,
.rules-and-terms__rules ul:not([class]) li {
  list-style-type: inherit;
}
.rules-and-terms__rules table tr th {
  max-width: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .rules-and-terms__rules table tr th {
    max-width: unset;
  }
}
@media screen and (min-width: 1216px) {
  .rules-and-terms__rules {
    padding-left: 2rem;
    border-left: 0.3rem solid #004b32;
  }
}
