/* ===========================================
   component.HeroJoker
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-joker {
  margin: 2rem;

  @media @sm {
    margin: 2rem 5rem;
  }
}

.avalon-joker__image {
  height: 4rem;

  @media @lg {
    height: 8rem;
  }
}

.avalon-joker__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.avalon-joker__logo-image {
  width: 13rem;
  height: 5.5rem;
  margin-right: 1rem;

  @media @lg {
    width: 20rem;
  }
}

.avalon-joker__logo-text {
  font-size: 3rem;
  font-style: italic;
  margin: 0;

  @media @lg {
    font-size: 5rem;
  }
}

.avalon-joker__prize {
  font-size: 3rem;
  font-style: italic;
  font-weight: 600;
  margin: 0;
  line-height: 1;

  @media @lg {
    font-size: 6rem;
  }

  &::first-letter {
    text-transform: capitalize;
  }

  // span is for the 'mindst' text which should be smaller than the actual prize.
  // Fx: Mindst 2 mio.
  span {
    font-size: 2.5rem;
  }
}
