.flip-clock {
  /* VARIABLES */

  @flip-clock-turn-duration: 200ms;

  // dark
  @flip-clock-color-dark: #ddd;
  @flip-clock-background-color: #333;
  @flip-clock-label-color: #222;

  // light
  @flip-clock-color-light: #444;
  @flip-clock-label-color-light: #eee;
  @flip-clock-background-color-light: #eee;

  // label size
  @flip-clock-label-size-xsmall: 1rem;
  @flip-clock-label-size-small: 1.1rem;
  @flip-clock-label-size-default: 1.2rem;
  @flip-clock-label-size-xlarge: 1.3rem;

  // label text
  @flip-clock-label-days: "Dage";
  @flip-clock-label-hours: "Timer";
  @flip-clock-label-mins: "Minutter";
  @flip-clock-label-mins-short: "Min.";
  @flip-clock-label-secs: "Sekunder";
  @flip-clock-label-secs-short: "Sek.";

  // size
  @flip-clock-size-xsmall: 3rem;
  @flip-clock-size-small: 4rem;
  @flip-clock-size-default: 5rem;
  @flip-clock-size-large: 6rem;
  @flip-clock-size-xlarge: 7rem;

  /* FUNCTIONS */
  .flipClockLabelSize(@size) {
    font-size: @size;
    height: @size * 2.2;
    line-height: @size * 2.2;
  }

  .flipClockSize(@height) {
    @border-radius: @height / 15;

    ul.flip {
      border-radius: @border-radius;
      width: @height * 1.3;
      height: @height;
      font-size: @height * 0.7;
      line-height: @height;
      box-shadow: 0 (@border-radius / 3) (@border-radius / 2) rgba(0, 0, 0, 0.7);

      &:after {
        top: @height;
        .flipClockLabelSize(@flip-clock-label-size-default);
      }
    }

    &.flip-clock--xsmall ul.flip:after {
      .flipClockLabelSize(@flip-clock-label-size-xsmall);
    }

    &.flip-clock--small ul.flip:after {
      .flipClockLabelSize(@flip-clock-label-size-small);
    }

    &.flip-clock--xlarge ul.flip:after {
      .flipClockLabelSize(@flip-clock-label-size-xlarge);
    }

    ul.flip li .flipper div.up:after {
      top: ~"calc((" @height ~" / 2) - 1px)";
    }

    ul.flip li .flipper div div.inn {
      border-radius: @border-radius;
    }

    ul.flip:nth-child(1):after {
      content: @flip-clock-label-days;
    }

    ul.flip:nth-child(2):after {
      content: @flip-clock-label-hours;
    }

    ul.flip:nth-child(3):after {
      content: @flip-clock-label-mins;
    }

    ul.flip:nth-child(4):after {
      content: @flip-clock-label-secs;
    }

    &.flip-clock--xsmall,
    &.flip-clock--small {
      ul.flip:nth-child(3):after {
        content: @flip-clock-label-mins-short;
      }

      ul.flip:nth-child(4):after {
        content: @flip-clock-label-secs-short;
      }
    }
  }
  /* SIZES */
  // default size
  .flipClockSize(@flip-clock-size-default);

  &.flip-clock--xsmall {
    .flipClockSize(@flip-clock-size-xsmall);
  }

  &.flip-clock--small {
    .flipClockSize(@flip-clock-size-small);
  }

  &.flip-clock--large {
    .flipClockSize(@flip-clock-size-large);
  }

  &.flip-clock--xlarge {
    .flipClockSize(@flip-clock-size-xlarge);
  }

  &.flip-clock--invert ul.flip li .flipper div div.inn {
    background-color: @flip-clock-color-dark;
    color: @flip-clock-background-color;
  }

  &.flip-clock--hidden {
    opacity: 0;
  }
  /* STYLES */
  display: block;
  padding: 0.5rem 0.5rem 2.5rem;

  ul.flip {
    transition: all 200ms ease-out;
    user-select: none;
    position: relative;
    list-style-type: none;
    padding: 0;
    float: left;
    margin: 0 0.08em;
    font-weight: 600;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      font-weight: 600;
      text-align: center;
      color: @flip-clock-label-color;
    }
  }

  &.flip-clock--label-light ul.flip:after {
    color: @flip-clock-label-color-light;
  }

  ul.flip li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  ul.flip li:first-child {
    z-index: 2;
  }

  ul.flip li .flipper {
    display: block;
    height: 100%;
  }

  ul.flip li .flipper div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
  }

  ul.flip li .flipper div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  ul.flip li .flipper div.up {
    transform-origin: 50% 100%;
    top: 0;
  }

  ul.flip li .flipper div.up:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 0.3rem;
    background-color: rgba(0, 0, 0, 0.4);
  }


  ul.flip li .flipper div.down {
    transform-origin: 50% 0;
    bottom: 0;
  }

  ul.flip li .flipper div div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: @flip-clock-color-dark;
    text-align: center;
    background-color: @flip-clock-background-color;
  }

  ul.flip li .flipper div div.invert {
    background-color: @flip-clock-color-dark;
    color: @flip-clock-background-color;
  }

  &.flip-clock--light ul.flip li .flipper div div.inn {
    color: @flip-clock-color-light;
    background-color: @flip-clock-background-color-light;
  }

  ul.flip li .flipper div.up div.inn {
    top: 0;
  }

  ul.flip li .flipper div.down div.inn {
    bottom: 0;
  }
  /* PLAY */
  &.play ul li.before {
    z-index: 3;
  }

  &.play ul li.active {
    animation: flipclock-change-overlay @flip-clock-turn-duration @flip-clock-turn-duration linear both;
    z-index: 2;
  }

  @keyframes flipclock-change-overlay {
    0% {
      z-index: 2;
    }

    5% {
      z-index: 4;
    }

    100% {
      z-index: 4;
    }
  }

  &.play ul li.active .down {
    z-index: 2;
    animation: flipclock-turn @flip-clock-turn-duration @flip-clock-turn-duration linear both;
  }

  @keyframes flipclock-turn {
    0% {
      transform: rotateX(90deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  &.play ul li.before .up {
    z-index: 2;
    animation: flipclock-turn2 @flip-clock-turn-duration linear both;
  }

  @keyframes flipclock-turn2 {
    0% {
      transform: rotateX(0deg);
    }

    100% {
      transform: rotateX(-90deg);
    }
  }
  /* SHADOW */
  &.play ul li.before .up .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
    animation: flipclock-show @flip-clock-turn-duration linear both;
  }

  &.play ul li.active .up .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
    animation: flipclock-hide @flip-clock-turn-duration @flip-clock-turn-duration linear both;
  }

  &.flip-clock--label-light {
    &.play ul li.active .up .shadow,
    &.play ul li.active .up .shadow {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
  }
  /* DOWN */
  &.play ul li.before .down .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    animation: flipclock-show @flip-clock-turn-duration linear both;
  }

  &.play ul li.active .down .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%);
    animation: flipclock-hide @flip-clock-turn-duration @flip-clock-turn-duration linear both;
  }

  &.flip-clock--label-light {
    &.play ul li.before .down .shadow,
    &.play ul li.active .down .shadow {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
    }
  }

  @keyframes flipclock-show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes flipclock-hide {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
  /* NO FLIPPING */
  &.flip-clock--static.play ul li {
    &.active,
    &.before {
      .up,
      .up .shadow,
      .down,
      .down .shadow {
        z-index: 1;
        animation: none;
      }
    }
  }
}
