/* ===========================================
   elements.paragraph
   ========================================= */
// Elements – unclassed HTML elements, redefine browsers' default styling

@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond PoCaGa
.avalon-area {
  p:not([class]) {
    margin-top: 0;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
