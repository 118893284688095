@import (reference) "./../../_imports.less";

.avalon-cards.card-layout-2 {
  padding: 0;

  &.cards__padding-top {
    padding-top: 2rem;
  }

  &.cards__padding-bottom {
    padding-bottom: 2rem;
  }

  .card:not(:first-child) {
    margin-top: 2rem;
  }
}
