/* ===========================================
   component.nav-spot
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

@gap: @item-margin; // Gap between components when used together with image-abstract or other nav spots

.avalon-nav-spot {
  padding: @spacing-unit--lg 0;

  /* ******** Modifying factors ******** */

  // Adjust grid layout when rendered directly after
  // navigation spot or image abstract spot
  .avalon-nav-spot + &{
    margin-top: -(@container-padding - @gap);
    padding: 0 0 @container-padding;
  }


  /* ************ Elements ************* */

  &__wrapper {
    position: relative;
  }


  &__caption {
    max-width: 50%;
    max-height: 100%;
    padding: 2rem;

    position: absolute;
    bottom: 0;
    right: 0;

    background: @color-black-50;
    color: @color-white-100;
    text-align: left;

    @media screen and (max-width: @medium) {
      max-width: 80%;
      font-size: @font-size--sm;
    }
  }

  &__caption-body {
    margin: 0;

    &:not(:empty) {
      margin-top: @spacing-unit--sm;
    }
  }
}
