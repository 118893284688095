/* ===========================================
   component.hero
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

@avalonHeroHeight: 34rem;

.avalon-hero {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-position: top center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;
  min-height: 35rem;
}

.avalon-hero--link {
  cursor: pointer;
}

.avalon-hero__background-link {
  text-decoration: none;
}

.avalon-hero__background {
  img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.avalon-hero__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: calc(~'100% - 3rem');
  text-align: center;
  
  @media @xxl {
    height: calc(~'100% - 2rem');
  }

  .mode-edit & {
    height: auto;
  }

  .avalon-hero__primary-image {
    max-height: 100%;

    @media (max-width: @medium) {
      height: auto;
      max-width: 100%;
    }
  }

  .avalon-hero__heading {
    margin: 1rem 0;
    width: 100%;
    max-width: @medium;
    overflow-wrap: break-word;
    hyphens: auto;
    color: @color-white-100;

    &.black {
      color: @color-black;
    }

    &.is-impact {
      font-family: @font-impact;
      font-style: italic;
    }
  }

  .avalon-hero__text {
    max-width: @medium;
    margin: 1rem 0;
    width: 100%;
    color: @color-white-100;

    &.black {
      color: @color-black;
    }

    @media (max-width: @medium) {
      display: none;
    }
  }
}

.avalon-hero__content-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 7rem;
  padding: 0.5rem 0.5rem 1rem;

  @media @sm {
    padding-bottom: 0;
  }
  
  .rules-and-conditions {
    margin-bottom: -0.5rem;

    @media @sm {
      margin-bottom: 0.5rem;
    }
  }
}

.avalon-hero__cta-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media @sm {
    flex-direction: row;
  }
}

.avalon-hero__cta {
  margin-left: 1.5rem;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: @medium) {
    width: 100%;
    margin: 1rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.avalon-hero__dynamic-content {
  display: flex;
  flex-direction: column;

  @media @sm {
    flex-direction: row;
  }
}
