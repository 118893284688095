/* ===========================================
   component.related-games
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-related-games {
  background-color: darken(@color-primary, 3%);
  color: @color-white;

  &__title {
    text-align: center;
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }


  // Item styles
  &__item {
    display: flex;
    flex-basis: calc(~'50% - @{item-margin} / 2');
    flex-direction: column;

    background-color: @color-white-100;
    color: @color-black;

    @media screen and (max-width: @medium) {
      flex-basis: 100%;
      &:not(:first-child) {
        margin-top: @item-margin;
      }
    }

    &:only-of-type {
      flex-basis: 100%;
      .related-games__item__image {
        &--overlay {
          font-size: @font-size--xl;
        }
      }
    }
  }

  &__item-header {
    flex: 0 0 auto;
    position: relative;
  }

  &__item-image {
    width: 100%;
    height: auto;
    display: block;
    flex: none;
  }

  &__item-overlay {
    position: absolute;
    bottom: 0;
    background-color: @color-black-50;
    font-size: @font-size--lg;
    font-weight: 600;
    padding: 1rem;
    color: @color-white;
    width: 100%;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: @medium) {
      font-size: @font-size;
      word-break: break-all;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 3rem;

    @media screen and (max-width: @medium) {
      padding: 1.5rem;
    }
  }

  &__item-paragraph {
    font-size: @font-size--sm;
  }

  &__item-cta {
    margin: auto auto 0 auto;
    padding-top: @item-margin;
    align-self: center;

    @media screen and (max-width: @medium) {
      padding: 1rem 0 0 0;
    }
  }

  &__item-button {
    margin: 0;
    text-align: center;

    @media @sm {
      min-width: 12.5rem;
    }
  }
}
