/* ===========================================
   elements.anchor
   ========================================= */

// Elements – unclassed HTML elements, redefine browsers' default styling
@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond PoCaGa
.avalon-area {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &:not([class]) {
      color: inherit;
      font-weight: 600;
      transition: .25s ease;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        color: @color-primary;
      }
    }
  }
}
