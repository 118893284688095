﻿@import (reference) "./../_imports.less";

.winner-table-with-header {
  padding: 0 3rem;
  color: @component-winner-table-with-header--color;
}

.winner-table-with-header__inner {
  margin: 2rem auto 4rem;
  max-width: 96rem;
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: @component-winner-table-with-header__inner--box-shadow;
}

.winner-table-with-header__header {
  background: @component-winner-table-with-header__header--bg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media @lg {
    flex-direction: row;
  }
}

.winner-table-with-header__header-image {
  overflow: hidden;
  width: 100%;
  line-height: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // image is visible in sitecore only, to help the editor insert background image
  img {
    max-width: 100%;
    height: inherit;
  }

  @media @lg {
    order: 1;
    flex: 1;
    margin: 2rem 2rem 2rem 0;
    border-radius: 0.6rem;
  }
}

.winner-table-with-header__header-text {
  width: 100%;
  padding: 1rem 0 1.5rem;
  margin: 0;
  flex: 1;

  @media @lg {
    padding: 2rem 0 2.5rem;
  }

  .winner-table-with-header__title {
    font-size: 1.9rem;
    line-height: 1.2;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0 2rem;

    @media @md {
      font-size: 2.4rem;
    }
  }

  .winner-table-with-header__text {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 0 2rem;
  }
}

.winner-table-with-header {
  table {
    color: @component-winner-table-with-header--color;
    border-spacing: @component-winner-table-with-header__table--border-spacing;
    border-collapse: separate;
    background-color: @component-winner-table-with-header__table--bg;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.3;
  }

  th {
    background: @component-winner-table-with-header__th--bg;
    font-weight: bold;
  }

  td,
  th {
    padding: 1rem 1.5rem;
    text-align: left;
    vertical-align: top;

    @media @lg {
      padding: 1.4rem 2rem;
    }
  }

  th {
    padding: 0.7rem 2rem;
  }

  td {
    background-color: @component-winner-table-with-header__table--bg-rows;
  }

  // don't split the date
  td:nth-of-type(2) {
    white-space: nowrap;
  }

  @media only screen and (max-width: @medium) {
    // force table to not be like tables anymore
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    // hide table headers (but not display: none;, for accessibility)
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-top: 0.1rem solid transparent;
    }

    tr:nth-child(odd) {
      background: @component-winner-table-with-header__table--bg-rows-odd;

      td {
        background: none;
      }
    }

    td {
      // behave  like a "row"
      position: relative;
      padding-top: 1.5em;
      padding-bottom: 0;

      &:last-child {
        padding-bottom: 1rem;
      }
    }

    td:before {
      // now behave like a table header
      position: absolute;
      // Top/left values mimic padding
      top: 0;
      left: 0.6rem;
      width: 45%;
      padding: 0.5rem 1rem;
      color: fade(@component-winner-table-with-header--color, 50%);
      font-size: 1.3rem;
    }

    td:nth-of-type(1):before {
      content: "Vinder";
    }

    td:nth-of-type(2):before {
      content: "Dato";
    }

    td:nth-of-type(3):before {
      content: "Præmie";
    }
  }
}
