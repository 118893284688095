﻿@import (reference) "./../_imports.less";

.region-spot {
  max-width: 184rem;
  margin: 0 auto 2rem;
  overflow: hidden;

  @media (min-width: @breakpoint-region-spot-medium) {
    margin-bottom: 6rem;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .region-mode {
    width: 100%;
    white-space: nowrap;
  }

  .region-spot__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: absolute;
    top: 2rem;
    right: 0;
    bottom: 0;
    left: 2rem;
    overflow: hidden;
    border-radius: 0.6rem;
    text-align: center;
    cursor: pointer;

    @media (min-width: @breakpoint-region-spot-medium) {
      &:hover .region-spot__bg {
        transform: scale(1.1) rotate(0.1deg);
      }
    }
  }

  .region-spot__item {
    display: inline-block;
    white-space: normal;
    position: relative;
    width: 30rem;
    height: 30rem;
    outline: none;

    &:last-child {
      margin-right: 2rem;
    }

    @media (min-width: @breakpoint-region-spot-medium) {
      float: left;
      height: auto;
      margin: 0;
      max-width: none;
      width: 50%;

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  @media (min-width: @breakpoint-region-spot-medium) {
    .region-spot__item--1 {
      .region-spot__content-wrapper {
        right: 1rem;
      }
    }

    .region-spot__item--2 {
      &:after {
        padding-bottom: 50%;
      }

      .region-spot__content-wrapper {
        left: 1rem;
        right: 2rem;
      }
    }

    .region-spot__item--3 {
      width: 25%;

      .region-spot__content-wrapper {
        left: 1rem;
        right: 1.5rem;
      }
    }

    .region-spot__item--4 {
      width: 25%;

      .region-spot__content-wrapper {
        left: 0.5rem;
        right: 2rem;
      }
    }
  }

  .region-spot__bg {
    position: absolute;
    z-index: 1;
    top: -0.1rem;
    right: -0.1rem;
    bottom: -0.1rem;
    left: -0.1rem;
    overflow: hidden;
    transition: transform 300ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &.oddset-blue-gradient {
      background-image: radial-gradient(ellipse at center,
      rgba(0, 53, 130, 1) 0%,
      rgba(0, 53, 130, 1) 33%,
      rgba(0, 0, 0, 1) 99%);
    }

    & img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .region-spot__brand {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 7rem;
    height: 3rem;
    border-radius: 0 0 0 .6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: @breakpoint-region-spot-large) {
      width: 10rem;
      height: 4rem;
    }

    img {
      // IE requires a width and height in order to scale properly 
      width: 80%;
      height: 80%;
      max-width: 80%;
      max-height: 70%;
    }
  }

  .region-spot__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(~'100% - 3rem');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @media (min-width: @breakpoint-region-spot-large) {
      height: calc(~'100% - 2rem');
    }

    .flip-clock {
      margin-top: 1rem;
    }
  }

  .region-spot__content-text {
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.25;
    color: white;
    padding: 0 1rem;
    z-index: 0;

    @media (min-width: @breakpoint-region-spot-medium) {
      font-size: 3.5rem;
    }

    @media (min-width: @breakpoint-region-spot-large) {
      font-size: 2.5vw;
    }
  }

  .region-spot__oddset-teams {
    line-height: 1.2rem;
    margin-top: 1rem;
    width: 100%;
  }

  .region-spot__oddset-teams-name {
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(~'100% - 2rem');
    text-overflow: ellipsis;
    display: inline-block;
  }

  .region-spot__content-1x2 {
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    margin: 1rem 0 0;
    color: @ds-dark-blue-600;
    font-weight: 800;
    text-align: center;

    @media (min-width: @breakpoint-region-spot-large) {
      margin: 3rem 0;
      font-size: 1.6rem;
    }

    &--1,
    &--x,
    &--2 {
      margin: 0 0.2rem;
      padding: 0.5rem 0.75rem;
      background-color: @color-white;
      border-radius: 0.6rem;

      &:before {
        color: @ds-orange;
        margin-right: 0.3rem;
      }

      @media (min-width: @breakpoint-region-spot-large) {
        margin: 0 0.4rem;
        padding: 0.6rem 1rem;
      }
    }

    &--1:before {
      content: "1";
    }

    &--x:before {
      content: "X";
    }

    &--2:before {
      content: "2";
    }
  }

  .region-spot__content-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 7rem;
    padding: 0.5rem 0.5rem 0;
  }

  .region-spot__content-cta {
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-decoration: none;
    text-align: center;
    line-height: 3.5rem;
    transition: all 200ms;
    border-radius: 0.6rem;
    font-weight: 900;
    background-color: #FEB700;
    display: inline-block;
    padding: 0 3rem;
    white-space: nowrap;
    margin: 0 auto;

    &:hover {
      background-color: #cb9200;
    }
  }

  .region-spot__item--2,
  .region-spot__item--3,
  .region-spot__item--4 {
    .region-spot__content-text {
      @media (min-width: @breakpoint-region-spot-medium) {
        font-size: 2rem;
      }

      @media (min-width: @breakpoint-region-spot-large) {
        font-size: 2vw;
      }
    }
  }
}
