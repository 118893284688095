/* ===========================================
   utility.grid
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
.avalon-area {
  --color-primary: #feb700;
  --color-primary--light: #ffc328;
  --color-primary--dark: #d59a00;
  --color-secondary--dark: #002d70;
  --color-button-theme: #feb700;
  --color-background-overlay: #a78952;
}
.avalon-hero {
  align-items: center;
}
.avalon-hero__content {
  justify-content: center;
}
.avalon-hero__feed-teams {
  color: var(--feedTextColor, white);
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1;
  margin: 1rem 0;
  font-style: italic;
  transition: color .3s ease;
}
@media all and (max-width: 768px) {
  .avalon-hero__feed-teams {
    font-size: 8vw;
  }
}
.pocaga-facts .avalon-hero__feed-teams {
  color: #feb700;
}
.avalon-hero__feed-team {
  display: inline-block;
}
.avalon-hero__feed__cta-wrapper .avalon-button--theme {
  background: #003e99;
  color: #fff;
}
.avalon-hero__feed-link {
  color: black;
}
.avalon-hero__feed-link:hover .pocaga-hero__feed-teams {
  color: #003e99;
}
.pocaga-facts .avalon-hero__feed-link:hover .pocaga-hero__feed-teams {
  color: #ffc328;
}
.avalon-hero__odds-container {
  text-align: center;
}
.pocaga-facts .avalon-hero__odds-container {
  display: flex;
  justify-content: space-between;
}
.avalon-hero__odds {
  color: var(--feedTextColor, white);
  display: inline-block;
  margin: 0 1rem;
  font-size: 3rem;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-style: italic;
}
@media all and (max-width: 768px) {
  .avalon-hero__odds {
    font-size: 4.5vw;
  }
}
.pocaga-facts .avalon-hero__odds {
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin: 0;
  color: white;
}
.avalon-hero__odds-type {
  background: var(--oddsBackgroundColor, #ed6b19);
  box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.25);
  padding: 0.2rem 0;
  width: 4rem;
  display: block;
  float: left;
  text-align: center;
  font-family: AvenirNext, Impact, "Anton Regular", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  font-style: normal;
  color: var(--oddsTextColor, white);
  margin-right: 0.5rem;
  margin-top: 0.4rem;
}
@media all and (max-width: 768px) {
  .avalon-hero__odds-type {
    font-size: 3.5vw;
    width: 6.5vw;
    height: 6.5vw;
    padding: 0.5vw 0;
    margin-top: 0.3vw;
  }
}
@media all and (max-width: 768px) {
  .pocaga-facts .avalon-hero__odds-type {
    font-size: 7.5vw;
    width: 13.5vw;
    height: 13.5vw;
  }
}
