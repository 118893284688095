/* ===========================================
   utility.sitecore-edit
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

.avalon-sitecoreedit {
  font-size: 11px;
  font-weight: normal;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #111;
  background: #eee;
  padding: 10px;
  text-align: left;

  span[scfieldtype="single-line text"],
  span[scfieldtype="droplink"] {
    display: block;
    border: 1px solid #333;
    margin: 2px 0 5px;
    padding: 1px 4px;
    background: white;
  }

  img,
  img& {
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 40px;
  }
}
