/* ===========================================
   utility.hero-banners
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

@import "_imports.less";

.region-avalon-frontpage{
  .poker-game-gallery { 
    background:@color-white;
    padding-top:4rem;

    @media screen and (max-width: @small){  
      padding-top:7vw;
      &:first-of-type{ 
        margin-bottom:-2vw;
      }
    }

    &__wrapper{ 
      @media screen and (max-width: 768px){   
        padding:2vw;
      } 
    }

    &__item{ 
      flex-basis:22% !important;
      flex: 0 0 22% !important;
      margin:2% 1.5% !important; 
      position:relative;
      box-shadow:0 1rem 1rem -1rem rgba(0, 0, 0, 0.3)   ;

      @media screen and (max-width:@medium){ 
        flex: 0 0 31.3% !important;
        margin:2% 1% !important;
      }
    }

    &__item-cta{ 
      display:none;
    }

    &__item-text{ 
      position:absolute;
      top:-4rem;
      left:0;
      font-style:italic;
      width:300%;
      color:@color-black;
      text-align:left;
      margin-top:1rem;

      @media screen and (max-width:@medium){ 
        top:-7.5vw;
        font-size:3vw;
      }
      @media screen and (max-width:@small){ 
        top:-9vw;
      }
    }
  }
}
