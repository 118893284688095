@import (reference) "./../../_imports.less";

.avalon-cards.card-layout-1 {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.cards__padding-top {
    padding-top: 2rem;
  }

  &.cards__padding-bottom {
    padding-bottom: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
    
    @media @sm {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  .card {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: calc(~'50% - 2rem');
    margin: 1rem;

    @media @sm {
      flex-basis: calc(~'25% - 2rem');
    }
  }
}
