/* ===========================================
   component.read-more-spot
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-area .avalon-read-more-spot .u-grid--medium{
  max-width: 128rem;
}

.avalon-read-more-spot {
  background: transparent;
}

.avalon-read-more-spot__title {
  text-align: center;
}

.avalon-read-more-spot__body {
  font-size: @font-size--sm;

  // Rich-text content styles
  img {
    max-width: 100%;
    height: auto;
  }

  ul:not([class]),
  ol:not([class]) {
    padding-left: 2.5rem;
    list-style-type: none;

    li:before {
      left: -3rem;
      color: var(--color-primary--dark, @color-primary--dark);
    }
  }

  ul:not([class]) {
    @media @sm {
      columns: 2;
      gap: 6rem;
    }
  }

  ul:not([class]) {
    li:before {
      left: -2.5rem;
      text-align: left;
      line-height: 0.8;
    }
  }
}

.avalon-read-more-spot__content--initial {
  margin-top: @item-margin;
}

.avalon-read-more-spot__content--more {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  padding-top: @item-margin;
  transition: grid-template-rows .4s ease-out;

  .avalon-read-more-spot__content--more-wrap {
    overflow: hidden;
  }

  .is-open & {
    grid-template-rows: 1fr;
  }
}


.avalon-read-more-spot__cta {
  display: block;
  max-width: 150px;
  margin: @item-margin auto 0;

  font-size: @font-size--sm;
  font-weight: 900;
  color: @component-read-more-text-spot-cta-color;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: @component-read-more-text-spot-cta-color-hover;

    .avalon-read-more-spot__cta-icon {
      fill: @component-read-more-text-spot-cta-color-hover;
    }
  }
}

.avalon-read-more-spot__cta-text {
  display: inline-block;
  vertical-align: middle;
}

.avalon-read-more-spot__cta-icon {
  margin-left: 1rem;
  fill: @component-read-more-text-spot-cta-color;
  vertical-align: middle;
  transition: .25s ease;

  .is-open & {
    transform: rotate(-180deg);
  }
}


// Three column layout styling
.three-col {
  .three-col__col--center {
    .avalon-read-more-spot__content ul li {
      flex-basis: 100%;
    }
  }
}

// Edit mode styling (Sitecore)
.mode-edit {
  .avalon-read-more-spot__content--more {
    display: block;

    &::before {
      margin-top: 2rem;
      content: 'Below is the initially hidden content. This text will not be seen when published!';
      color: @color-gray-300;
      font-style: italic;
      text-align: center;
      text-decoration: underline;
      display: block;
    }
  }
}

