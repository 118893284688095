/* ===========================================
   utility.color-helpers
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

@import (reference) "./../_imports.less";

.avalon-area {
  // text colours
  .u-text--white {
    color: @color-white;
  }

  .u-text--black {
    color: @color-black;
  }

  .u-text--yellow {
    color: @color-yellow;
  }

  .u-text--primary {
    color: @color-primary;
    color: var(--color-primary);
  }

  .u-text--secondary {
    color: @color-secondary;
    color: var(--color-secondary);
  }

  // background colours
  .u-background--white {
    background-color: @color-white;
  }

  .u-background--black {
    background-color: @color-black;
  }

  .u-background--primary {
    background-color: @color-primary;
    background-color: var(--color-primary);
  }

  .u-background--secondary {
    background-color: @color-secondary;
    background-color: var(--color-secondary);
  }

  .u-background--secondary-dark {
    background-color: @color-secondary--dark;
    background-color: var(--color-secondary--dark);
  }
}
