@import (reference) "./../Colors/_colors";
@import (reference) "./../Variables/breakpoints";
@import (reference) "./../../../AvalonLiveCasino/Styles/_colors";

.hero-livecasino-games {
  position: relative;
  margin: 3rem 0;
  width: 100%;
  min-height: 34rem;
}

.hero-livecasino-games__bg {
  display: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: repeat;
  clip-path: inset(0 0% 0% -1rem round 0.7rem);
  transform: skewX(-15deg) translateX(-5rem);

  @media @sm {
    display: block;
  }

  @media @xxl {
    left: -9rem;
  }

  .hero-livecasino-games--flipped & {
    @media @sm {
      left: auto;
      right: 0;
      clip-path: inset(0 0 0 1rem round .7rem);
      transform: skewX(15deg) translateX(5rem);
    }

    @media @xxl {
      right: -9rem;
    }
  }
}

.hero-livecasino-games__bg-inner {
  height: 100%;
  width: 100%;
  transform: skewX(15deg) translateX(5rem);

  .hero-livecasino-games--flipped & {
    @media @sm {
      transform: skewX(-15deg) translateX(-5rem);
    }
  }
}

.hero-livecasino-games__inner {
  display: flex;
  flex-direction: column-reverse;
  max-width: 128rem;
  margin: 0 auto;

  @media @sm {
    flex-direction: row;
  }

  .hero-livecasino-games--flipped & {
    @media @sm {
      flex-direction: row-reverse;
    }
  }
}

.hero-livecasino-games__cards {
  padding-bottom: 1rem;
  background-position: center;
  background-repeat: repeat;
  flex: 1 1 50%;

  @media @sm {
    padding: 2rem 0 0 2rem;
    background-size: 0;
    margin-right: 1rem;

    .hero-livecasino-games--flipped & {
      padding: 2rem 2rem 0 0;
      margin-right: auto;
      margin-left: 1rem;
    }
  }
}

.hero-livecasino-games__right {
  padding: 2rem;
  flex: 1 1 50%;

  @media @sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem 0 0;
    margin-left: 1rem;

    .hero-livecasino-games--flipped & {
      padding: 0 0 0 2rem;
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  @media @lg {
    padding: 0;

    .hero-livecasino-games--flipped & {
      padding: 0;
    }
  }

  a:not(.livecasino-button) {
    color: @color-livecasino-gold;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hero-livecasino-games__cards-header {
  position: relative;
  font-size: 1.8rem;
  font-weight: bold;
  color: @color-white;
  padding: 2rem;

  @media @sm {
    display: none;
  }
}

.hero-livecasino-games__gamecard-content {
  margin: auto;
  display: block;

  .is-mobile-detected & {
    width: 100%;
    max-width: unset;
    position: relative;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &::-webkit-scrollbar {
      height: 1.6rem;
    }

    &::-webkit-scrollbar-button {
      width: 1rem;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: @color-livecasino-gold;
      border: 0.3rem solid @scroll-border;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.6rem fade(@color-black, 90%);
      border-radius: 1rem;
      background-color: fade(@color-black, 30%);
    }

    &::after {
      content: '';
      padding: 1rem;
    }
  }

  .hero-livecasino-games__inner-wrapper.hero-livecasino-games__inner-wrapper--active {
    position: relative;

    .livecasino-grid__element {
      position: absolute;
      z-index: 0;
      filter: brightness(0.4) blur(0.05rem);
      pointer-events: none;

      .campaign-banner {
        visibility: hidden;
      }
    }

    .activeCard() {
      position: relative;
      z-index: 1;
      filter: none;
      pointer-events: all;

      .campaign-banner {
        visibility: visible;
      }
    }

    .beforeCard() {
      transform: translate(-12rem) scale(0.8);
    }

    .afterCard() {
      transform: translate(9rem) scale(0.8);
    }

    .hiddenCard() {
      transform: translate(0) scale(0);
    }

    &:not([data-active-slide]),
    &[data-active-slide="1"] {
      .livecasino-grid__element:nth-child(4) {
        .beforeCard();
      }

      .livecasino-grid__element:nth-child(1) {
        .activeCard();
      }

      .livecasino-grid__element:nth-child(2) {
        .afterCard()
      }

      .livecasino-grid__element:nth-child(3) {
        .hiddenCard()
      }
    }

    &[data-active-slide="2"] {
      .livecasino-grid__element:nth-child(1) {
        .beforeCard();
      }

      .livecasino-grid__element:nth-child(2) {
        .activeCard();
      }

      .livecasino-grid__element:nth-child(3) {
        .afterCard()
      }

      .livecasino-grid__element:nth-child(4) {
        .hiddenCard()
      }
    }

    &[data-active-slide="3"] {
      .livecasino-grid__element:nth-child(2) {
        .beforeCard();
      }

      .livecasino-grid__element:nth-child(3) {
        .activeCard();
      }

      .livecasino-grid__element:nth-child(4) {
        .afterCard()
      }

      .livecasino-grid__element:nth-child(1) {
        .hiddenCard()
      }
    }

    &[data-active-slide="4"] {
      .livecasino-grid__element:nth-child(3) {
        .beforeCard();
      }

      .livecasino-grid__element:nth-child(4) {
        .activeCard();
      }

      .livecasino-grid__element:nth-child(1) {
        .afterCard()
      }

      .livecasino-grid__element:nth-child(2) {
        .hiddenCard()
      }
    }
  }

  .livecasino-grid__element {
    text-align: left;
    width: 21rem;
    position: relative;
    transition: inherit;

    .is-mobile-detected & {
      width: 21rem;
      margin-left: 2rem;
      margin-right: 0;

      @media @sm {
        margin-left: 5rem;
      }

      &:last-child {
        margin-right: 2rem;
      }
    }

    .info-text {
      white-space: pre-wrap;
    }

    .campaign-banner {
      position: absolute;
      z-index: -1;
      left: -3rem;
      width: 3rem;
      background: #223D3D;
      top: 3.5rem;
      bottom: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        transform: rotate(-90deg);
        color: @color-livecasino-gold;
        font-size: 1.5rem;
        text-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.7);
        letter-spacing: 0.1rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      &::before,
      &::after {
        height: 3.5rem;
        content: '';
        background: url('/Components/DanskeSpil/Domain/AvalonLiveCasino/Graphics/livecasino-corner.svg') no-repeat center center;
        background-size: 100%;
        position: absolute;
        top: -3rem;
        right: -0.1rem;
        left: 0;
      }

      &::after {
        top: auto;
        bottom: -3rem;
        transform: scaleY(-1);
      }
    }
  }
}

.hero-livecasino-games__content--smooth {
  transition: 0.3s;
}

.hero-livecasino-games__content {
  display: inherit;
  width: 21rem;
  position: relative;
  float: right;

  :not(.is-mobile-detected) & {
    margin: auto;
    float: none;

    @media @xxl {
      margin: auto 12rem auto auto;

      .hero-livecasino-games--flipped & {
        margin: auto auto auto 15rem;
      }
    }
  }

  .is-mobile-detected & {
    margin: 0;
  }

  .carousel-arrow {
    fill: white;
    transform: scale(2);
    z-index: 4;
    stroke: white;
    stroke-width: 0.2rem;
    top: 12rem;

    &.left {
      left: -6.2rem;
    }

    &.right {
      right: -4.2rem;
    }
  }

  .hero-livecasino-games__outer-wrapper {
    overflow: visible;
    transition: inherit;

    .hero-livecasino-games__inner-wrapper {
      display: inline-flex;
      white-space: nowrap;
      transition: inherit;
    }
  }

  .dots-wrapper {
    background: transparent;

    .dot {
      border: 0.2rem solid @scroll-border;
      width: 1rem;
      height: 1rem;
      margin: 0.3rem;
      cursor: pointer;

      &.active {
        background: @color-gold;
      }
    }
  }
}
