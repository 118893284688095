﻿/* ===========================================
   component.related-content
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-related-content {
  background-color: @color-primary;
  color: @color-white-100;

  &__title {
    text-align: center;
  }

  &__item {
    list-style-type: none;

    &:not(:first-child) {
      margin-top: @item-margin;
    }
  }

  &__link-area {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    color: @color-white;
    text-decoration: none;
  }

  &__image-wrapper {
    flex: 0 0 auto;
    flex-basis: calc(40%);

    @media screen and (max-width: @small) {
      flex: initial;
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  &__image {
    width: 100%;
    display: block;
    height: auto;
  }

  &__text {
    flex: 0 0 auto;
    flex-basis: calc(~'60% - @{item-margin}');

    @media @sm {
      font-size: @font-size--sm;
    }

    @media screen and (max-width: @small) {
      flex: initial;
      width: 100%;
    }
  }
}
