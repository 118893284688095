.app-universe__arrow {
  cursor: pointer;
  width: 1rem;
  height: 100%;
  color: white;
  padding: 0 1.2rem;
  margin: 0 1rem 0 0;
  outline: none;
  opacity: 1;
  transition: 150ms opacity ease-out;
  position: relative;
  display: flex;

  &:before, &:after {
    content: '';
    position: absolute;
    border-color: currentColor;
    border-style: solid;
    box-sizing: border-box;
  }

  &:before {
    width: 100%;
    border-width: 0.3rem 0 0 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &:after {
    width: 1.7rem;
    height: 1.7rem;
    border-width: 0 0 0.3rem 0.3rem;
    transform: translateY(-50%) rotate(45deg);
    left: 0;
    top: 50%;
  }
}
