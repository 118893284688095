﻿@import (reference) "./../_imports.less";

.avalon-cards {

  .card-one {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    background-size: cover;
    background-position: center;
    color: @ds-black;

    &:hover {
      text-decoration: none;
    }

    .card__label {
      position: absolute;
      top: 1rem;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      color: white;
      z-index: 1;
    }

    .card__image {
      width: 100%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: center;

      &--small {
        height: 11rem;

        @media @xl {
          display: none;
        }
      }

      &--large {
        height: 15rem;
        display: none;

        @media @xl {
          display: block;
        }
      }
    }

    .card__content {
      padding: 2rem;
      flex: 1 1 auto;

      &--remove-side-padding {
        padding: 2rem 0;

        @media @sm {
          padding: 2rem;
        }
      }
    }

    .card__cta-wrapper {
      padding: 0 0 2rem;
      text-align: center;
    }
  }
}
