﻿@import (reference) "./../_imports.less";

@color-nectar-red: #824D65;

.promo-video-spot {
  position: relative;
  padding: 3rem 2rem;
  display: grid;
  gap: 0.1rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "video" "pretitle" "title" "details" "cta" "text" "screenshots";

  @media @sm {
    padding: 4rem;
    display: grid;
    grid-template-columns: 1fr 23%;
    grid-template-rows: auto;
    grid-template-areas: "video screenshots" "pretitle pretitle" "title title" "details details" "cta cta" "text text";
  }

  &.promo-video-spot--no-screenshots {
    @media @sm and (max-width: (@xl-breakpoint - 1px)) {
      grid-template-columns: 1fr;
      grid-template-areas: "video" "pretitle" "title" "details" "cta" "text";
    }
  }

  @media @xl {
    padding-top: 5rem;
    grid-template-columns: 1fr minmax(auto, 76rem) minmax(auto, 77rem) 1fr;
    grid-template-rows: repeat(4, auto) 1fr auto;
    grid-template-areas: ". pretitle video ." ". title video ." ". text video ." ". details video ." ". cta video ." ". screenshots screenshots .";
  }
}


.promo-video-spot__pretitle {
  font-family: var(--font-family-raleway);
  font-weight: 900;
  font-style: italic;
  grid-area: pretitle;
  font-size: 2rem;
  line-height: 1.35;
  max-width: 62rem;
  margin-bottom: 0.5rem;
}

.promo-video-spot__title {
  position: relative;
  font-family: var(--font-family-raleway);
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  grid-area: title;
  font-size: 3rem;
  line-height: 1.35;
  max-width: 62rem;
  margin-bottom: 3.5rem;

  &::after {
    content: "";
    position: absolute;
    bottom: -0.6rem;
    left: 0;
    height: 0.5rem;
    width: 10rem;
    background-color: @ds-gold;
    border-radius: 0.5rem;
  }
}

.promo-video-spot__text {
  grid-area: text;
  max-width: 62rem;
  font-size: 1.6rem;
  line-height: 1.5625;

  margin-bottom: 3rem;

  @media @sm {
    margin-bottom: 0;
  }

  @media @xl {
    margin-bottom: 2.5rem;
  }
}

.promo-video-spot__details {
  font-family: var(--font-family-raleway);
  grid-area: details;
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  justify-content: space-around;

  @media @sm {
    margin-bottom: 2rem;
    max-width: 50rem;
  }
}

.promo-video-spot__detail {
  flex: 0 1 calc(~'33.3333% - 1rem');
}

.promo-video-spot__detail-key {
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.promo-video-spot__detail-value {
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: 700;
}

.promo-video-spot__cta {
  grid-area: cta;
  height: 4.7rem;
  min-width: 20rem;
  max-width: 30rem;
  margin: 0 auto 5rem;
  border-radius: 0.4rem;
  background-color: @color-brown-chocolate;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0.1rem;

  &:hover {
    background-color: @color-nectar-red;
  }

  svg {
    width: 1.4rem;
    height: 1.7rem;
  }

  &--desktop {
    display: none;
  }

  @media @sm {
    margin: 0 auto 3rem 0;
    min-width: 24rem;
  }

  @media @xl {
    margin: 0 auto 0 0;

    &--mobile {
      display: none;
    }

    &--desktop {
      display: inline-flex;
    }
  }
}

.promo-video-spot__cta-text {
  margin: 1rem;
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 700;
  color: @ds-white;
}

.promo-video-spot__video {
  grid-area: video;
  margin-bottom: 3rem;
  overflow: hidden;

  padding: var(--promo-video-spot__aspect-ratio, 56.25%) 0 0 0;
  line-height: 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 1rem;
    overflow: hidden;
  }

  img {
    display: none;
  }

  .promo-video-spot--no-video & {
    padding: 0;

    iframe {
      display: none;
    }

    img {
      border-radius: 1rem;
      display: block;
      max-width: 100%;
      line-height: 0;
    }

    @media @sm {
      padding-right: 2rem;
    }

    @media @xl {
      padding-right: 0;
    }
  }

  @media @sm {
    margin: 0 2rem 4rem 0;

    .promo-video-spot--no-video & {
      margin-right: 0;
    }
  }

  @media @xl {
    margin: 0 0 auto;
  }
}

.promo-video-spot__screenshots {
  overflow: hidden;
  grid-area: screenshots;
  margin: 0 -2rem;

  img {
    height: auto;
    border-radius: 1rem;
  }

  .promo-video-spot--no-screenshots & {
    display: none;
  }

  .tns-nav {
    display: flex;
    justify-content: center;
    padding: 1.5rem;

    button {
      margin: 0 0.7rem;
      background: @ds-white;
      width: 0.9rem;
      height: 0.9rem;
      border-radius: 1rem;
      padding: 0;
      border: 0.1rem solid @ds-white;

      &.tns-nav-active {
        width: 1rem;
        height: 1rem;
        background: @color-brown-chocolate;
      }
    }
  }

  @media @sm {
    margin: 0;
    height: auto;
  }

  @media @xl {
    margin-top: 7rem;
  }
}

.promo-video-spot__screenshots-slider {
  img {
    max-width: 100%;
  }

  @media @xl {
    display: flex;
    justify-content: space-between;

    img {
      width: 0;
      flex: 0 0 calc(~'33.3333% - 1rem');

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--init {
    display: flex;
    overflow-x: scroll;
    padding: 0 2rem 2rem 1rem;

    img {
      width: calc(~'100vw - 4rem');
      max-width: 36rem;
      margin-left: 1rem;
    }

    @media @sm {
      display: block;
      overflow: hidden;
      padding: 0;

      img {
        width: 100%;
        max-width: none;
        margin: 0;
      }
    }

    @media @xl {
      display: flex;
      justify-content: space-between;

      img {
        width: 0;
        flex: 0 0 calc(~'33.3333% - 1rem');

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.promo-video-spot__background {
  position: absolute;
  z-index: -1;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--promo-video-spot__bg-color, transparent);
  background-image: var(--promo-video-spot__bg-img-mobile, none);
  background-position: 50% 0;
  background-repeat: repeat-y;
  background-size: 100% auto;

  @media @sm {
    background-image: var(--promo-video-spot__bg-img-tablet, none);
  }

  @media @xl {
    background-image: var(--promo-video-spot__bg-img-desktop, none);
  }
}
