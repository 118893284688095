// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.page-nav-header {
  background-color: @component-page-nav-header-background;
  width: 100%;
  height: 5rem;
  box-shadow: 0 0.4rem 0.2rem -0.2rem fade(@ds-black, 10%);
  position: relative;
  overflow: hidden;
  border-top: 1px solid @component-page-nav-header-background-border;
  display: none;

  &.is-ready {
    display: block;
  }

  &::before,
  &::after {
    pointer-events: none;
    content: '';
    width: 2rem;
    height: 100%;
    background: @component-page-nav-header-background;
    background: linear-gradient(270deg, transparent 0%, @component-page-nav-header-background 100%);
    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    left: auto;
    right: 0;
    transform: scaleX(-1);
  }
}

.page-nav-header__content {
  max-width: @content-max-width;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  min-height: 5rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.page-nav-header__item {
  user-select: none;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  color: @ds-white;
  flex: 0 0 auto;

  &:hover {
    color: @component-page-nav-header-hover-text;
    opacity: 0.5;
  }

  &:first-child {
    padding-left: 1.5rem;
  }

  &:last-child {
    padding-right: 2rem;
  }
}

// Styling for extra element to make sure the header gets correct height
.page-nav-header-static-fix {
  height: 5rem;
}
