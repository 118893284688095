/* ===========================================
   object.icon
   ========================================= */
// Objects – class-based selectors, that define undecorated, unopinionated design patterns

@import (reference) "./../_imports.less";

.avalon-icon {
  @size: 3rem;
  display: inline-block;
  vertical-align: middle;
  width: @size;
  height: @size;
}


// Size modifiers

.avalon-icon--lg {
  @size: 4rem;
  width: @size;
  height: @size;
}

.avalon-icon--sm {
  @size: 1.5rem;
  width: @size;
  height: @size;
}

.avalon-icon--xs {
  @size: 1rem;
  width: @size;
  height: @size;
}
