/* ===========================================
   component.news
   ========================================= */
// Components � class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.news {
  text-align: center;

  .news__section {
    position: relative;
    // fonts
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    background-color: white;
    border-color: @ds-color-primary-dark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media @lg {
      padding-top: 0rem;
    }

    .news-heading {
      color: @ds-color-primary;
      position: relative;
      z-index: 2;

      @media @lg {
        box-sizing: border-box;
      }
    }

    .news__content-wrapper {
      text-align: center;
      z-index: 4;
      padding: 0;
      margin: 0 auto;

      a.heading-link {
        .news__headline {
          color: @ds-color-primary;
          text-transform: uppercase;
        }
      }

      .news__block.single {
        padding: 15vw 0 6vw;

        @media @lg {
          padding: 4rem 0;
          max-width: 58rem;
        }

        .news__headline {
          max-width: 85vw;
          font-size: @font-size--lg;
          font-weight: 900;
          line-height: 5.6vw;
          margin: 1.5rem auto 2rem;
          color: @ds-color-primary;

          @media @lg {
            font-size: 3.5rem;
            line-height: 3.5rem;
          }
        }
      }

      .news__block {
        width: 100%;
        max-width: 85vw;
        border-bottom: .1rem solid @ds-color-primary;
        padding: 8vw 0;
        margin: auto;

        @media @lg {
          max-width: 58rem;
          padding: 4rem 0;
        }

        &:last-child {
          border-bottom: none;
        }

        .news__date {
          font-size: @font-size;
          line-height: normal;

          @media @sm {
            font-size: 2rem;
            line-height: 2rem;
          }
        }

        .news__headline {
          max-width: 86.5vw;
          font-size: @font-size--xl;
          font-weight: 900;
          line-height: 5.6vw;
          margin: 1.5rem auto 2rem;
          text-transform: uppercase;

          @media @sm {
            font-size: 3.3rem;
            line-height: 4rem;
          }
          
          @media @lg {
            font-size: @font-size--xxxl;
          }

          a.heading-link {
            text-decoration: none;
            color: @ds-color-primary;
          }
        }

        .news__teaser-details {
          font-size: @font-size;
          display: block;
          margin-bottom: 5vw;

          @media @sm {
            font-size: 1.8rem;
            margin-bottom: 2rem;
          }

          @media @lg {
            font-size: 1.6rem;
          }
        }

        .svg-icon-arrow-right {
          position: absolute;
          top: -0.3vw;
          right: 3vw;

          @media @lg {
            position: absolute;
            top: 1.2rem;
            right: .6rem;
          }

          svg {
            fill: @color-black;
            width: 0;
            height: 0;

            @media @lg {
              width: 1.3rem;
              height: 0.8rem;
            }
          }
        }

        .btn {
          color: @ds-color-cta-primary--text;
          position: relative;
          background-color: @ds-color-cta-primary;
          text-decoration: none;
          box-sizing: border-box;
          display: inline-block;
          padding: 1.3rem;
          margin: 2rem auto 0;

          @media @lg {
            padding: 1.3rem 2.5rem 1.3rem 1.3rem;
          }
        }
      }
    }
    // News single item view
    .top-spot-filling {
      min-height: 35rem;
    }
  }
}
