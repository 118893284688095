﻿@import (reference) "./../_imports.less";

.shop {
  background-color: #E2E2E2;

  .book-details__back-button-wrapper {
    position: absolute;
    width: 85%;
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: 134rem;

    .book-details__back-button {
      display: block;
      width: 2rem;
      height: 2rem;
      position: relative;

      svg {
        max-width: 100%;
        max-height: 100%;
        fill: @ds-black;
      }
    }
  }

  .shop-overview {
    @media @sm {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }

    .shop-overview__hero {
      background: url("/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Statisk_kalejdoskop_baggrund_WHITE-ON-GREY_4.svg") top center;
      background-size: cover;
      padding-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @ds-black;
      overflow: hidden;

      @media @sm {
        flex-direction: row;
        justify-content: center;
        width: 100%;
      }

      .shop-overview__hero-content-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 94rem;

        @media @md {
          flex-direction: row;
          justify-content: space-between;
          max-width: 85rem;
          padding: 0 2.5rem;
        }

        @media @lg {
          max-width: 102.4rem;
        }

        @media @xl {
          max-width: 121.6rem;
        }

        .shop-overview__hero-texts {
          margin: 0 2rem;
          text-align: center;

          @media @xs {
            text-align: left;
            margin: 3rem 5rem;
          }

          @media @md {
            margin: 3rem 0 5rem 0;
          }

          .shop-overview__hero-pre-header {
            font-weight: 600;
            letter-spacing: 0.2rem;
            margin-bottom: 1rem;

            @media @sm {
              margin-bottom: 0;
            }
          }

          .shop-overview__hero-header {
            .shop-header-style(3rem);

            @media @sm {
              .shop-header-style(6rem);
            }
          }

          .shop-overview__hero-text {

            &--desktop {
              display: none;
            }

            p span {
              background: linear-gradient(90deg, rgba(233, 155, 35, 1) 0%, rgba(254, 235, 172, 1) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: 800;
            }

            @media @sm {
              width: 75%;

              &--desktop {
                display: initial;

                p {
                  margin-bottom: 0;
                }
              }

              &--mobile {
                display: none;
              }
            }
          }

          .shop-overview__hero-mandatory-text {
            font-size: 1.2rem;
          }

          .shop-overview__hero-cta {
            cursor: pointer;
            margin-top: 2rem;
            display: inline-block;
            .shop-button-style();

            @media @sm {
              margin-top: 4rem;
            }
          }
        }

        .shop-overview__hero-image-wrapper {
          margin-top: 3rem;
          margin-bottom: 3rem;
          display: flex;
          justify-content: center;
          width: 100%;

          @media @sm {
            width: 100%;
            margin: 5rem 0;
            align-self: flex-start;
          }

          @media @md {
            width: 100%;
            margin: 8rem 0;
            align-self: flex-start;
          }

          .shop-overview__hero-image {
            width: 80%;
            position: relative;

            @media @sm {
              width: 90%;
            }

            @media @md {
              margin-top: 1rem;
              width: 100%;
            }
          }
        }
      }
    }

    .shop-overview__items {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: @ds-black;
      padding: 7rem 2rem 0 2rem;
      max-width: 102.4rem;

      .shop-overview__items-header {
        .shop-header-style();
        line-height: 5rem;
        margin-bottom: 4rem;
      }

      .shop-overview__items-text {
        margin-bottom: 3rem;

        @media @sm {
          width: 45rem;
        }
      }

      .shop-overview__items-list {
        margin-top: 3rem;

        @media @sm {
          display: inline-block;
          justify-content: center;
          width: 100%;
        }

        .shop-overview__list-item {
          margin-bottom: 10rem;

          @media @sm {
            height: 45rem;
            width: 50%;
            margin: 0 auto 10rem;
            display: inline-block;
          }

          @media @md {
            width: 33%;
          }

          .shop-overview__list-item-image-wrapper {
            position: relative;

            @media @sm {
              width: 27rem;
              margin: 0 auto;
            }

            .shop-overview__list-item-image {
              max-width: 100%;

              &--back {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: none;
              }
            }
          }

          &:hover {
            .shop-overview__list-item-image {
              &--front {
                opacity: 0;
              }

              &--back {
                display: initial;
              }
            }
          }

          .shop-overview__list-item-cta-wrapper {
            margin: 2rem 0;
            display: flex;
            justify-content: center;

            .shop-overview__list-item-cta {
              display: block;
              .shop-button-style();
            }
          }
        }
      }
    }

    .shop-overview__video {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media @md {
        width: 90%;
      }

      @media @lg {
        width: 102.4rem;
      }

      .shop-overvire__video-header {
        .shop-header-style(3rem);
        text-align: center;

        @media @sm {
          .shop-header-style();
        }
      }

      .shop-overview__video-wrapper {
        width: 100%;
        height: 0;
        // aspect ratio of mobile video
        padding-top: calc(~'9/16 * 100%');
        position: relative;
        margin: 3rem 0 10rem 0;

        @media @sm {
          // aspect ratio of desktop video
          padding-top: calc(~'9/16 * 100%');
        }

        .video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &--desktop {
            display: none;
          }

          @media @sm {
            &--desktop {
              display: initial;
            }

            &--mobile {
              display: none;
            }
          }
        }
      }
    }

    .shop-overview__steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @ds-black;
      padding-bottom: 5rem;

      @media @sm {
        padding-bottom: 5rem;
      }

      .shop-overview__steps-header {
        .shop-header-style(3rem);
        text-align: center;

        @media @sm {
          .shop-header-style();
        }
      }

      .shop-overview__steps-wrapper {
        display: table;
        width: 100%;
        margin-top: 2.5rem;

        @media @sm {
          flex-direction: row;
        }

        .shop-overview__step {
          text-align: center;

          @media @xs {
            display: table-row;
            width: 100%;
          }


          @media @sm {
            display: table-cell;
            width: 33%;

            &:not(:last-child) {
              margin-right: 3rem;
            }
          }

          .shop-overview__step-icon {
            width: 6rem;
            height: 6rem;
            margin-top: 1rem;


            @media @xxs {
              margin-top: 2.5rem;
              width: 12rem;
              height: 12rem;
            }

            @media @sm {
              width: 10rem;
              height: 10rem;
            }
          }

          .shop-overview__step-text {
            font-weight: 600;
          }
        }
      }
    }
  }

  .book-details {
    position: relative;
    padding-bottom: 10rem;

    @media @sm {
      padding-top: 10rem;
    }

    #href-link {
      position: absolute;
      left: -1000rem;
    }

    .book-details__navigation {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 2rem 0;
      width: 55%;
      font-size: 1.4rem;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 2.75rem;

      @media @xl {
        width: 85%;
        max-width: 134rem;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .book-details__navigation-button {
        color: @ds-black;
        position: relative;
        display: flex;
        align-items: center;
        user-select: none;

        &:hover {
          cursor: pointer;
        }

        &::after {
          content: '';
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          border-top: @ds-black solid 0.2rem;
          border-left: @ds-black solid 0.2rem;
        }

        &--prev {
          flex-direction: row-reverse;

          &::after {
            transform: rotate(-45deg);
            margin-right: .5rem;

            @media @sm {
              transform: rotate(-45deg);
              margin-bottom: 1.5rem;
            }
          }
        }

        &--next {
          &::after {
            transform: rotate(135deg);
            margin-left: .5rem;

            @media @sm {
              transform: rotate(135deg);
              margin-bottom: 1.5rem;
            }
          }
        }

        @media @sm {
          flex-direction: column-reverse;
          justify-content: center;
        }
      }
    }

    .book-details__details {
      display: flex;
      flex-direction: column;
      color: @ds-black;
      padding-top: 7rem;

      @media @sm {
        flex-direction: row;
        max-width: 95rem;
        margin: 0 auto;
        padding-top: 0;
      }

      .book-details__image-wrapper {
        margin: 3rem 4rem;

        @media @sm {
          margin: 0 4rem;
          width: 45%;
        }

        .book-details__image {
          max-width: 100%;
        }

        .book-details__image-radios {
          display: flex;
          justify-content: space-between;
          width: 15rem;
          margin: 2rem auto 0;

          @media @sm {
            margin-top: 3rem;
            width: 10rem;
          }

          .book-details__image-radio {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: @component-shop-radio-button;
            opacity: 0.3;
            cursor: pointer;

            @media @sm {
              width: 1.5rem;
              height: 1.5rem;
            }

            &--selected {
              opacity: 1;
            }
          }
        }
      }

      .book-details__texts {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 2rem;

        @media @sm {
          align-items: flex-start;
          width: 55%;
        }

        .book-details__title {
          margin-bottom: 4rem;
          text-align: center;
          line-height: 5rem;
          .shop-header-style();
        }

        .book-details__description {
          width: 100%;
          margin-bottom: 5rem;
        }

        .book-details__price {
          margin: 1rem 0;
          font-size: 5rem;
          font-weight: 700;
        }

        .book-details__cta {
          .shop-button-style();

          &:hover {
            cursor: pointer;
          }
        }

        .book-details__copy-wrapper {
          margin-top: 2rem;
          display: flex;

          @media @sm {
            display: none;
          }

          &:hover {
            cursor: pointer;
          }

          .book-details__copy-icon {
            margin-right: 1rem;
            width: 2.5rem;
            height: 2.5rem;
          }

          .book-details__copy-text {
            font-weight: 600;
          }
        }
      }
    }
  }

  .shop-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: @ds-black;
    padding: 7rem 0;

    @media @sm {
      padding: 10rem 0;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: flex-start;
    }

    .shop-form__header {
      .shop-header-style(2.8rem);
      text-align: center;

      &--desktop {
        .shop-header-style(3.5rem);
        display: none;
      }

      @media @sm {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: block;
        }
      }
    }

    .shop-form__image-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media @sm {
        width: 25rem;
        margin-top: 8rem;
      }

      .shop-form__item-title {
        font-weight: 700;
        margin: 2rem 0;

        @media @sm {
          margin: 0 0 2rem 0;
        }
      }

      .shop-form__item-image {
        max-width: 35%;

        @media @sm {
          max-width: 100%;
        }
      }
    }

    .shop-form__form {
      margin: 0 2rem;

      @media @sm {
        margin: 0 15rem 4rem 0;
        width: 45rem;
      }

      .shop-form__address-line-2 {
        display: flex;
        width: 100%;

        & > div {
          margin: 0;
        }

        .shop-form__input-group--postal {
          width: 9rem;
          margin-right: 2rem;
        }
      }
    }

    .shop-form__input-group {
      margin: 2rem 0;
      width: 100%;

      .shop-form__form-label {
        display: block;
        font-size: 1.4rem;
        font-weight: 600;
      }

      .shop-form__form-input {
        display: block;
        width: 100%;
        background-color: @ds-white;
        border-radius: 0.5rem;
        border: solid @color-gray-300 0.1rem;
        color: @ds-black;
        padding: 1rem;
      }

      .shop-form__form-input-error {
        color: @component-shop-input-error;
        font-style: italic;
        font-size: 1.2rem;
        margin-top: 0.5rem;
        display: none;
      }

      .shop-form__newsletter,
      .shop-form__competition {
        font-size: 1.4rem;

        .shop-form__optin-wrapper {
          display: flex;
          align-items: center;
          margin: 2rem 0;

          &:hover {
            cursor: pointer;
          }

          .shop-form__checkbox-wrapper {
            position: relative;
            display: flex;
            align-items: center;

            .shop-form__checkbox {
              appearance: none;
              width: 2rem;
              height: 2rem;
              background-color: @ds-white;
              border-radius: 0.5rem;
              border: solid @color-gray-300 0.1rem;
              margin-right: 2rem;
            }

            .shop-form__checkmark {
              position: absolute;
              display: none;
              top: 0.4rem;
              left: 0.8rem;
              height: 1rem;
              width: 0.5rem;
              transform: rotate(45deg);
              border-bottom: 0.2rem solid @ds-black;
              border-right: 0.2rem solid @ds-black;
              pointer-events: none;

              &--show {
                display: block;
              }
            }
          }
        }
      }

      .shop-form__competition {
        .shop-form__optin-wrapper {
          margin-bottom: 0;
        }
      }

      &--invalid {
        .shop-form__form-input-error {
          display: block;
        }
      }
    }
  }

  .shop-form__age-confirmation {
    margin: 3rem 0;

    .shop-form__input-group {
      max-width: 70%;

      .shop-form__form-input {
        &::placeholder {
          font-style: italic;
        }
      }
    }

    .shop-form__age-text {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .shop-form__submit {
    .shop-button-style();
    text-align: center;
    width: 100%;
  }
}

.shop-confirmation {
  color: @ds-black;

  .shop-confirmation__content {
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @sm {
      max-width: 50rem;
      padding: 10rem 0;
      margin: 0 auto;
    }

    .shop-confirmation__heading {
      .shop-header-style(4rem);

      @media @sm {
        .shop-header-style(6rem);
      }
    }

    .shop-confirmation__text {
      text-align: center;
      margin-bottom: 5rem;
      margin-top: 5rem;
    }

    .shop-confirmation__cta {
      .shop-button-style();
    }

    .shop-confirmation__footer {
      margin-top: 7rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .shop-confirmation__footer-text {
        margin-bottom: 1rem;
      }

      .shop-confirmation__footer-logo {
        height: 2.5rem;
      }
    }
  }
}

.shop-overview__youtube-video {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @md {
    width: 90%;
  }

  @media @lg {
    width: 102.4rem;
  }

  .shop-overview__youtube-video-header {
    .shop-header-style(3rem);
    text-align: center;

    @media @sm {
      .shop-header-style();
    }
  }

  .shop-overview__youtube-video-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    margin: 3rem 0 10rem 0;

    .shop-overview__youtube-video-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.shop-button-style() {
  color: @ds-black;
  font-weight: 600;
  padding: 1rem 3rem;
  border-radius: 3.5rem;
  position: relative;
  z-index: 1;
  // Border color:
  background: @ds-yellow;

  &:hover {
    cursor: pointer;
  }
}

.shop-spot-light() {
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 200vw;
    height: 200vw;
    top: 11rem;
    border-radius: 11rem;
    background: @component-shop-spotlight-mobile;
    filter: blur(2rem);
    left: -50%;
    transform: rotate(45deg);
    z-index: -2;
    opacity: 0.25;

    @media @sm {
      left: 0;
      width: 100vw;
      height: 100vw;
      top: 16rem;
      background: @component-shop-spotlight-desktop;
    }
  }
}

.shop-header-style(@fontSize: 4rem, @fontWeight: 800) {
  text-transform: uppercase;
  font-weight: @fontWeight;
  font-size: @fontSize;
  color: @ds-black;
  position: relative;
  display: inline-block;
}
