﻿@import (reference) "./../_imports.less";

.rules-and-conditions {
  text-align: center;
  font-style: italic;
  padding: 0.5rem 0.7rem;
  line-height: 1.4;
  color: white;
  font-size: 0.9rem;
  background: rgba(0, 0, 0, 0.2);
  margin: 0.5rem auto;
}
