/* ===========================================
   utility.spacing
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

@import (reference) "./../_imports.less";

.avalon-area {
  // Class naming anatomy:
  // (utility)-(property)-(location)--(size)

  //
  // Margin utility classes
  //
  .u-margin--none {
    margin: 0 !important;
  }


  // Margin bottom
  .u-margin-bottom--none {
    margin-bottom: 0 !important;
  }

  .u-margin-bottom--xs {
    margin-bottom: @spacing-unit--xs !important;
  }

  .u-margin-bottom--sm {
    margin-bottom: @spacing-unit--sm !important;
  }

  .u-margin-bottom {
    margin-bottom: @spacing-unit !important;
  }

  .u-margin-bottom--lg {
    margin-bottom: @spacing-unit--lg !important;
  }

  .u-margin-bottom--xl {
    margin-bottom: @spacing-unit--xl !important;
  }


  // Margin top
  .u-margin-top--xs {
    margin-top: @spacing-unit--xs !important;
  }

  .u-margin-top--sm {
    margin-top: @spacing-unit--sm !important;
  }

  .u-margin-top {
    margin-top: @spacing-unit !important;
  }

  .u-margin-top--lg {
    margin-top: @spacing-unit--lg !important;
  }

  .u-margin-top--xl {
    margin-top: @spacing-unit--xl !important;
  }


  //
  // Padding utility classes
  //
  .u-padding--none {
    padding: 0 !important;
  }


  // Padding bottom
  .u-padding-bottom--none {
    padding-bottom: 0 !important;
  }

  .u-padding-bottom--xs {
    padding-bottom: @spacing-unit--xs !important;
  }

  .u-padding-bottom--sm {
    padding-bottom: @spacing-unit--sm !important;
  }

  .u-padding-bottom {
    padding-bottom: @spacing-unit !important;
  }

  .u-padding-bottom--lg {
    padding-bottom: @spacing-unit--lg !important;
  }

  .u-padding-bottom--xl {
    padding-bottom: @spacing-unit--xl !important;
  }


  // Padding top
  .u-padding-top--none {
    padding-top: 0 !important;
  }

  .u-padding-top--xs {
    padding-top: @spacing-unit--xs !important;
  }

  .u-padding-top--sm {
    padding-top: @spacing-unit--sm !important;
  }

  .u-padding-top {
    padding-top: @spacing-unit !important;
  }

  .u-padding-top--lg {
    padding-top: @spacing-unit--lg !important;
  }

  .u-padding-top--xl {
    padding-top: @spacing-unit--xl !important;
  }


  // Padding vertical
  .u-padding-ver--none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .u-padding-ver--xs {
    padding-top: @spacing-unit--xs !important;
    padding-bottom: @spacing-unit--xs !important;
  }

  .u-padding-ver--sm {
    padding-top: @spacing-unit--sm !important;
    padding-bottom: @spacing-unit--sm !important;
  }

  .u-padding-ver {
    padding-top: @spacing-unit !important;
    padding-bottom: @spacing-unit !important;
  }

  .u-padding-ver--lg {
    padding-top: @spacing-unit--lg !important;
    padding-bottom: @spacing-unit--lg !important;
  }

  .u-padding-ver--xl {
    padding-top: @spacing-unit--xl !important;
    padding-bottom: @spacing-unit--xl !important;
  }


  // Padding horizontal
  .u-padding-hor--none {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
