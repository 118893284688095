@import (reference) "../../../Domain/AvalonComponents/Styles/_imports";

.page-not-found {
  font-family: @font-primary;
  line-height: 1.4;
  color: @ds-green-600;
  max-width: 99rem;
  text-align: center;
  margin: 4rem auto 2rem;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-areas: ". dice ." ". title ." ". text ." ". buttons. " ". games .";

  @media @sm {
    text-align: left;
    grid-template-columns: auto minmax(auto, 40rem) auto 23rem auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: ". title . dice ." ". text . dice ." ". buttons . dice ." ". games games games .";
  }

  @media @lg {
    margin-top: 5rem;
    grid-template-columns: minmax(auto, 57rem) auto 34rem;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas: "title . dice" "text . dice" "buttons . dice" "games games games";
  }
}

.page-not-found__dice {
  grid-area: dice;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media @sm {
    justify-content: flex-end;
    margin-bottom: 4rem;
  }

  @media @lg {
    margin-bottom: 10rem;
  }

  .ds-cube-loader-wrapper {
    position: relative;

    .ds-cube-wrapper {
      position: relative;
      top: 0;
      transform: scale(0.5);
      perspective-origin: 50% 90%;

      @media @sm {
        transform: scale(0.55);
      }

      @media @lg {
        transform: scale(0.65);
      }

      .ds-cube {
        margin: 2rem auto -10rem;

        @media @sm {
          margin: 5rem auto -10rem -3rem;
        }

        @media @lg {
          margin: 10rem -2rem -10rem auto;
        }
      }
    }

    .ds-cube-loader-text {
      display: none;
    }
  }
}

.page-not-found__title {
  grid-area: title;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;

  @media @sm {
    margin: 3rem 0 1rem;
  }

  @media @lg {
    font-size: 4rem;
  }
}

.page-not-found__text {
  grid-area: text;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  line-height: 1.3;
  margin-bottom: 1rem;

  @media @sm {
    margin-bottom: 2rem;
  }

  @media @lg {
    font-size: 2rem;
  }
}

.page-not-found__buttons {
  grid-area: buttons;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;

  @media @sm {
    justify-content: flex-start;
    margin-bottom: 4rem;
  }

  @media @lg {
    margin-bottom: 10rem;
  }
}

.page-not-found__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 0 2rem;
  min-width: 13rem;
  height: 4rem;
  background: @ds-yellow;
  color: @ds-black;
  font-size: 1.2rem;
  font-weight: 700;
  box-shadow: 0 0.3rem 1rem 0.2rem fade(@ds-black, 15%), 0 0.1rem 0.2rem 0.1rem fade(@ds-black, 10%);
  transition: 100ms;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    box-shadow: 0 0.1rem 0.2rem 0 fade(@ds-black, 20%);
  }

  & + & {
    margin-left: 2rem;
  }

  @media @sm {
    font-size: 1.4rem;
  }

  @media @lg {
    font-size: 1.6rem;
  }
}

.page-not-found__games {
  grid-area: games;

  @media @lg {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.page-not-found__games-dli,
.page-not-found__games-dlo,
.page-not-found__games-dli-dlo {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 55rem;
  margin-bottom: 2rem;

  @media @lg {
    flex: 1 1 calc(~'50% - 0.5rem');
    max-width: 60rem;
  }
}

.page-not-found__games-dli {
  @media @lg {
    margin-right: 1rem;
  }
}

.page-not-found__games-dli-dlo {
  @media @lg {
    flex: 0 0 100%;
  }
}

.page-not-found--dli {
  .page-not-found__games-dlo,
  .page-not-found__games-dli-dlo {
    display: none;
  }
}

.page-not-found--dlo {
  .page-not-found__games-dli,
  .page-not-found__games-dli-dlo {
    display: none;
  }
}

.page-not-found__game {
  position: relative;
  min-height: 4rem;
  flex: 1 1 9rem;
  height: 4rem;
  transition: 100ms;

  &:hover {
    transform: scale(1.05);
  }

  @media @lg {
    height: 4.5rem;
  }

  // We add "invisible" elements to make flex items in the last row have the same size. 
  &--placeholder {
    min-height: 0;
    max-height: 0;
    order: 100;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    bottom: 0.2rem;
    right: 0.2rem;
    background-color: fade(@ds-black, 5%);
    border-radius: 0.5rem;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
  }

  // DLI

  &--oddset {
    order: 1;

    &::before {
      background-color: @ds-dark-blue;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#oddset_use);
    }
  }

  &--casino {
    order: 2;

    &::before {
      background-color: @ds-gold;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#casino_use);
    }
  }

  &--spillehjoernet {
    order: 3;

    @media @lg {
      order: 8;
    }

    &::before {
      background-color: @ds-blue-gray;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#spillehjoernet_use);
    }
  }

  &--eoddset {
    order: 4;

    @media @lg {
      order: 6;
    }

    &::before {
      background-color: @ds-dark-blue;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#eoddset_use);
    }
  }

  &--livecasino {
    order: 5;

    @media @lg {
      order: 7;
    }

    &::before {
      background-color: @ds-gold;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#livecasino_use);
    }
  }

  &--poker {
    order: 6;

    @media @lg {
      order: 9;
    }

    &::before {
      background-color: @ds-black;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#poker_use);
    }
  }

  &--tips {
    order: 7;

    @media @lg {
      order: 4;
    }

    &::before {
      background-color: @ds-lightgreen;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#tips_use);
    }
  }

  &--bingo {
    order: 8;

    @media @lg {
      order: 3;
    }

    &::before {
      background-color: @ds-lime;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#bingo_use);
    }
  }

  &--dantoto {
    order: 9;

    @media @lg {
      order: 5;
    }

    &::before {
      background-color: @ds-orange;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#dantoto_use);
    }
  }

  // DLO

  &--lotto {
    order: 1;

    &::before {
      background-color: @ds-red;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#lotto_use);
    }
  }

  &--vikinglotto {
    order: 2;

    @media @lg {
      order: 3;
    }

    &::before {
      background-color: @ds-blue-400;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#vikinglotto_use);
    }
  }

  &--eurojackpot {
    order: 3;

    @media @lg {
      order: 2;
    }

    &::before {
      background-color: @ds-honey;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#eurojackpot_use);
    }
  }

  &--keno {
    order: 4;

    @media @lg {
      order: 7;
    }

    &::before {
      background-color: @ds-light-blue;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#keno_use);
    }
  }

  &--allornothing {
    order: 5;

    @media @lg {
      order: 6;
    }

    &::before {
      background-color: @ds-dustypurple-900;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#allornothing_use);
    }
  }

  &--quick {
    order: 6;

    @media @lg {
      order: 8;
    }

    &::before {
      background-color: @ds-purple;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#quick_use);
    }
  }

  &--plus {
    order: 7;

    @media @lg {
      order: 4;
    }

    &::before {
      background-color: @ds-green;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#plus_use);
    }
  }

  &--spilsammen {
    order: 8;

    @media @lg {
      order: 5;
    }

    &::before {
      background-color: @ds-green;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#spilsammen_use);
    }
  }

  &--virtuel {
    order: 9;

    &::before {
      background-color: @color-lightblue-200;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#virtuel_use);
    }
  }

  // DLI/DLO

  &--sikkertspil {
    order: 1;

    &::before {
      background-color: #4b997f;
      background-image: url(/Components/DanskeSpil/Domain/ErrorHandling/Graphics/SpriteSheets/DsLogos.svg#sikkertspil_use);
    }
  }
}

.page-not-found__games-header {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.4rem;

  @media @lg {
    font-size: 1.3rem;
  }

  .page-not-found__games-dli & {
    color: @color-brandmenu-dli;
  }

  .page-not-found__games-dlo & {
    color: @color-brandmenu-dlo;
  }
}
