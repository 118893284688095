/* ===========================================
   utility.hero-banners
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.region-avalon-frontpage .poker-game-gallery {
  background: #ffffff;
  padding-top: 4rem;
}
@media screen and (max-width: 480px) {
  .region-avalon-frontpage .poker-game-gallery {
    padding-top: 7vw;
  }
  .region-avalon-frontpage .poker-game-gallery:first-of-type {
    margin-bottom: -2vw;
  }
}
@media screen and (max-width: 768px) {
  .region-avalon-frontpage .poker-game-gallery__wrapper {
    padding: 2vw;
  }
}
.region-avalon-frontpage .poker-game-gallery__item {
  flex-basis: 22% !important;
  flex: 0 0 22% !important;
  margin: 2% 1.5% !important;
  position: relative;
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .region-avalon-frontpage .poker-game-gallery__item {
    flex: 0 0 31.3% !important;
    margin: 2% 1% !important;
  }
}
.region-avalon-frontpage .poker-game-gallery__item-cta {
  display: none;
}
.region-avalon-frontpage .poker-game-gallery__item-text {
  position: absolute;
  top: -4rem;
  left: 0;
  font-style: italic;
  width: 300%;
  color: #000000;
  text-align: left;
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .region-avalon-frontpage .poker-game-gallery__item-text {
    top: -7.5vw;
    font-size: 3vw;
  }
}
@media screen and (max-width: 480px) {
  .region-avalon-frontpage .poker-game-gallery__item-text {
    top: -9vw;
  }
}
