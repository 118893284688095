@import "../_imports";

.seo-text-spot {
  padding: 3rem 0;
  background: @ds-color-primary;
  color: @color-white;
}

.seo-text-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 130rem;
  margin: 0 auto;
  padding: 3rem;
}

.seo-text-spot__text {
  @media @md {
    columns: 2;
    column-gap: 5rem;
  }
}