﻿@import (reference) "./../_imports.less";

.avalon-cards {
  .card.card-campaign {
    overflow-y: visible;
  }

  .card-campaign {
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    color: @ds-white;

    &:hover {
      text-decoration: none;
    }

    &:hover .card-campaign__cta {
      background: @ds-yellow-600;
    }
  }

  .card-campaign__curve {
    overflow: hidden;
    position: absolute;
    z-index: 2;
    top: 0;
    height: 28rem;
    left: 0;
    right: 0;

    .card-campaign__curve-inner {
      position: absolute;
      z-index: 3;
      bottom: -37rem;
      left: -30rem;
      right: -30rem;
      height: 40rem;
      border-top-left-radius: 250rem 100rem;
      border-top-right-radius: 250rem 100rem;
    }
  }

  .card-campaign__behind {
    position: absolute;
    z-index: -1;
    top: 0;
    background-color: @ds-neongreen-800;
    background-size: cover;
    background-position: center;
    left: 0;
    width: 100%;
    height: 28rem;
    border-radius: 0.6rem 0.6rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }

  .card-campaign__image {
    max-width: ~"calc(100% - 4rem)";
    max-height: ~"calc(100% - 4rem)";
    height: auto;
  }

  .card-campaign__front {
    background-color: @ds-neongreen-800;
    height: 100%;
    margin: 28rem 0 2.5rem;
    border-radius: 0 0 0.6rem 0.6rem;
    padding: 0 2.5rem 4.5rem;
  }

  .card-campaign__title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  .card-campaign__text {
    font-size: 1.2rem;
    line-height: 1.3;
  }

  .card-campaign__cta {
    position: absolute;
    z-index: 10;
    bottom: 0.3rem;
    left: 50%;
    transform: translateX(-50%);
    background: @ds-yellow;
    height: 5rem;
    min-width: 16.5rem;
    border-radius: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @ds-gray-900;
    font-size: 1.6rem;
    font-weight: 800;
    white-space: nowrap;
    box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    padding: 0 2.5rem;
  }
}
