@import (reference) '../Colors/Base';
@import (reference) "../Variables/breakpoints";

.app-universe {
  .app-description {
    color: @app-universe-color-text-on-dark;

    p {
      font-size: 1.3rem;
      margin: 0 0 0.5em;
      line-height: 1.4;
      font-weight: 400;
      text-transform: none;

      &:not([class]):last-child {
        margin: 0 0 1em;
      }

      @media (min-width: @medium) {
        font-size: 1.5rem;
      }
    }

    h1 {
      font-size: 2rem;
      line-height: 1.3;
      font-weight: 600;
      text-transform: none;
      margin: 0 0 0.2em;

      @media (min-width: @medium) {
        font-size: 2.8rem;
      }
    }

    h1 ~ p {
      font-size: 1.4rem;

      @media (min-width: @medium) {
        font-size: 1.6rem;
      }
    }

    h2 {
      font-size: 1.6rem;
      line-height: 1.4;
      font-weight: 600;
      text-transform: none;
      margin: 0 0 0.2em;

      @media (min-width: @medium) {
        font-size: 1.8rem;
      }
    }
  }
}
