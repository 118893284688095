﻿/* ===========================================
   component.full-width-cta
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-full-width-cta {
  width: 100%;
  margin: 0 auto;
  background: @color-gray-600;
  padding: 0;

  @media @sm {
    padding: 0 @container-padding;
  }

  &__buttons {
    @media @sm {
      padding: 0 3rem;
    }
  }

  &__count--1 {
    .avalon-full-width-cta__text {
      @media @sm {
        justify-content: center;
        text-align: center;
      }
    }
  }

  &__count--2 {
    .avalon-full-width-cta__buttons {
      display: flex;

      @media screen and (max-width: @medium) {
        flex-direction: column;
      }
    }
  }
  
  // Singular button styles
  &__btn {
    display: flex;
    width: 100%;
    min-height: 10rem;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 0;

    @media screen and (max-width: @medium) {
      min-height: 7.5rem;
    }

    .avalon-full-width-cta__count--1 & {
      height: 100%;
    }

    .avalon-full-width-cta__count--2 & {
      &:first-child {
        @media screen and (max-width: @medium) {
          border-bottom: 1px solid @color-white-100;
        }
      }

      &:last-child {
        border-left: 1px solid @color-white-100;

        @media screen and (max-width: @medium) {
          border-left: none;
        }
      }
    }
  }
  
  // Button text
  &__text {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 2.5rem 4.5rem 2.5rem 3rem;

    font-size: @font-size;
    text-align: left;

    @media @sm {
      flex-direction: row;
      align-items: center;
    }
  }

  &__header {
    font-weight: 900;
    margin-right: 1rem;
  }

  &__text-body {
    font-weight: 400;
    margin-top: @spacing-unit--xs;

    @media @sm {
      margin-top: 0;
    }
  }
  
  // Button icon
  &__icon {
    fill: @button-text-color;
    position: absolute;
    top: 50%;
    right: 1rem;

    transform: translateY(-50%);
  }
}
