﻿/* ===========================================
   component.campaign
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-campaign {
  background-color: @component-campaign-spot-bg;

  .u-background--white & {
    background-color: @color-white;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: -2rem; //to lessen padding-top inherited from .u-grid
  }

  &__item {
    overflow: hidden;
    background-color: @component-campaign-spot-item-bg;
    color: rgba(255, 255, 255, 0.8);
    margin-top: @item-margin;

    &--size-50 {
      @media @sm {
        width: calc(~'100%/2 - @{item-margin} / 2');
      }
    }

    &--size-100 {
      @media @sm {
        width: 100%;
        display: block;
      }
    }
  }

  &__header {
    position: relative;
    display: block;

    &[href] {
      .avalon-campaign__title,
      .avalon-campaign__subtitle {
        transition: color ease .25s;
      }

      &:hover {
        .avalon-campaign__title,
        .avalon-campaign__subtitle {
          color: @component-campaign-spot-header-hover;
        }
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 75vw;
    object-fit: cover;

    @media @sm {
      height: auto;
    }
  }

  &__imagetext {
    padding: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: @component-campaign-spot-text-bg;
    color: @color-white;

    @media @sm {
      padding: 2.5rem;
    }
  }

  &__subtitle {
    font-weight: 100;
  }

  &__content {
    font-size: @font-size--xs;
    padding: 2rem;

    @media @sm {
      padding: 2.5rem;
    }

    a {
      color: @color-primary;
    }
  }

  .avalon-area & ol:not([class]),
  .avalon-area & ul:not([class]) {
    padding-left: 2rem;

    li {
      &:before {
        left: -3rem;

        font-size: 1.4rem;
        line-height: 1.4;
        color: @color-gray-300;
      }
    }
  }

  .avalon-area & ol:not([class]) {
    li {
      &:before {
        top: -0.1rem;
      }
    }
  }
  
  //// Experience Editor behavior ////
  .mode-edit & {
    &__item {
      // disable equal height in edit-mode
      height: auto !important;
    }
  }
}
