/* ===========================================
   component.hero
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

@avalonHeroHeight: 34rem;

.avalon-clock {
  display: flex;
  flex-direction: column;
  background-position: top center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;
}

.avalon-clock--link {
  cursor: pointer;
}

.avalon-clock__background-link {
  text-decoration: none;
}

.avalon-clock__background {
  img {
    max-width: 200rem;
    width: 100%;
    height: 45rem;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    // Fallback for IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }
}

.avalon-clock__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 45rem;
  min-height: 45rem;
  width: 100%;
  position: relative;
  
  .flip-clock {
    display: flex;
    padding: 1rem 0 2rem;
    justify-content: center;

    @media @sm {
      justify-content: flex-start;
    }
  }
  
  .avalon-clock__content-left,
  .avalon-clock__content-right {
    flex: 1;
  }
  
  .avalon-clock__content-right {
    display: none;

    @media @sm {
      display: flex;
      justify-content: center;
      padding-left: 2rem;
    }
  }

  .mode-edit & {
    height: auto;
  }

  .avalon-clock__primary-image {
    display: flex;
    
    @media @sm {
      height: 30rem;
    }
  }

  .avalon-clock__heading {
    margin: 1rem 0;
    width: 100%;
    max-width: @medium;
    overflow-wrap: break-word;
    hyphens: auto;
    color: @color-white-100;

    &.black {
      color: @color-black;
    }

    &.is-impact {
      font-family: @font-impact;
      font-style: italic;
    }
  }

  .avalon-clock__text {
    color: white;
    text-align: center;
    width: 100%;

    &.black {
      color: @color-black;
    }

    @media @sm {
      text-align: left;
    }
  }
}

.avalon-clock__cta-wrapper {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-top: 3rem;

  @media @sm {
    flex-direction: row;
  }
}

.avalon-clock__cta {
  margin-left: 1.5rem;
  
  &:first-child {
    margin-left: 0;
  }

  @media (max-width: @medium) {
    
    &:first-child {
      margin-top: 0;
    }
  }
}

.avalon-clock__duty-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;

  @media @sm {
    text-align: left;
  }
}
