/* ===========================================
   component.video
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-video {
  background-color: @color-white-100;

  &__text {

    @media screen and (max-width: @medium) {
      font-size: @font-size--sm;
    }
  }

  &__video-inner {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 2.5rem;
    height: 0;

    @media @sm {
      padding-bottom: 54.7%;
    }
  }

  // Internal video
  // mejs is the prefix set by the mediaelementplayer.js library
  // We use it to unify html video look across browsers
  .mejs-container {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
  }

  .mejs-overlay {
    position: static;
  }

  // We override this style from the library so poster/thumbnail image will always fill the entire video container
  .mejs-poster {
    background-size: cover;
  }

  // Vemo & youtube
  iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
  }
}
