﻿@import (reference) "./../_imports.less";

.avalon-cards {

  .card.card-picture {
    border-radius: 0;
    padding: 1rem;
    margin: 0;
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: @ds-black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: @ds-white;
    text-align: center;
    text-decoration: none;
    min-height: 25rem;
  }

  .card-picture__icon {
    fill: @ds-white;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

}