﻿//modifiers.colors.backgroundcolors


@import "../../Variables/colors.less";

//General Colors
.component-backgroundcolor--white {
  background-color: white;
}

.component-backgroundcolor--black {
  background-color: black;
}

.component-backgroundcolor--current-brand {
  background-color: @ds-color-primary;
}

// DS Colors
//DLI
.component-backgroundcolor--dli {
  background-color: @color-brandmenu-dli;
}

.component-backgroundcolor--poker {
  background-color: @color-brand-poker; // black
}

.component-backgroundcolor--spillehjoernet {
  background-color: @color-brand-spillehjoernet;
}

.component-backgroundcolor--casino {
  background-color: @color-brand-casino;
}

.component-backgroundcolor--dantoto {
  background-color: @color-brand-dantoto;
}

.component-backgroundcolor--oddset {
  background-color: @color-brand-oddset;
}

.component-backgroundcolor--tips {
  background-color: @color-brand-tips;
}

.component-backgroundcolor--bingo {
  background-color: @color-brand-bingo;
}

//DLO
.component-backgroundcolor--dlo {
  background-color: @color-brandmenu-dlo;
}

.component-backgroundcolor--lotto {
  background-color: @color-brand-lotto;
}

.component-backgroundcolor--eurojackpot {
  background-color: @color-brand-eurojackpot;
}

.component-backgroundcolor--vikinglotto {
  background-color: @color-brand-vikinglotto;
}

.component-backgroundcolor--keno {
  background-color: @color-brand-keno;
}

.component-backgroundcolor--all-or-nothing {
  background-color: @color-brand-all-or-nothing;
}

.component-backgroundcolor--quick {
  background-color: @color-brand-quick;
}

.component-backgroundcolor--quick-with-border-radius {
  background-color: @color-brand-quick;
  border-radius: 4rem;
  position: relative;
  margin-top: -6rem;
}

.component-backgroundcolor--plus {
  background-color: @color-brand-plus;
}
