/* ===========================================
   component.campaign-module
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-campaign-module {
  position: relative;

  &__link-area {
    display: block;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__cta {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
