/* ===========================================
   elements.headings
   ========================================= */

// Elements – unclassed HTML elements, redefine browsers' default styling
@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond PoCaGa
.avalon-area {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 2rem;
    margin-top: 0;
    text-transform: uppercase;
    overflow-wrap: break-word;

    .region-avalon-bingo & {
      text-transform: none;
    }
  }

  h1 {
    font-size: 3.4rem;
    line-height: 1;
    font-weight: 800;

    @media @sm {
      font-size: 5rem;

      .region-avalon-bingo & {
        font-size: 4.6rem;
        letter-spacing: -0.01em;
      }
    }
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 800;

    @media @sm {
      font-size: 3.2rem;
    }
  }

  h3 {
    font-size: 2.2rem;
    line-height: 1.2;
    font-weight: 600;

    @media @sm {
      font-size: 2.4rem;
    }
  }

  h4 {
    font-size: 1.6rem;
    line-height: 1.3;

    @media @sm {
      font-size: 1.8rem;
    }
  }

  h5 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}
