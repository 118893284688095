/* ===========================================
   elements.lists
   ========================================= */

// Elements – unclassed HTML elements, redefine browsers' default styling
@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond PoCaGa
.avalon-area {

  ul:not([class]),
  ol:not([class]) {
    margin-left: 0;
    padding: 0 0 0 4rem;

    li {
      list-style-type: none;
      margin-bottom: @spacing-unit--sm;
      position: relative;

      &:before {
        width: 2rem;

        position: absolute;
        left: -4rem;

        font-size: @font-size--xl;
        color: @list-style-color;
        text-align: right;
        font-weight: 900;
        direction: rtl;
      }
    }
  }
  
  // Ordered List with large numbers
  ol:not([class]) {
    counter-reset: list;

    >li {
      counter-increment: list;

      &:before {
        content: '.' counter(list);
        left: -3.5rem;
        top: -.85rem;
      }
    }
  }
  
  // Unordered List with large dots
  ul:not([class]) {
    li {
      &:before {
        content: @element-list-item-content;
        top: 0;
        line-height: 0.95;
        font-family: serif;
      }
    }
  }
}
