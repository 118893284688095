﻿@import url("../variables.less");

body.region-corporate,
body.region-avalon-corporate {
  background: @color-green-200;
}

.region-avalon-corporate {
  //We have 2 mobil login links until we go live with PAM
  //Refactor when PAM is live
  @media (max-width: (@nav-medium)) {
    .accountmenu {
      overflow: hidden; //hide scrollbars when there is no links present
    }
  }

  .login-btn.mobile-only.avalon-login-link {
    display: none !important;
  }

  @media (min-width: @nav-medium) {
    .brand-nav__logo-img {
      display: none;
    }
  }
}

.top-navigation-theme-corporate {

  @theme-color-a: #005f3f;
  @theme-color-b: #00402b;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .accountmenu {
    .login-btn,
    .signup-btn {
      display: none;
    }
  }

  .brand-nav {
    background-color: @theme-color-b;

    .login-btn.mobile-only {
      display: none;
    }

    .brand-nav__logo-cube,
    .mobile-navigation-header__logo-cube {
      display: none;
    }
  }

  .mobile-navigation-header {
    background-color: @theme-color-b;
  }

  .corporate-nav {
    background-color: @theme-color-a;
    //Mobile related
    .mobile-navigation-header,
    .mobile-navigation-footer__icon {
      background-color: @theme-color-a;
    }
  }

  .brand-nav__navigation,
  .corporate-nav__navigation {
    &-header {
      background-color: @theme-color-b;
    }

    &-footer {
      &-icon {
        background-color: @theme-color-b;
      }
    }

    &-item {
      &-link {
        &:before {
          background-color: @theme-color-b;
        }
      }
    }

    .corporate-nav__list-link {

      &:hover {
        color: #fff;
      }
    }
  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;

        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}

.region-corporate {
  .custom-static-fix-height {
    height: 62px + 0px;
  }
}


