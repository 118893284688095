@import (reference) "../Colors/Base";
@import (reference) "../Variables/breakpoints";
@import (reference) "../Variables/Variables";

.app-universe {
  .app-list {
    margin-bottom: 4rem;

    .app-list__hero {
      position: relative;
      height: 25rem;
      overflow: hidden;
      z-index: -1;
      padding-bottom: 5rem;
      margin-bottom: -5rem;
      top: 0;
      width: calc(~"100% + 30rem");
      left: -15rem;
      border-bottom-left-radius: 50% 25%;
      border-bottom-right-radius: 50% 25%;

      > .app-list__hero-content {
        position: relative;
        margin: 0 15rem;
        height: inherit;
      }
    }

    .app-list__hero-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .app-list__title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 3.5rem 0 0;
      text-transform: uppercase;
      color: @app-universe-color-text-on-dark;
      font-size: 2.1rem;
      line-height: 1.1;
      font-weight: 900;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

      @media (min-width: @medium) {
        font-size: 2.8rem;
      }

      @media (min-width: @large) {
        font-size: 3.6rem;
      }
    }

    .app-list__items-wrapper-title {
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 1.4;
      font-weight: bold;
      color: @app-universe-color-text-on-dark;
    }

    .app-list__items {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      margin: 0 -0.5rem;

      @media (min-width: @medium) {
        margin: 0 -1rem;
      }
    }

    .app-list__item-wrapper {
      flex: 0 0 100%;
      overflow: hidden;
      padding: 0.5rem;

      @media (min-width: @medium) {
        padding: 1rem;
        flex: 0 0 50%;
      }
    }

    .app-list__item {
      position: relative;
      min-height: 10.5rem;
      border-radius: 0.7rem;
      background: @ds-white;
      padding: 1.2rem 2rem 1.2rem 1.2rem;
      display: flex;
      flex-direction: row;
      height: 100%;

      &:hover {
        .app-list__item-icon {
          transform: scale(1.05) rotate(-0.01turn);
          margin-top: -0.1rem;
        }
      }
    }

    .app-list__item-icon {
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
      width: 8rem;
      height: 8rem;
      border-radius: 22.5%;
      background-size: cover;
      transition: 100ms transform ease-out;
      margin-top: 0.5rem;

      @media (min-width: @large) {
        width: 10rem;
        height: 10rem;
      }
    }

    .app-list__item-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 2rem;
    }

    .app-list__item-title {
      margin-top: 0.5rem;
      font-size: 1.6rem;
      line-height: 1.4;
      font-weight: bold;
      color: @app-universe-color-green;

      @media (min-width: @large) {
        font-size: 1.7rem;
      }
    }

    .app-list__item-description {
      font-size: 1.2rem;
      line-height: 1.4;
      color: @app-universe-color-grey;

      @media (min-width: @large) {
        font-size: 1.3rem;
      }
    }

    .app-list__item-link {
      text-transform: uppercase;
      color: @ds-black-primary;
      font-size: 1.3rem;
      font-weight: bold;
      margin: auto 0 1rem 0;
      padding-top: 1rem;

      @media (min-width: @large) {
        font-size: 1.5rem;
      }
    }

    .app-list__item-platforms {
      position: absolute;
      color: @app-universe-color-dark-grey;
      font-style: italic;
      font-size: 1rem;
      right: 1.2rem;
      bottom: 0.2rem;
    }
  }
}

