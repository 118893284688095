/* ===========================================
   utility.grid
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything

@import (reference) "./../_imports.less";

@spacing: @container-padding;

@grid-gutter-width: 3rem;

.avalon-area {
  .u-grid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: @spacing;
  }


  // Size modifiers

  .u-grid--large {
    max-width: ~'calc(1280px + ('@spacing ~'* 2))';
  }

  .u-grid--medium {
    max-width: ~'calc(960px + ('@spacing ~'* 2))';
  }

  .u-grid--small {
    max-width: ~'calc(560px + ('@spacing ~'* 2))';
  }
}

.u-css-grid {
  display: flex;
  align-items: top;
  justify-content: space-between;

  // All newer browsers (than IE11)
  @supports (display: grid) {
    display: grid;
    grid-column-gap: @grid-gutter-width;
    grid-row-gap: @grid-gutter-width;
  }
}

.u-css-grid--two-columns {
  @media @sm {
    grid-template-columns: calc(~"35% - (@{grid-gutter-width} / 2)") calc(~"65% - (@{grid-gutter-width} / 2)");
    grid-row-gap: 0;
    // IE11 fallback
    & > .column {
      width: calc(~"50% - (@{grid-gutter-width} / 2)");
    }

    @supports (display: grid) {
      & > .column {
        width: auto;
      }
    }
  }
}
