﻿@import (reference) "./../_imports.less";

// Defaults to spillehjoernet.
@pagination__number__color: @ds-white;
@pagination__number--active__backgroundColor: #D9569E;
@pagination__number--active__color: @ds-white;

:root {
  --pagination__number__color: @pagination__number__color;
  --pagination__number--active__backgroundColor: @pagination__number--active__backgroundColor;
  --pagination__number--active__color: @pagination__number--active__color;

  .region-casino {
    --pagination__number__color: @ds-gray-925;
    --pagination__number--active__backgroundColor: @color-brown-chocolate;
    --pagination__number--active__color: @ds-white;
  }

  .region-avalon-bingo,
  .region-avalon-bingo-gvc,
  .region-avalon-bingo-slingo {
    --pagination__number__color: @ds-gray-825;
    --pagination__number--active__backgroundColor: @ds-yellow;
    --pagination__number--active__color: @ds-gray-125;
  }
}

.avalon-area {
  .pagination {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    user-select: none;
  }

  .pagination__number {
    color: var(--pagination__number__color, @pagination__number__color);
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0 1.1rem;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.1rem;

    @media @sm {
      font-size: 1.9rem;
    }

    &:active,
    &.active {
      background-color: var(--pagination__number--active__backgroundColor, @pagination__number--active__backgroundColor);
      color: var(--pagination__number--active__color, @pagination__number--active__color);
      border-radius: 0.5rem;
    }
  }

  .pagination__arrow {
    cursor: pointer;
    transform: rotate(90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    margin-right: 0.1rem;

    &:last-child {
      margin-right: 0;
      transform: rotate(-90deg);
    }

    &:active {
      background-color: var(--pagination__number--active__backgroundColor, @pagination__number--active__backgroundColor);
      border-radius: 0.5rem;

      svg {
        stroke: var(--pagination__number--active__color, @pagination__number--active__color);
      }
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
      stroke: var(--pagination__number__color, @pagination__number__color);
      transition: transform 150ms ease-out;

      @media @sm {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
