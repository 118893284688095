body.region-corporate,
body.region-avalon-corporate {
  background: #004b32;
}
@media (max-width: 950px) {
  .region-avalon-corporate .accountmenu {
    overflow: hidden;
  }
}
.region-avalon-corporate .login-btn.mobile-only.avalon-login-link {
  display: none !important;
}
@media (min-width: 950px) {
  .region-avalon-corporate .brand-nav__logo-img {
    display: none;
  }
}
.top-navigation-theme-corporate .menu-toggle-right {
  background-color: #00402b;
}
.top-navigation-theme-corporate .accountmenu .login-btn,
.top-navigation-theme-corporate .accountmenu .signup-btn {
  display: none;
}
.top-navigation-theme-corporate .brand-nav {
  background-color: #00402b;
}
.top-navigation-theme-corporate .brand-nav .login-btn.mobile-only {
  display: none;
}
.top-navigation-theme-corporate .brand-nav .brand-nav__logo-cube,
.top-navigation-theme-corporate .brand-nav .mobile-navigation-header__logo-cube {
  display: none;
}
.top-navigation-theme-corporate .mobile-navigation-header {
  background-color: #00402b;
}
.top-navigation-theme-corporate .corporate-nav {
  background-color: #005f3f;
}
.top-navigation-theme-corporate .corporate-nav .mobile-navigation-header,
.top-navigation-theme-corporate .corporate-nav .mobile-navigation-footer__icon {
  background-color: #005f3f;
}
.top-navigation-theme-corporate .brand-nav__navigation-header,
.top-navigation-theme-corporate .corporate-nav__navigation-header {
  background-color: #00402b;
}
.top-navigation-theme-corporate .brand-nav__navigation-footer-icon,
.top-navigation-theme-corporate .corporate-nav__navigation-footer-icon {
  background-color: #00402b;
}
.top-navigation-theme-corporate .brand-nav__navigation-item-link:before,
.top-navigation-theme-corporate .corporate-nav__navigation-item-link:before {
  background-color: #00402b;
}
.top-navigation-theme-corporate .brand-nav__navigation .corporate-nav__list-link:hover,
.top-navigation-theme-corporate .corporate-nav__navigation .corporate-nav__list-link:hover {
  color: #fff;
}
.top-navigation-theme-corporate .mega-menu__campaign__cta-button {
  background-color: #00402b;
  color: white;
}
.top-navigation-theme-corporate .mega-menu__campaign__cta-button:hover {
  background-color: #005f3f;
}
.region-corporate .custom-static-fix-height {
  height: 62px;
}
