/* ===========================================
   utility.grid
   ========================================= */
// Utilities – utility- and helper classes with the ability to override anything
@import "_imports.less";

.avalon-hero {
  align-items: center;

  &__content {
    justify-content: center;
  }

  &__feed {
    &-teams {
      color: var(--feedTextColor, @ds-white);
      text-align: center;
      font-size: 4rem;
      font-weight: bold;
      font-family: @font-impact;
      line-height: 1;
      margin: 1rem 0;
      font-style: italic;
      transition: color .3s ease;

      @media all and (max-width: (@medium + 1)) {
        font-size: 8vw;
      }

      .pocaga-facts & {
        color: @color-primary;
      }
    }

    &-team {
      display: inline-block;
    }

    &__cta-wrapper {
      .avalon-button--theme {
        background: #003e99;
        color: #fff;
      }
    }
  }

  &__feed-link {
    color: black;

    &:hover .pocaga-hero__feed-teams {
      color: @color-secondary;

      .pocaga-facts & {
        color: lighten(@color-primary, 8%);
      } 
    }
  }

  &__odds-container {
    text-align: center;

    .pocaga-facts & {
      display: flex;
      justify-content: space-between;
    }
  }

  &__odds {
    color: var(--feedTextColor, @ds-white);
    display: inline-block;
    margin: 0 1rem;
    font-size: 3rem;
    font-family: @font-impact;
    font-style: italic;

    @media all and (max-width: (@medium + 1)) {
      font-size: 4.5vw;
    }

    .pocaga-facts & {
      display: flex;
      align-items: center;
      font-size: 2rem;
      margin: 0;
      color: white;
    }
  }

  &__odds-type {
    background: var(--oddsBackgroundColor, #ed6b19);
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.25);
    padding: 0.2rem 0;
    width: 4rem;
    display: block;
    float: left;
    text-align: center;
    font-family: @font-impact;
    font-size: 2.3rem;
    font-style: normal;
    color: var(--oddsTextColor, @ds-white);
    margin-right: 0.5rem;
    margin-top: 0.4rem;

    @media all and (max-width: (@medium + 1)) {
      font-size: 3.5vw;
      width: 6.5vw;
      height: 6.5vw;
      padding: 0.5vw 0;
      margin-top: 0.3vw;
    }

    .pocaga-facts & {
      @media all and (max-width: (@medium + 1)) {
        font-size: 7.5vw;
        width: 13.5vw;
        height: 13.5vw;
      }
    }
  }
}
