﻿/* ===========================================
   component.vip-member-campaign
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-vip-member-campaign {
  background-color: @color-black-100;
  color: @color-white-100;
  width: 100%;

  &__main-header {
    text-align: center;

    @media screen and (max-width: @medium) {
      p {
        margin: 0;
      }
    }
  }

  &__items {
    counter-reset: section;
    margin-top: 3rem;
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;
  }

  &__divider {
    background: @color-primary;
    height: .1rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  &__item {
    width: 100%;
    padding: 3rem;
    border-top: 1px solid @color-gray-100;

    @media screen and (max-width: @medium) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__item-icon {
    background: @color-primary;
    color: @color-black-100;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    float: left;
    margin-right: 2rem;
    text-align: center;
    display: table;
    font-size: 3rem;

    @media screen and (max-width: @medium) {
      height: 4rem;
      width: 4rem;
      font-size: 2.1rem;
    }

    &::before {
      display: table-cell;
      vertical-align: middle;
      counter-increment: section;
      content: counters(section, ".") " ";
      font-weight: 900;
    }
  }

  &__item-wrapper {
    .clearfix();
  }

  &__item-title {
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 1.5rem;
    padding-left: 7.5rem;

    @media screen and (max-width: @medium) {
      padding-top: 1rem;
      padding-left: 6rem;
    }
  }

  &__item-subheading {
    padding-top: 1.5rem;
    padding-left: 7.5rem;

    @media screen and (max-width: @medium) {
      padding-left: 6rem;
      width: 100%;
      font-size: @font-size--sm;
    }
  }
}