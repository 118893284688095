/* ===========================================
   elements.typography
   ========================================= */
// Elements – unclassed HTML elements, redefine browsers' default styling

@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond PoCaGa
.avalon-area {
  font-family: @font-primary;
  font-size: @font-size;
  color: @color-black;
  line-height: @lineheight-normal;

  strong {
    font-weight: bold;
  }

  // Do we need to add more padding after a hero spot? Maybe
  // .avalon-hero+*:not(section),
  // .avalon-hero+*:not(.three-col),
  // .avalon-hero+*:not(.one-col) {
  // 	padding-top: 5rem;
  // }
}
