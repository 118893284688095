﻿@import (reference) "./../_imports.less";

.rules-and-terms {
  position: relative;
  width: 100%;
  z-index: 2;
  border-radius: 2rem 2rem 0 0;
  background: @ds-white;
  padding: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  max-width: 144rem;
  margin: -4rem auto 0;

  &--open {
    position: fixed;
    top: 0;
  }

  @media @xl {
    border-radius: 4rem 4rem 0 0;
  }
}

.rules-and-terms__top {
  position: sticky;
  top: 0;
  text-align: right;
  z-index: 10;
  background: fade(@ds-white, 93%);
  border-radius: 2rem 2rem 0 0;
  margin: 0;
  padding: 2rem 2rem 1rem;

  .rules-and-terms--open & {
    background: @ds-white;
    border-bottom: 0.1rem solid @ds-gray-475;

    overflow: scroll;
    height: 100vh;

    @media @xl {
      display: none;
    }
  }

  @media @xl {
    border-radius: 4rem 4rem 0 0;
    padding: 4rem 4rem 1rem;
    position: relative;
    top: auto;
  }
}

.rules-and-terms__input {
  margin: 0;
  padding: 0 1.5rem;
  border: 0.1rem solid @ds-gray-475;
  border-radius: 0.8rem;
  height: 5.6rem;
  width: ~"calc(100% - 7.6rem)";
  max-width: 34.5rem;
  background-repeat: no-repeat;
  background-position: ~"calc(100% - 1.6rem)" center;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/search.svg);
  background-size: 1.8rem;

  .rules-and-terms--search & {
    background: none;
  }
}

.rules-and-terms__input-clear {
  display: none;
  width: 5rem;
  height: 5rem;
  position: absolute;
  z-index: 1;
  right: 2.3rem;
  top: 2.3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .rules-and-terms--search & {
    display: flex;
  }

  @media @xl {
    right: 4.3rem;
    top: 4.3rem;
  }
}

.rules-and-terms__input-clear-lines {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -0.8rem;
    height: 0.2rem;
    background: @ds-black;
    border-radius: 0.1rem;
    transform-origin: center;
    width: 2rem;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.rules-and-terms__toggle {
  display: flex;
  height: 5.6rem;
  width: 5.6rem;
  border: 0.1rem solid @ds-gray-475;
  border-radius: 0.8rem;
  position: absolute;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media @xl {
    display: none;
  }
}

.rules-and-terms__toggle-lines {
  position: relative;
  width: 2.6rem;
  height: 0.3rem;
  background: @ds-black;
  border-radius: 0.1rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2.6rem;
    height: 0.3rem;
    background: @ds-black;
    border-radius: 0.1rem;
    transform: translatey(-0.8rem);
  }

  &::after {
    transform: translatey(0.8rem);
  }

  .rules-and-terms--open & {
    background: none;

    &::before,
    &::after {
      transform-origin: center;
      width: 2.8rem;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.rules-and-terms__content {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  padding: 1rem 3rem 3rem;

  @media @xl {
    padding: 1rem 4rem 4rem;
  }
}

.rules-and-terms__toc-mobile {
  display: none;
  text-align: left;
  list-style-type: none;
  margin: 3rem 0 6rem 1rem;
  padding: 0;

  a:not([class]) {
    font-weight: normal;
    color: @ds-black;
    text-decoration: none;
  }

  li {
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  .rules-and-terms--open & {
    display: block;

    @media @xl {
      display: none;
    }
  }

  @media @xl {
    display: none;
  }
}

.rules-and-terms__toc {
  text-align: left;
  display: none;
  background: @ds-white;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0;
  border-bottom: 0.1rem solid @ds-black;
  list-style-type: none;
  margin: 0;
  line-height: 1.3;

  li {
    padding: 0;
    margin: 0 0 0.8rem;

    &:last-child {
      margin: 0;
    }

    a:not([class]) {
      font-weight: normal;
      text-decoration: none;
      color: @ds-black;

      &:hover {
        text-decoration: underline;
        font-weight: bold;
        color: @ds-black;
      }
    }
  }

  li:first-child a:not([class]) {
    font-weight: bold;
  }

  @media @xl {
    display: block;
    border: none;
    width: 28rem;
    flex: 0 0 28rem;
    padding: 0 2rem 0 0;
    position: sticky;
    top: 5rem;
    margin-bottom: auto;
    max-height: ~"calc(100vh - 5rem)";
    overflow: auto;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.rules-and-terms__rules {
  flex: 1;

  mark {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -0.1em;
      bottom: -0.15em;
      left: 0.5ch;
      right: -0.2ch;
      border-radius: 1em 0 1em 0;
      background-image: linear-gradient(-100deg, rgba(255, 255, 0, 0.3), rgba(255, 255, 0, 0.7) 95%, rgba(255, 255, 0, 0.1));
    }
  }

  h1,
  h2,
  h3 {
    line-height: 1.2;
    padding-top: 12rem;
    margin: -12rem 0 0.4em;

    @media @xl {
      padding-top: 5rem;
      margin: -5rem 0 0.4em;
    }
  }

  p {
    margin: 0 0 0.4em;
  }

  dl {
    line-height: 1.5;
  }

  dd {
    margin: -1.5em 0 0.4em 4ch;

    @media @xl {
      margin: -1.5em 0 0.4em 6ch;
    }
  }

  dl > dt {
    font-weight: bold;
  }

  dl dl dt {
    font-weight: normal;
  }

  dl dl dl dd {
    margin-left: 4ch;
  }

  ol:not([class]) li,
  ul:not([class]) li {
    list-style-type: inherit;
  }

  table {
    tr {
      th {
        max-width: 5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media @sm {
          max-width: unset;
        }
      }
    }
  }

  @media @xl {
    padding-left: 2rem;
    border-left: 0.3rem solid @ds-green;
  }
}
