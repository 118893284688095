/* ===========================================
   elements.iframe
   ========================================= */
// Elements – unclassed HTML elements, redefine browsers' default styling

@import (reference) "./../_imports.less";

// Namespacing is necessary to prohibit code-bleed to projects beyond Avalon
.avalon-area {
  iframe {
    border: none;
  }
}
