﻿/* ===========================================
   settings.colors
   ========================================= */
// Color variables used in PoCaGa, Poker and Casino projects
@import "../../AvalonComponents/Styles/_imports.less";

@color-primary: @color-yellow-100;
@color-primary--dark: darken(@color-primary, 8%);
@color-secondary: @color-blue-100;
@color-secondary--light: lighten(@color-blue-100, 10%);
@color-secondary--dark: darken(@color-blue-100, 8%);
@color-secondary--darker: darken(@color-blue-100, 16%);
@color-third: #da702d;
@color-third--light: lighten(@color-third, 8%);
@color-third--dark: darken(@color-third, 8%);

// Set Casino colors
.avalon-area {
  --color-primary: @color-primary;
  --color-primary--light: lighten(@color-primary, 8%);
  --color-primary--dark: @color-primary--dark;

  --color-secondary--dark: @color-secondary--dark;

  // Button colors
  --color-button-theme: @color-primary;

  // Background colors
  --color-background-overlay: @color-gold-100;
}

// Set eSport colors
@esport-color-lightgray: #d9d9d9;
@esport-color-gray: #979797;
@esport-color-darkgray: #808080;
@esport-color-orange: #dc5f12;
