﻿@import (reference) '../Colors/Base';
@import (reference) "../Variables/breakpoints";

.app-installation-guide__version-selector {
  position: relative;
  z-index: 15;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: @medium) {
    padding: 0 5rem;
  }

  @media only screen and (min-width: @large) {
    flex-direction: row;
    justify-content: center;
  }

  .selector-wrapper {
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 100%;
    margin-bottom: 1.5rem;

    @media only screen and (min-width: @large) {
      flex-direction: row;
      width: auto;
      margin-left: 2rem;

      &:first-child {
        margin-left: 0;
      }
    }

    .selector-label {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 600;
      line-height: 1.3;
      font-size: 1.3rem;
      margin: 0 0 0.5rem;

      @media only screen and (min-width: @large) {
        margin: 1rem 1rem 1rem 0;
      }
    }

    .options-wrapper {
      display: inline-block;
      position: relative;
      box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);

      .options-placeholder-wrapper {
        background-color: white;
        border-radius: 0.4rem;
        position: relative;
        overflow: hidden;
        padding: 0 3rem 0 1.5rem;
        cursor: pointer;
        height: 3.5rem;
        line-height: 3.5rem;

        &:hover {
          background-color: @app-universe-dropdown-item-hover;
        }

        .placeholder-text {
          color: black;
          font-weight: 600;
          line-height: 1;
          font-size: 1.4rem;

          &:after {
            content: "";
            border-width: 0.2rem 0.2rem 0 0;
            border-style: solid;
            border-color: black;
            display: inline-block;
            box-sizing: border-box;
            transform: translateY(-50%) rotate(135deg);
            transition: transform 150ms ease-out;
            position: absolute;
            width: 0.7rem;
            height: 0.7rem;
            right: 1.5rem;
            top: 1.6rem;
          }
        }
      }

      .options-list-wrapper {
        position: absolute;
        z-index: 10;
        background-color: white;
        border-radius: 0.4rem;
        left: 0;
        box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.5);
        width: 22.5rem;
        margin-top: 1rem;

        &:before {
          content: '';
          border-radius: 0.2rem;
          background-color: white;
          opacity: 0;
          position: absolute;
          top: 0;
          transform: translateY(30%) rotate(45deg);
          width: 1.2rem;
          height: 1.2rem;
          left: 1.3rem;
        }

        .option-item-wrapper {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          position: relative;
          cursor: pointer;

          .option-item-text {
            color: black;
            font-weight: 600;
            line-height: 1;
            display: block;
            border-top: 1px solid @app-universe-dropdown-item-selected;

            font-size: 1.4rem;
            padding: 1.4rem 3.5rem 1.4rem 1.4rem;
          }

          .option-item-icon {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            position: absolute;
            width: 1.8rem;
            height: 1.8rem;
            top: 1.3rem;
            right: 1.4rem;

            &:before {
              content: '';
              background-color: @app-universe-primary;
              opacity: 0;
              border-radius: 50%;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transform: scale(0);
            }

            &:after {
              content: '';
              width: 35%;
              padding-top: 20%;
              opacity: 0;
              border-width: 0 0 0.2rem 0.2rem;
              border-style: solid;
              border-color: white;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -65%) rotate(-45deg);
            }
          }

          &:first-child {
            .option-item-text {
              border-top: none;
            }
          }

          &.selected {
            .option-item-icon {
              border: 1px solid @app-universe-primary;

              &:before {
                opacity: 1;
                transform: scale(1);
              }

              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      &.active {
        .options-placeholder-wrapper {
          .placeholder-text {
            &:after {
              transform: translateY(0) rotate(-45deg);
            }
          }
        }

        .options-list-wrapper {
          &:before {
            transform: translateY(-30%) rotate(45deg);
            opacity: 1;
          }

          .option-item-wrapper {
            max-height: 4.2rem;
            opacity: 1;

            &:hover {
              background-color: @app-universe-dropdown-item-hover;
            }
          }
        }
      }
    }
  }
}
