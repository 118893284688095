/* ===========================================
   component.facts
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-facts {
  background: @color-tertiary;
  color: @tertiary-text-color;
  font-size: @font-size--sm;

  &:nth-child(1n + 2) {
    margin-top: 1rem;
  }

  &__title {
    color: @tertiary-text-color;
    text-align: center;

    &--white {
      color: @color-white-100 !important;
    }

    &--black {
      color: @color-black-100 !important;
    }

    &--yellow {
      color: @color-yellow-100 !important;
    }
  }

  &__cta {
    text-align: center;
    margin-top: 3rem;
  }

  &__cta-link {
    min-width: 100px;
  }

  .facts--link {
    display: block;
    padding-left: 0;

    &:last-child {
      li:last-child {
        border-bottom: 0;
        padding-bottom: 1rem;

        a {
          &::after {
            margin-top: 1rem;
          }
        }
      }
    }

    li {
      list-style-type: none;
      padding: 3rem 4.5rem 3rem 3rem;
      margin: 0;
      position: relative;
      border-bottom: 1px solid #4f4e56;

      &:first-child {
        border-top: 1px solid #4f4e56;
      }

      a {
        color: white;
        display: block;

        &:hover {
          color: white;
          text-decoration: none;
        }

        &:after {
          content: '';
          width: 4rem;
          height: 4rem;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: @color-primary;
          background-image: url('/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/Icons/arrow.1.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 25%;
          color: @color-black-100;
          text-align: center;
          border-radius: 50%;
          font-size: @font-size--xl;
        }

        &:active:after,
        &:hover:after {
          background-color: @color-primary;
        }
      }
    }
  }
}

.three-col__col--center {
  .avalon-facts {
    background: transparent;
    color: @color-black-100;

    &__title {
      display: none;
    }

    &--link {
      li a {
        color: @color-black-100;
      }
    }

    ol {
      li {
        &::before {
          top: -.35em;
        }
      }
    }
    
    ul {
      li {
        &::before {
          top: -.25em;
        }
      }
    }
  }
}
