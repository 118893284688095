﻿@import (reference) "./../_imports.less";

// Defaults to spillehjoernet.
@jackpot-winners__backgroundColor: #748FAF;
@jackpot-winners__sort__backgroundColor: #5B799E;
@jackpot-winners__sort-label__color: @ds-white;
@jackpot-winners__winner__color: @ds-white;
@jackpot-winners__winner__borderColor: #5B799E;
@jackpot-winners__winner--even__backgroundColor: #6B88AA;
@jackpot-winners__winner-img__backgroundColor: #5B799E;
@jackpot-winners__winner-prize__color: @ds-yellow;
@jackpot-winners__winner-cta__color: @ds-black;
@jackpot-winners__winner-cta__backgroundColor: @ds-white;
@jackpot-winners__winner-cta--hover__color: @ds-white;
@jackpot-winners__winner-cta--hover__backgroundColor: #D9569E;
@jackpot-winners__winner-cta__borderRadius: 2rem;
@jackpot-winners__show-more__color: @ds-white;

:root {
  --jackpot-winners__backgroundColor: @jackpot-winners__backgroundColor;
  --jackpot-winners__sort__backgroundColor: @jackpot-winners__sort__backgroundColor;
  --jackpot-winners__sort-label__color: @jackpot-winners__sort-label__color;
  --jackpot-winners__winner__color: @jackpot-winners__winner__color;
  --jackpot-winners__winner__borderColor: @jackpot-winners__winner__borderColor;
  --jackpot-winners__winner--even__backgroundColor: @jackpot-winners__winner--even__backgroundColor;
  --jackpot-winners__winner-img__backgroundColor: @jackpot-winners__winner-img__backgroundColor;
  --jackpot-winners__winner-prize__color: @jackpot-winners__winner-prize__color;
  --jackpot-winners__winner-cta__color: @jackpot-winners__winner-cta__color;
  --jackpot-winners__winner-cta__backgroundColor: @jackpot-winners__winner-cta__backgroundColor;
  --jackpot-winners__winner-cta--hover__backgroundColor: @jackpot-winners__winner-cta--hover__backgroundColor;
  --jackpot-winners__winner-cta--hover__color: @jackpot-winners__winner-cta--hover__color;
  --jackpot-winners__winner-cta__borderRadius: @jackpot-winners__winner-cta__borderRadius;
  --jackpot-winners__show-more__color: @jackpot-winners__show-more__color;

  .region-casino {
    --jackpot-winners__backgroundColor: #e9d9a6;
    --jackpot-winners__sort__backgroundColor: #d5bf85;
    --jackpot-winners__sort-label__color: @color-brown-chocolate;
    --jackpot-winners__winner__color: #1a1a1a;
    --jackpot-winners__winner__borderColor: #c4a76d;
    --jackpot-winners__winner--even__backgroundColor: #E1D09A;
    --jackpot-winners__winner-img__backgroundColor: #d5bf85;
    --jackpot-winners__winner-prize__color: @color-brown-chocolate;
    --jackpot-winners__winner-cta__color: @ds-white;
    --jackpot-winners__winner-cta__backgroundColor: @color-brown-chocolate;
    --jackpot-winners__winner-cta--hover__backgroundColor: #824D65;
    --jackpot-winners__winner-cta__borderRadius: 0.2rem;
    --dropdown__color: @color-brown-chocolate;
    --dropdown__content__backgroundColor: #E9D9A6;
    --dropdown__content--hover__backgroundColor: #E9D9A6;
    --dropdown__menu-item--hover__backgroundColor: #d5bf85;
    --dropdown__menu-item__borderColor: #D5BF85;
    --dropdown__arrow__svg__stroke: @color-brown-chocolate;
    --jackpot-winners__show-more__color: @color-brown-chocolate;
  }

  .region-avalon-bingo,
  .region-avalon-bingo-gvc,
  .region-avalon-bingo-slingo {
    --jackpot-winners__backgroundColor: #F7F6F0;
    --jackpot-winners__sort__backgroundColor: #2E933B;
    --jackpot-winners__sort-label__color: @ds-white;
    --jackpot-winners__winner__color: #333;
    --jackpot-winners__winner__borderColor: #D9D4BE;
    --jackpot-winners__winner--even__backgroundColor: #F3F0E1;
    --jackpot-winners__winner-img__backgroundColor: #61635D;
    --jackpot-winners__winner-prize__color: #333;
    --jackpot-winners__winner-cta__color: @ds-black;
    --jackpot-winners__winner-cta__backgroundColor: @ds-yellow;
    --jackpot-winners__winner-cta--hover__backgroundColor: #EBA800;
    --jackpot-winners__winner-cta__borderRadius: 2rem;
    --dropdown__color: #333;
    --dropdown__content__backgroundColor: #F7F6F0;
    --dropdown__content--hover__backgroundColor: #F7F6F0;
    --dropdown__menu-item--hover__backgroundColor: #efeee6;
    --dropdown__menu-item__borderColor: #D9D4BE;
    --dropdown__arrow__svg__stroke: #333;
    --jackpot-winners__show-more__color: #333;
  }
}

.jackpot-winners__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;

  .loading-spinner--dark {
    display: none;
  }

  .region-casino &,
  .region-avalon-bingo & {
    .loading-spinner {
      display: none;
    }

    .loading-spinner--dark {
      display: block;
    }
  }
}

.jackpot-winners {
  width: calc(~'100% - 4rem');
  max-width: 104rem;
  margin: 2rem auto;
  background-color: var(--jackpot-winners__backgroundColor, @jackpot-winners__backgroundColor);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.3rem 0.5rem fade(@ds-black, 25%);
}

.jackpot-winners__sort {
  background-color: var(--jackpot-winners__sort__backgroundColor, @jackpot-winners__sort__backgroundColor);
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;

  @media @lg {
    padding: 1rem 3rem;
  }
}

.jackpot-winners__sort-label {
  margin-right: 1rem;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 800;
  color: var(--jackpot-winners__sort-label__color, @jackpot-winners__sort-label__color);
}

.jackpot-winners__sort-dropdown {
  .avalon-dropdown {
    text-transform: none;
    width: 130px;
    height: 3.2rem;

    .dropdown__label {
      height: 3.2rem;
    }

    .dropdown__arrow {
      padding-right: 1rem;
    }
  }

  .avalon-dropdown,
  .avalon-dropdown.open {
    .dropdown__content {
      box-shadow: 0 0.3rem 0.5rem fade(@ds-black, 25%);
      border: none;
    }
  }

  .dropdown__menu {
    .dropdown__menu-item {
      height: 3.5rem;

      &.selected {
        background-color: transparent;
      }
    }
  }
}

.jackpot-winners__winners {
  padding: 0 2rem;

  @media @lg {
    padding: 0;
    margin-bottom: 2rem;
  }
}

.jackpot-winners__winner {
  position: relative;
  color: var(--jackpot-winners__winner__color, @jackpot-winners__winner__color);
  border-bottom: 0.1rem solid;
  border-color: var(--jackpot-winners__winner__borderColor, @jackpot-winners__winner__borderColor);
  padding-bottom: 2rem;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 12.5rem auto;
  grid-template-rows: auto auto 1fr 0 auto;
  column-gap: 1rem;
  grid-template-areas: "img player" "img date" "img prize" ". ." "game cta";

  @media @sm {
    grid-template-columns: 10rem 1fr 1fr 14rem;
    grid-template-rows: auto auto 1fr;
    column-gap: 3rem;
    grid-template-areas: "img game prize cta" "img player prize cta" "img date prize cta";

    .jackpot-winners--bingo-manual & {
      grid-template-columns: auto 10rem 1fr 1fr 14rem;
      grid-template-areas: "date img game prize cta" "date img player prize cta";
    }
  }

  @media @lg {
    grid-template-columns: 10rem 1fr 1fr 1fr 14rem;
    grid-template-rows: auto auto;
    grid-template-areas: "img game player prize cta" "img game date prize cta";
    border-bottom: none;
    margin: 0;
    padding: 2rem 3rem;

    .jackpot-winners--bingo-manual & {
      grid-template-columns: 10rem 1fr 1fr 1fr 1fr 14rem;
      grid-template-areas: "date img game player prize cta";
    }

    &:nth-child(even) {
      background-color: var(--jackpot-winners__winner--even__backgroundColor, @jackpot-winners__winner--even__backgroundColor);
    }

    &:nth-child(7)::after {
      content: "";
      position: absolute;
      left: 3rem;
      right: 3rem;
      bottom: 0;
      border-bottom: 0.1rem solid;
      border-color: var(--jackpot-winners__winner__borderColor, @jackpot-winners__winner__borderColor);
    }
  }
}

.jackpot-winners__winner-img {
  grid-area: img;
  background-color: var(--jackpot-winners__winner-img__backgroundColor, @jackpot-winners__winner-img__backgroundColor);
  border-radius: 0.5rem;
  width: 10rem;
  height: 9rem;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.jackpot-winners__winner-game {
  grid-area: game;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.1;
  word-break: break-word;
  align-self: center;

  @media @sm {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    .jackpot-winners--bingo-manual & {
      align-self: flex-end;
    }
  }

  @media @lg {
    align-self: center;
    margin-bottom: 0;

    .jackpot-winners--bingo-manual & {
      align-self: center;
      margin-bottom: 0;
    }
  }
}

.jackpot-winners__winner-player {
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: player;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.3;

  @media @sm {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }

  @media @lg {
    align-self: end;

    .jackpot-winners--bingo-manual & {
      align-self: center;
      margin-bottom: 0;
    }
  }
}

.jackpot-winners__winner-date {
  grid-area: date;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.3;
  margin-bottom: 1rem;
  white-space: nowrap;

  @media @sm {
    font-size: 1.6rem;
    margin: 0;
    align-self: center;

    .jackpot-winners--bingo-manual & {
      font-size: 1.4rem;
    }
  }
}

.jackpot-winners__winner-prize {
  grid-area: prize;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.3;
  color: var(--jackpot-winners__winner-prize__color, @jackpot-winners__winner-prize__color);

  @media @sm {
    font-size: 2rem;
    align-self: center;
    text-align: right;
  }
}

.jackpot-winners__winner-prize-type {
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.3;

  @media @sm {
    font-size: 1.4rem;
  }
}

.jackpot-winners__winner-cta {
  grid-area: cta;
  max-width: 14rem;
  height: 3.2rem;
  color: var(--jackpot-winners__winner-cta__color, @jackpot-winners__winner-cta__color);
  background-color: var(--jackpot-winners__winner-cta__backgroundColor, @jackpot-winners__winner-cta__backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.3;
  border-radius: var(--jackpot-winners__winner-cta__borderRadius, @jackpot-winners__winner-cta__borderRadius);

  &:hover {
    color: var(--jackpot-winners__winner-cta--hover__color, @jackpot-winners__winner-cta--hover__color);
    background-color: var(--jackpot-winners__winner-cta--hover__backgroundColor, @jackpot-winners__winner-cta--hover__backgroundColor);
  }

  @media @sm {
    align-self: center;
  }
}

.jackpot-winners__nav {
  display: flex;
  justify-content: center;
  padding: 0 2rem 2rem;

  .pagination .pagination__number:not(.active):hover {
    text-decoration: underline;
  }
}

.jackpot-winners__show-more {
  cursor: pointer;
  position: relative;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--jackpot-winners__show-more__color, @jackpot-winners__show-more__color);

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;

    right: 50%;
    transform: translateX(50%) rotate(45deg);
    height: 0.9rem;
    width: 0.9rem;
    border-right: 0.2rem solid;
    border-bottom: 0.2rem solid;
    border-color: var(--jackpot-winners__show-more__color, @jackpot-winners__show-more__color);
    transition-duration: 200ms;
  }
}
