﻿@import (reference) "../variables.less";

body.region-frontpage,
body.region-avalon-frontpage,
body.region-avalon-corporate {
  .seo-text-container,
  footer .info-bar {
    background: @frontpage-background;
  }
}
