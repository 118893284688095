/* ===========================================
   component.content-text
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";
// file needs to be imported,
// so @extend can be used on .u-padding classes for conditional styling
@import (reference) "./../Utility/spacing.less";

.avalon-content-text {

  img {
    max-width: 100%;
    height: auto;
  }

  .avalon-heading + & {
    &:extend(.avalon-area .u-padding-top--none);
  }

  .three-col__col--right & {
    @media @sm {
      margin-top: -@container-padding;
    }
  }
}
