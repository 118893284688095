@import (reference) "./../_imports.less";
@import (reference) url("../Abstracts/_background-frames");

.region-spot__item {
  .background-frame__eurojackpot01,
  .background-frame__eurojackpot02,
  .background-frame__eurojackpot03,
  .background-frame__eurojackpot04,
  .background-frame__eurojackpot05,
  .background-frame__eurojackpot06 {
    background-color: @eurojackpot-gold;

    .region-spot__bg {
      margin: 0.2rem;

      @media (min-width: @breakpoint-region-spot-medium) {
        margin: 0.5rem;
      }
    }

    .region-spot__content-text {
      color: @color-black;
    }
  }

  .background-frame__eurojackpot01 {
    .framed-background__bg-eurojackpot-gold--small();

    .region-spot__bg {
      .framed-background__frame-eurojackpot01--small();
    }
  }

  .background-frame__eurojackpot02 {
    background: black;

    .region-spot__bg {
      .framed-background__frame-eurojackpot02--small();
      box-shadow: inset 0 0 0 0.1rem black;
    }
  }

  .background-frame__eurojackpot03 {
    background: black;

    .region-spot__bg {
      .framed-background__frame-eurojackpot03--small();
    }

    .region-spot__content-text {
      color: @eurojackpot-gold-light;
    }
  }

  .background-frame__eurojackpot04 {
    .framed-background__bg-eurojackpot-stars--small();

    .region-spot__bg {
      .framed-background__frame-eurojackpot04--small();
    }

    .region-spot__content-text {
      color: @eurojackpot-gold-light;
    }
  }

  .background-frame__eurojackpot05 {
    .framed-background__bg-eurojackpot-gold--small();

    .region-spot__bg {
      .framed-background__frame-eurojackpot05--small();
    }
  }

  .background-frame__eurojackpot06 {
    .framed-background__bg-eurojackpot-stars--small();

    .region-spot__bg {
      .framed-background__frame-eurojackpot06--small();
    }

    .region-spot__content-text {
      color: @eurojackpot-gold-light;
    }
  }

}
