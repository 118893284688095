﻿/* ===========================================
   component.link-list
   ========================================= */

// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avalon-link-list {
  background: @color-tertiary;
  color: @color-white;

  &__title {
    color: @color-primary;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
  }

  &__item {
    flex: 0 0 50%;
    flex-basis: calc(~'100%/2 - @{item-margin} / 2');
    border-top: 1px solid @color-gray-200;

    &:nth-last-child(-n+2) {
      border-bottom: 1px solid @color-gray-200;
    }

    @media screen and (max-width: @medium) {
      flex-basis: 100%;

      &:not(:nth-last-child(1)) {
        border-bottom: 0;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem 2rem 2rem;
    text-transform: uppercase;

    @media screen and (max-width: @medium) {
      padding: 2rem 1rem 2rem 2rem;
    }

    &::after {
      flex: 0 0 4.5rem;
      height: 4.5rem;
      content: '';
      border-radius: 50%;
      background: @color-primary url('/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/Icons/arrow.1.svg') no-repeat center center /25%;

      @media screen and (max-width: @medium) {
        flex-basis: 3.5rem;
        height: 3.5rem;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &:hover::after {
      background-color: @color-primary--dark;
    }
  }

  &__text {
    flex: 0 0 85%;
    flex-basis: calc(~'100% - 6rem');
  }
}