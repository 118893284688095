﻿@import (reference) "./../_imports.less";

.podcast-overview {
  margin: 0 auto;
  width: 90%;

  @media @md {
    max-width: 100rem;
  }
}

.podcast-overview__header {
  width: 100%;
  margin: 5rem 0;
  display: flex;
  justify-content: center;
}

.podcast-overview__episodes-wrapper {
  width: 100%;
  position: relative;
}

.podcast-overview__tags-and-search {
  font-size: 1.2rem;
  line-height: 1.6;
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
  
  @media @lg {
    justify-content: flex-end;
  }
}

.podcast-overview__tags {
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(~'100% - 5.2rem');
  transition-duration: 200ms;

  @media @sm {
    width: calc(~'50% - 2rem');
  }

  @media @lg {
    width: auto;
    flex: 1;
    position: absolute;
    left: 0;
    z-index: 10;
  }

  .podcast-overview__tags-and-search--toggle & {
    width: 8rem;

    @media @sm {
      width: calc(~'50% - 2rem');
    }
  }
}

.podcast-overview__dropdown {
  position: absolute;
  z-index: 10;
  border-radius: 1rem;
  border: @component-podcast-gray solid 0.2rem;
  width: 100%;
  background-color: @color-white;
  overflow: hidden;
  cursor: pointer;
  height: 3.2rem;

  &::after {
    content: '';
    position: absolute;
    top: 0.1rem;
    right: 1.2rem;
    transform: translateY(.9rem) rotate(45deg);
    height: 0.7rem;
    width: 0.7rem;
    border-bottom: @component-podcast-gray solid 0.2rem;
    border-right: @component-podcast-gray solid 0.2rem;
    transition-duration: 200ms;
  }

  @media @lg {
    width: 25rem;
    position: relative;
  }

  &--open {
    height: auto;

    &::after {
      transform: translateY(1.2rem) rotate(225deg);
    }
  }
}

.podcast-overview__dropdown-selected-item {
  height: 2.8rem;
  line-height: 2.8rem;
  padding: 0 2rem 0 1.5rem;
  transition-duration: 200ms;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.podcast-overview__dropdown-items {
  position: relative;
  transform: translateY(-0.7rem);
  transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1);
  overflow: hidden;
  background-color: @color-white;
  margin: 0 1rem 0.5rem;
  padding-top: 0.5rem;
}

.podcast-overview__dropdown--open .podcast-overview__dropdown-items {
  border-top: 0.1rem solid @component-podcast-gray;
  transform: none;
}

.podcast-overview__dropdown-item {
  height: 3rem;
  line-height: 3rem;
  padding: 0 0.5rem;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  &--selected {
    font-weight: 700;
  }
}

.podcast-overview__sort-after {
  display: none;
  white-space: nowrap;
  margin-right: 1rem;
  line-height: 3rem;

  @media @lg {
    display: block;
  }
}

.podcast-overview__tag {
  display: none;
  padding: 1rem 1.5rem;
  user-select: none;
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;

  &::after {
    border-radius: 0.6rem;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scaleX(0);
    opacity: 0;
    background-color: @color-black;
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &--selected {
    animation: podcast-tag-bump 200ms;
    color: @color-white;
    // Makes text look bold without changing container width
    text-shadow: 0.1rem 0 0 @color-white;

    &::after {
      animation: podcast-tag-curtain 0.5s forwards;
    }
  }

  @media @md {
    display: block;
  }
}

.podcast-overview__search {
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  height: 3.2rem;
  width: 3.2rem;
  border: @component-podcast-gray solid 0.2rem;
  line-height: 3.2rem;
  transition-duration: 200ms;

  @media @sm {
    width: calc(~'50% - 2rem');
  }

  @media @lg {
    width: 25rem;
  }

  .podcast-overview__tags-and-search--toggle & {
    width: calc(~'100% - 10rem');

    @media @sm {
      width: calc(~'50% - 2rem');
    }

    @media @lg {
      width: 25rem;
    }
  }

  input {
    background: transparent;
    border: none;
    padding: 0 1rem 0 3.5rem;
    width: 100%;
    transition-duration: 200ms;
    height: 3rem;
    line-height: 3rem;
    position: relative;
    top: -0.2rem;

    &:focus {
      outline: none;
    }
  }
}

.podcast-overview__search-loop {
  position: absolute;
  top: 50%;
  left: 0.4rem;
  transform: translateY(-50%);
  width: 2rem;

  @media @sm {
    left: 0.8rem;
  }
}

.podcast-overview__episodes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media @sm {
    flex-direction: row;
  }
}

.podcast-overview__episode {
  cursor: pointer;
  width: 100%;
  transition: transform 0.1s;
  transform: scale(1);
  display: flex;
  justify-content: space-between;
  height: 8.8rem;
  margin-bottom: 1.5rem;
  color: @color-black;

  &:focus {
    outline: none;
  }

  &:hover {
    animation: podcast-episode-bump 0.2s;
    transform: scale(1.03);
  }

  @media @sm {
    margin-bottom: 2rem;
    width: calc(~'50% - 2rem');
  }

  @media @lg {
    margin-bottom: 4rem;
    width: calc(~'33.3333% - 3rem');
    height: auto;
    flex-direction: column;
  }
}

.podcast-overview__episode-image-wrapper {
  position: relative;
  width: 9rem;
  height: 9rem;
  margin-right: 1rem;
  line-height: 0;

  @media @lg {
    margin-right: 0;
    width: 100%;
    height: auto;
  }
}

.podcast-overview__episode-image {
  object-fit: cover;
  width: 9rem;
  height: 9rem;

  @media @lg {
    width: 100%;
    height: 100%;
  }
}

.podcast-overview__episode-duration {
  position: absolute;
  bottom: 0;
  right: 0;
  color: @color-white;
  font-size: 1.3rem;
  line-height: 3rem;
  padding: 0 1.5rem;
  text-align: center;
  background: @color-black-75;
  font-weight: 300;
}

.podcast-overview__episode-content-wrapper {
  overflow: hidden;
  flex-basis: calc(~'100% - 11rem');
}

.podcast-overview__episode-title {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-style: italic;
  font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;

  @media @lg {
    font-weight: 700;
    font-size: 2rem;
    margin: 1rem 0;
  }
}

.podcast-overview__episode-description {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding-bottom: 0.1rem;
  color: @component-podcast-text-color;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;

  &--3-lines {
    -webkit-line-clamp: 3;
  }

  @media @lg {
    height: 8rem;
    font-size: 1.4rem;
    line-height: 2rem;
    -webkit-line-clamp: 4;

    &--3-lines {
      -webkit-line-clamp: 5;
      height: unset;
    }
  }
}

.podcast-overview__episode-separator {
  display: none;
  background: @component-podcast-gray;
  width: 100%;
  height: 0.1rem;
  margin: 1rem 0 0.5rem;

  @media @lg {
    display: block;
  }
}

.podcast-overview__episode-release-date {
  margin: 0;
  font-size: 1rem;
  color: @component-podcast-text-color;

  &--mobile {
    @media @lg {
      display: none;
    }
  }

  &--desktop {
    display: none;
    font-size: 1.2rem;

    @media @lg {
      display: block;
    }
  }
}

.podcast-overview__show-more-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.podcast-overview__show-more {
  margin-bottom: 2rem;
  color: @ds-white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background-color: @ds-black;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-size: 1.3rem;
}

.episode-overview__podcast-description {
  max-width: 145rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 5rem;
}

.episode-overview__podcast-description-inner-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 1rem;

  @media @md {
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 5rem;
  }
}

.episode-overview__podcast-description-text-wrapper {
  @media @md {
    flex-basis: 50%;
  }
}

.episode-overview__podcast-description-header {
  font-style: italic;
  font-family: @font-impact;
  font-size: 2.5rem;
  font-weight: 600;
}

.episode-overview__podcast-description-image-wrapper {
  margin: 2rem auto;
  width: 65%;

  @media @md {
    margin-right: 2rem;
    flex-basis: 50%;
    align-items: center;
  }
}

.episode-overview__podcast-description-image {
  width: 100%;
}

@keyframes podcast-episode-bump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1.03);
  }
}

@keyframes podcast-tag-curtain {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }

  30% {
    opacity: 1;
    transform: scaleX(1.02);
  }

  70% {
    opacity: 1;
    transform: scaleX(1);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes podcast-tag-bump {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.06);
  }

  60% {
    transform: scale(1, 1.04);
  }

  100% {
    transform: scale(1);
  }
}
