@import (reference) "./../_imports.less";

.onboarding-spot {
  position: relative;

  @media (min-width: @breakpoint-region-spot-medium) {
    background-image: radial-gradient(circle at top, #00704B -33%, #003F2A 103%, #003825 10%);
    margin-bottom: 4rem;
  }

  .onboarding-spot__container {
    max-width: 1840px;
    margin: 0 auto;
    padding: 0;

    @media (min-width: @breakpoint-region-spot-medium) {
      padding: 0 2rem;
      display: flex;
    }
  }

  .onboarding-spot__graphic {
    background-size: cover;
    background-position: center;

    @media (min-width: @breakpoint-region-spot-medium) {
      flex: 1;
      margin-right: 2rem;
      position: relative;
      transform: translateY(-2rem);
      margin-bottom: -4rem;
      border-radius: 0.6rem;
    }

    &:after {
      content: "";
      padding-bottom: 80%;
      display: block;

      @media (min-width: @breakpoint-region-spot-medium) {
        padding-bottom: 0;
      }
    }
  }

  .onboarding-spot__background-bottom {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;

    @media (min-width: @breakpoint-region-spot-medium) {
      height: auto;
      top: 50%;
      bottom: -4rem;
    }
  }

  .onboarding-spot__content {
    position: relative;
    width: 85%;
    margin: 0 auto -20vw;
    transform: translateY(-20vw);
    background-image: radial-gradient(circle at top, #00704B -33%, #003F2A 103%, #003825 10%);
    color: @color-white;
    border-radius: 0.6rem;

    @media (min-width: @breakpoint-region-spot-medium) {
      flex: 1;
      width: auto;
      margin: 0;
      transform: none;
      background-image: none;
      border-radius: 0;
    }
  }

  .onboarding-spot__text {
    padding: 4rem;

    @media (min-width: @breakpoint-region-spot-medium) {
      padding: 4rem 2rem;
    }

    .onboarding-spot__headline {
      text-transform: none;
      font-size: 4rem;
      line-height: 1.25;
      font-weight: 900;
    }

    .onboarding-spot__lead {
      font-size: 1.6rem;
      line-height: 1.35;
    }
  }

  .onboarding-spot__content-cta {
    color: @color-black;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-decoration: none;
    text-align: center;
    line-height: 3.5rem;
    transition: all 200ms;
    border-radius: 0.6rem;
    font-weight: 900;
    background-color: #FEB700;
    display: inline-block;
    padding: 0 3rem;
    white-space: nowrap;

    &:hover {
      background-color: #cb9200;
    }
  }
}
