﻿@import (reference) "./../_imports.less";

// Defaults to spillehjoernet.
@dropdown__color: @ds-white;
@dropdown__content__backgroundColor: @ds-blue-gray-600;
@dropdown__content--open__borderColor: @ds-white;
@dropdown__content--hover__backgroundColor: @ds-blue-gray-700;
@dropdown__menu-item__borderColor: @ds-blue-gray-550;
@dropdown__menu-item--selected__backgroundColor: @ds-blue-gray-550;
@dropdown__menu-item--hover__backgroundColor: @ds-blue-gray-600;
@dropdown__arrow__svg__stroke: @ds-white;

:root {
  --dropdown__color: @dropdown__color;
  --dropdown__content__backgroundColor: @dropdown__content__backgroundColor;
  --dropdown__content--open__borderColor: @dropdown__content--open__borderColor;
  --dropdown__content--hover__backgroundColor: @dropdown__content--hover__backgroundColor;
  --dropdown__menu-item__borderColor: @dropdown__menu-item__borderColor;
  --dropdown__menu-item--selected__backgroundColor: @dropdown__menu-item--selected__backgroundColor;
  --dropdown__menu-item--hover__backgroundColor: @dropdown__menu-item--hover__backgroundColor;
  --dropdown__arrow__svg__stroke: @dropdown__arrow__svg__stroke;

  .region-casino {
    --dropdown__content__backgroundColor: @ds-gold-600;
    --dropdown__content--open__borderColor: @ds-white;
    --dropdown__content--hover__backgroundColor: @ds-gold-700;
    --dropdown__menu-item__borderColor: @ds-gold-550;
    --dropdown__menu-item--selected__backgroundColor: @ds-gold-550;
    --dropdown__menu-item--hover__backgroundColor: @ds-gold-600;
  }

  .region-avalon-bingo,
  .region-avalon-bingo-gvc,
  .region-avalon-bingo-slingo {
    --dropdown__content__backgroundColor: @ds-lime-600;
    --dropdown__content--open__borderColor: @ds-white;
    --dropdown__content--hover__backgroundColor: @ds-lime-700;
    --dropdown__menu-item__borderColor: @ds-lime-550;
    --dropdown__menu-item--selected__backgroundColor: @ds-lime-550;
    --dropdown__menu-item--hover__backgroundColor: @ds-lime-600;
  }
}

.avalon-dropdown {
  user-select: none;
  position: relative;
  z-index: 15;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  color: @dropdown__color;
  color: var(--dropdown__color);
  height: 4rem;
  flex: 1;

  @media @sm {
    height: 4.8rem;
    flex: 0 0 20rem;
  }

  .dropdown__content {
    position: absolute;
    left: 0;
    border-radius: 0.3rem;
    width: 100%;
    height: 100%;
    border: 0.1rem solid transparent;
    overflow: hidden;
    background-color: @dropdown__content__backgroundColor;
    background-color: var(--dropdown__content__backgroundColor);
  }

  &:hover {
    .dropdown__content {
      background-color: @dropdown__content--hover__backgroundColor;
      background-color: var(--dropdown__content--hover__backgroundColor);
    }
  }

  &.open {
    .dropdown__content {
      height: auto;
      border: 0.1rem solid;
      border-color: @dropdown__content--open__borderColor;
      border-color: var(--dropdown__content--open__borderColor);
    }
  }

  .dropdown__arrow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;

    svg {
      width: 1.3rem;
      height: 1.3rem;
      stroke: @dropdown__arrow__svg__stroke;
      stroke: var(--dropdown__arrow__svg__stroke);
      transition: transform 150ms ease-out;

      @media @sm {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  &.open {
    .dropdown__arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown__label {
    position: relative;
    padding: 0 4rem 0 1.5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media @sm {
      height: 4.8rem;
    }
  }

  .dropdown__menu {
    margin-top: 0.8rem;
    transform: translateY(0.2rem);
    transition: transform 200ms cubic-bezier(0, 1.7, 0.3, 1);
  }

  &.open {
    .dropdown__menu {
      display: block;
      transform: none;
    }
  }

  .dropdown__menu-item {
    height: 5.2rem;
    padding: 0 1rem 0 1.6rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid;
    border-color: @dropdown__menu-item__borderColor;
    border-color: var(--dropdown__menu-item__borderColor);

    &.selected {
      font-weight: bold;
      background-color: @dropdown__menu-item--selected__backgroundColor;
      background-color: var(--dropdown__menu-item--selected__backgroundColor);
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
      background-color: @dropdown__menu-item--hover__backgroundColor;
      background-color: var(--dropdown__menu-item--hover__backgroundColor);
    }
  }

  .dropdown__menu-item-container {
    display: flex;
    align-items: center;
    height: 4rem;
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 0 0 0 2.5rem;
    font-size: 1.5rem;
    line-height: 1.3;
  }
}
