﻿// Components – class-based selectors, that define specific, designed UI components
@import (reference) "./../_imports.less";

.avatar-sidebar {
  position: fixed;
  top: 20vh;
  right: -40rem;
  width: 29rem;
  height: auto;
  user-select: none;
  display:none;
  opacity: 0;
  z-index: 20;
  font-weight: 700;

  @media @lg {
    width: 34rem;
  }

  @media @xxl {
    width: 40rem;
  }

  &.avatar-sidebar--show {
    display: block;
    animation-name: avatar-animation-show;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  &.avatar-sidebar--close {
    animation-name: avatar-animation-close;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  .avatar-sidebar__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    z-index: 25;
  
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .avatar-sidebar__image {

    img {
      vertical-align: bottom;
      width: 23rem;
      height: auto;
      margin-left: 6rem;
    
      @media @lg {
        width: 26rem;
        margin-left: 8rem;
      }

      @media @xxl {
        width: 33rem;
        margin-left: 7rem;
      }
    }
  }

  .avatar-sidebar__titlebar {
    color: @color-white;
    text-align: center;
    margin-left: 3rem;
    height: 4.3rem;
    font-size: 2rem;
    line-height: 3.5rem;
  }

  .avatar-sidebar__title {
    position: relative;
    margin-top: -3.6rem;
    transform: skew(-12deg);
    margin-left: 6rem;
  }

  .avatar-sidebar__titlebackground {
    background-color: @color-lightblue-200;
    height: 3.6rem;
    transform: skew(-12deg);
    margin-left: 3rem;
    box-shadow: 1rem 0.4rem 0 0.3rem @color-white;
    width: 45rem;
  }

  .avatar-sidebar__titleshadow {
    box-shadow: 3rem 0.6rem 1rem 0.3rem @color-black-25;
  }

  .avatar-sidebar__content {
    margin-top: -0.7rem;
    padding-left: 5.5rem;
    line-height: 1.2;
  }

  .avatar-sidebar__text {
    text-align: right;
    padding-top: 1rem;
    width: 20rem;
    margin-left: 1rem;
  
    @media @lg {
      width: 25rem;
      margin-left: 1rem;
    }

    @media @xxl {
      width: 30rem;
    }
  }

  .avatar-sidebar__cta {
    text-align: center;
    transform: skewX(12deg);
    width: 21rem;
    padding: 2rem 0 2rem 6rem;

    @media @lg {
      width: 27rem;
    }

    @media @xxl {
      width: 30rem;
    }

    .avalon-button--virtuel {
      background-color: @ds-color-cta-primary;
      text-transform: none;
    }
  }

  .avatar-sidebar__textbackground {
    background-color: #14e37c;
    transform: skew(-12deg);
    width: 45rem;
    box-shadow: 1.8rem 0.9rem 0 0.3rem  @color-white;
  }

  .avatar-sidebar__textshadow {
    box-shadow: -0.7rem 1.3rem 1rem 0.3rem @color-black-25;
  }
}

@keyframes avatar-animation-show {
  0% {
    right: -40rem;
    opacity: 0;
  }
  
  60% {
    transform: scale(1.05);
  }

  100% {
    right: 0rem;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes avatar-animation-close {
  0% {
    right: 0rem;
    opacity: 1;
    transform: scale(1);
  }
  
  40% {
    transform: scale(0.95);
    opacity: 0.9;
  }

  100% {
    right: -40rem;
    opacity: 0;
    display: none;
  }
}
