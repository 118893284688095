/* ===========================================
   component.game-item-module
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import (reference) "./../_imports.less";

.avalon-game-item-module {
  position: relative;
  width: 100%;

  &__link-area {
    display: block;
  }

  &__cta {
    position: absolute;
    bottom: 10%;
    text-align:center;
    width: 100%;
    padding: 0 @container-padding;
  }
	
  &__button {
    text-align: center;
    min-width: 15rem;
    font-weight: 900;
    cursor: pointer;
  }
}
